import styled from "styled-components";
import { ReloadOutlined,SearchOutlined } from '@ant-design/icons';
import {Input, Select, Pagination, App} from 'antd';
import Item from "./item.jsx";
import {useEffect, useState,useCallback} from "react";
import {useParams} from "react-router-dom";
import { listedList} from "../../api/assets.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {debounce} from "lodash";
import {useTranslation} from "react-i18next";


const Box = styled.div`
    margin-top: 10px;
    .ant-select .ant-select-arrow{
        color: rgba(255,255,255,0.3);
    }

`

const PageBox = styled.div`
    margin:40px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 1274px) {
        justify-content: flex-start;
        padding: 0 10px;
        align-items: flex-start;
        gap: 10px;
    }
`
const FilterBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 1274px) {
        .refresh{
            display: none;
        }
    }
`
const IconBox = styled.div`
    border: 1px solid #FFFAF2;
    height: 38px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 0.5;
    padding: 0 10px;
    gap: 10px;
    background: #141414;
    cursor: pointer;
    span{
        font-size: 14px;
    }
    &:hover{
        color: #ff9500;
        opacity: 1;
        border: 1px solid #ff9500;
    }
`

const RhtBox = styled.div`
    display: flex;
    justify-content: flex-end;
    gap:10px;
    .selectBox{
        width: 300px;
    }
    .inputBox{
        width: 400px;
    }
    @media (max-width: 1274px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        .selectBox{
            margin-left: -46px;
            width: calc( 100vw - 80px);
        }
        .inputBox{
            width: calc( 100vw - 80px);

        }
    }
`

const UlBox = styled.ul`
    width: 100%;
    margin-top: 20px;
    &:after{content:"";display:block;clear:both;}
    li{
        width: calc((100% - 80px)/5);
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5n){
            margin-right: 0;
        }
    }
    @media (max-width: 1274px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        box-sizing: border-box;
        li{
            width: 48%;
            margin-right: 0;

        }

    }
`

export default function Listed(){

    const {t } = useTranslation();
    const {id} = useParams()
    const [page,setPage] = useState(0)
    const [total,setTotal] = useState(0)
    const [pageSize,setPageSize] = useState(50)
    const [keyword,setKeyword] = useState('')
    const [queryWord,setQueryWord] = useState('')
    const [type,setType] = useState('order_price')
    const [typeSort,setTypeSort] = useState('asc')
    const {  notification } = App.useApp();

    const [list,setList] = useState([])

    const getAssets = async(e) =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await listedList(id,(e>1?e-1:0) * pageSize,pageSize,queryWord,type,typeSort)
            const {size,data,total,offset} = rt
            setPageSize(size)
            setTotal(total)
            let page = Math.ceil(offset/size)
            setPage(page + 1)
            setList(data)

        }catch (e) {
            console.error("collectionsList",e)
            notification.error({
                message:  t("error.listedOrders"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }
    const handleChange = (value) => {
        console.log(`selected ${value}`);
        switch (value){
            case "1":
                setType("order_price")
                setTypeSort("asc")
                break;

            case "2":
                setType("order_price")
                setTypeSort("desc")
                break;

            case "3":
                setType("update_ts")
                setTypeSort("asc")
                break;

            case "4":
                setType("update_ts")
                setTypeSort("desc")
                break

        }
    };

    const handlePage = (e) =>{
        setPage(e)
        getAssets(e)
    }

    const refreshPage= () =>{
        setPage(0)
        getAssets()
    }

    const handleDebounceFn = async (inputValue) => {
        setQueryWord(inputValue)

    }

    const debounceFn = useCallback(debounce(handleDebounceFn, 1000), []);
    const handleKeyword = (e) =>{
        const {value} = e.target;
        setKeyword(value)
        debounceFn(value);
    }

    useEffect(()=>{
        getAssets()

    },[queryWord,type,typeSort])

    return <Box>
        <FirstLine>
            <FilterBox>
                {/*<IconBox>*/}
                {/*    <FilterOutlined />*/}
                {/*    <span>   Filters</span>*/}
                {/*</IconBox>*/}
                <IconBox className="active" onClick={() => refreshPage()}>
                    <ReloadOutlined/>
                    <span className="refresh"> {t('collection.Refresh')}</span>

                </IconBox>
                {/*<IconBox>*/}
                {/*    <LineChartOutlined />*/}
                {/*    <span>Chart</span>*/}

                {/*</IconBox>*/}
            </FilterBox>
            <RhtBox>
                <Input size="large"  value={keyword} onChange={(e)=>handleKeyword(e)}  className="inputBox" placeholder={t("collection.searchTips")} prefix={<SearchOutlined />} />
                <Select
                    defaultValue="1"
                    size="large"
                    className="selectBox"
                    onChange={handleChange}
                    dropdownStyle={{background:"#141414"}}
                    options={[
                        { value: '1', label: t('collection.priceL2H') },
                        { value: '2', label: t('collection.priceH2L') },
                        { value: '3', label: t('collection.LTL2E') },
                        { value: '4', label: t('collection.LTE2L') },
                    ]}
                />
            </RhtBox>
        </FirstLine>
        <UlBox>
            {
                list.map((item,index)=> (<li key={`collection_${index}`}>
                    <Item item={item} getAssets={getAssets} />
                </li>))
            }

        </UlBox>
        <PageBox>
            {
                total >= pageSize &&  <Pagination defaultCurrent={page} current={page} total={total} pageSize={pageSize} onChange={handlePage}  showSizeChanger={false} />
            }

        </PageBox>

    </Box>
}
