import styled from "styled-components";
import {useState, useEffect, useCallback} from "react";
import {App, Popover} from 'antd';
import {SketchOutlined,WalletOutlined,ShopOutlined } from '@ant-design/icons';
import Wallet from "../modal/wallet.jsx";
import {useSelector} from "react-redux";
import store from "../../store/index.js";
import {
    savePrice,
    saveServiceFee,
    saveInfoCKB,
    saveJoyidInfo,
    saveWalletList,
    saveAccount,
    saveType,
    saveToken,
    saveSignature,
    saveHash,
    saveSecondary,
} from "../../store/reducer.js";
import {FormatAddress, isBTC} from "../../utils/general.js"
import BtcImg from "../../assets/images/btc.png";
import CkbImg from "../../assets/images/ckb.png";

import {useNavigate} from "react-router-dom";
import Loading from "../loading.jsx";
import LogoImg from "../../assets/images/newlogo.png";
import {getBTCPrice} from "../../api/btc";
import {getServiceFee} from "../../api/assets.js";
import {useTranslation} from "react-i18next";

import { Globe } from 'lucide-react';
import {getServiceInfo} from "../../api/assets_ckb.js";
import Chain from "../modal/chain.jsx";
import SwitchWallet from "../modal/switchWallet.jsx";
import SwitchImg from "../../assets/images/switch.svg";
import {CKB_networkList} from "../../utils/constant.js";
import {Indexer} from "@ckb-lumos/lumos";
import {getNet} from "../../utils/wallet.js";

const HeaderBox = styled.div`
    border-bottom: 1px solid rgba(255, 249, 242,0.2);
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
    position: fixed;
    width: 100%;
    z-index: 99;
    padding-top: 30px;
    @media (max-width: 1274px) {
        padding-top: 60px;
    }
`

const CenterBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 63px;
    //min-width: 1274px;
    width: 90vw;
    margin: 0 auto;

    @media (max-width: 1274px) {
       min-width: 100%;
       box-sizing: border-box;
       padding: 0 10px;
    }
    
    
`
const LogoBox = styled.div`
    margin-right: 20px;
    cursor: pointer;
    display: flex;
    align-content: center;
    img{
        width: 152px;
    }
`

const RhtBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    .connected-user-button {
        height: 32px;
        border: 1px solid #FF9500;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        color: #FF9500;
        cursor: pointer;
        width: 160px;
        box-sizing: border-box;
        background: #09080860;
        img{
            width: 20px;
            height: 20px;
            margin-right: 5px;
            border-radius: 50%;
            object-position: center;
            object-fit: cover;
        }
    }
    .connect-button{
        height: 32px;
        border: 0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        font-size: 14px;
        cursor: pointer;
        box-sizing: border-box;
        width: 160px;
        color: #0D0C0B;
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
    }
    @media (max-width: 1274px) {
        .connected-user-button{
            width: auto;
            gap:0;
            font-size: 12px;
            padding: 0 5px;
        }
        .connect-button{
            width: auto;
            gap:5px;
            padding: 0 5px;
        }
    }
`

const LftBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const  MenuBox = styled.ul`
    display: flex;
    align-items: center;
    height: 100%;
    li{
        display: flex;
        align-items: center;
        gap: 10px;
        
    }
`

const DropBox = styled.ul`
    width: 150px;
    box-sizing: border-box;
    li{
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        .icon{
            color: #ff9500;
        }
        .icon2{
            margin-left: 10px;
        }
        span{
            margin-left: 10px;
        }
        &:last-child{
            border-bottom: 0;
        }
    }
`

const TipsBox = styled.div`
    color: #ff9500;
    font-size: 14px;
    text-align: center;
    position: fixed;
    z-index: 900;
    width: 100%;
    background: rgba(255,255,255,0.18);
    padding: 5px;
    box-sizing: border-box;
    @media (max-width: 1274px) {
        padding: 10px;
    }
`

const LanBtn = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: #ff9500;
    margin-right: 20px;
    cursor: pointer;
    @media (max-width: 1274px) {
        margin-right: 0;
       span{
           display: none;
       }
    }
`

export default function Header(){
    const {t,i18n } = useTranslation();
    const navigate = useNavigate()
    const [show,setShow] = useState(false);
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)
    const loading = useSelector(store => store.loading)
    const {  notification } = App.useApp();
    const [showChain,setShowChain] = useState(false);
    const [showSwitch,setShowSwitch] = useState(false);
    const walletList = useSelector(store => store.walletList);

    const [chain,setChain] = useState("ckb");

    useEffect(() => {
        if(window.ckb){
            window.ckb?.on('accountsChanged', accountChangesFun);
            window.ckb?.on('chainChanged', accountChangesFun);
        }

    },[])


    useEffect(()=>{
        getDID()
    },[])

    const getDID = async() =>{
        try{
            const net = await getNet()
            const rpcUrl = CKB_networkList[net].node
            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);

            const  args =net==="testnet"?"0x80f0d4bf6b3951911aa6b98cc609d477a8a10b903b35cfd528d098e95c36f680":"0x62312cd846659e188b05da11dc3f080b083c27371ea701d6026e11e713e0e3de"
            const didRT = indexer.collector({
                type: {
                    script: {
                        codeHash:
                            "0x00000000000000000000000000000000000000000000000000545950455f4944",
                        hashType: "type",
                        args,

                    },
                    searchMode: "exact",
                }
            });
            let rt = await didRT.collect();
            let didRTJSON = await rt.next()
            if(didRTJSON?.value){
                const {outPoint:{txHash}} = didRTJSON.value
                store.dispatch(saveHash(txHash))
            }
        }catch(e){
            console.error(e)
            notification.error({
                message: "Get DID",
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
    }



    const  accountChangesFun =  () => {
        disconnect("ckb")

    }
    const accountChangeBTC = () =>{
        disconnect("btc")
    }

    useEffect(() => {
        getPrice()
        getPriceCkb()
        serviceFee()
    }, []);


    useEffect(() => {
        if(!window?.unisat ) return;
        window.unisat.on('accountsChanged', accountChangeBTC);
        // return ()=>{
        //     window.unisat.removeListener('accountsChanged', accountChangeBTC);
        // }

    }, []);
    useEffect(() => {
        if(!window?.okxwallet) return;
        window?.okxwallet?.bitcoin?.on('accountsChanged',accountChangeBTC);

    }, []);



    const disconnect = (chain) =>{
        const {btc,ckb} = walletList;

        let newList;
        if(chain === "btc"){
            newList = {
                btc:null,
                ckb
            }
            localStorage.setItem("joyidType",null);

        }else {
            newList = {
                btc,
                ckb:null
            }
            store.dispatch(saveJoyidInfo(null));
            store.dispatch(saveSecondary(null));
        }

        let current = newList[chain === "btc"? "ckb":"btc"];

        if(!current){
            store.dispatch(saveAccount(null));
            store.dispatch(saveType(null));
            store.dispatch(saveSignature(null));

        }else{
            const {account,type,signature} = current;
            store.dispatch(saveAccount(account));
            store.dispatch(saveType(type));
            store.dispatch(saveSignature(signature));
        }
        store.dispatch(saveWalletList(newList));


    }

    const getPrice = async() =>{
        try{
            let rt = await getBTCPrice();
            store.dispatch(savePrice(rt?.USD));

        }catch (e) {
            console.error(e)
            notification.error({
                message: t("error.BTCprice"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
    }

    const getPriceCkb = async() =>{
        try{
            let rt = await getServiceInfo();
            console.log("getPriceCkb",rt);
            store.dispatch(saveInfoCKB(rt));

        }catch (e) {
            console.error(e)
            notification.error({
                message: t("error.BTCprice"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
    }
    const serviceFee = async() =>{
        try{
            let rt = await getServiceFee();
            store.dispatch(saveServiceFee(rt));
        }catch (e) {
            console.error(e)
            notification.error({
                message: t("error.serviceFee"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }


    }

    const leftClose = () =>{
        setShow(false)
    }


    const handleSwitch = () =>{
        setShowSwitch(true)
    }

    const handleSwitchClose = () =>{
        setShow(false)
        setShowChain(false)
        setShowSwitch(false)
    }

    const handleShowChain = () =>{
        setShowChain(true)
    }

    const handleCloseChain = () =>{
        setShowChain(false)
        setShow(false)
        setShowSwitch(false)
    }

    const handleShow = (e,chain) =>{
        setShow(true)
        setChain(chain)
    }

    const handleClose = () =>{
        setShow(false)
        setShowChain(false)
        setShowSwitch(false)
    }

    const returnImg = () =>{
        let imgStr = ""
        // switch(type){
        //     case "joyid":
        //     case "joyid_ckb":
        //         imgStr =  JoyidImg;
        //         break;
        //     case "unisat":
        //         imgStr =  UnisatImg;
        //         break;
        //     case "okx":
        //         imgStr =  OkxImg;
        //         break;
        //
        //     case "rei":
        //         imgStr =  ReiImg;
        //         break;
        // }
        if(isBTC(type)){
            imgStr = BtcImg;
        }else{
            imgStr = CkbImg;
        }
        return imgStr;
    }

    const handleLan = () =>{

        const lang = i18n.language === "zh-cn" ? "en-us":"zh-cn"
        i18n.changeLanguage(lang);

    }

    const content = () =>{

        return <DropBox>
            {
                isBTC(type) &&
                <li onClick={() => navigate("/myAssets")}><SketchOutlined
                    className="icon"/><span>{t('general.MyAssets')} (BTC)</span></li>
            }

            {
                !isBTC(type) && <li onClick={() => navigate("/myAssets_ckb")}><SketchOutlined
                    className="icon"/><span>{t('general.MyAssets')} (CKB)</span></li>
            }
            {
                isBTC(type) &&<li onClick={() => navigate("/myAssets?tab=2")}> <ShopOutlined className="icon" /> <span>My Orders (BTC)</span></li>
            }
            {
                !isBTC(type) && <li onClick={() => navigate("/myAssets_ckb?tab=2")}> <ShopOutlined className="icon" /> <span>My Orders (CKB)</span></li>
            }

            <li onClick={() => handleSwitch()}><img src={SwitchImg} alt=""  className="icon2" /><span>{t('general.switch')} </span></li>


            {/*<li onClick={() => disconnect()}><DisconnectOutlined className="icon"/><span>{t('general.Disconnect')}</span></li>*/}
        </DropBox>
    };

    return <>
        {
            show && <Wallet handleClose={handleClose} chain={chain} leftClose={leftClose} />
        }
        {
            showChain && <Chain handleClose={handleCloseChain} handleNext={handleShow} />
        }
        {
            showSwitch && <SwitchWallet handleClose={handleSwitchClose} handleNext={handleShow} />
        }

        {
            loading &&  <Loading />
        }

        <TipsBox>{t("general.warnings")}</TipsBox>
        <HeaderBox>


        <CenterBox>
            <LftBox>
                <LogoBox onClick={()=>navigate("/")}>
                    <img src={LogoImg} alt=""/>
                </LogoBox>
                <MenuBox>
                    {/*<li>*/}
                    {/*    <BankOutlined />*/}
                    {/*    <span>*/}
                    {/*                           MarketPlace*/}
                    {/*    </span>*/}

                    {/*</li>*/}


                </MenuBox>
            </LftBox>
            <RhtBox>
                <LanBtn onClick={()=>handleLan()}>
                    <Globe color="#ff9500" size={24} />
                    <span>
                          {i18n.language === "zh-cn"?"中文":"English"}
                    </span>

                </LanBtn>
                {
                    !!account && <Popover placement="bottomRight"  content={content}><div className="connected-user-button">

                        <img src={returnImg()} alt=""/>
                        {FormatAddress(account)}</div> </Popover>
                }
                {
                    !account && <div className="connect-button" onClick={()=>handleShowChain()}><WalletOutlined />{t('general.connect')}</div>
                }

            </RhtBox>
        </CenterBox>

    </HeaderBox>

        </>
}
