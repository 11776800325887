import styled from "styled-components";
import {App, Table, Tag} from 'antd';
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {cancelOrder, myOrderList} from "../../api/assets.js";
import {useSelector} from "react-redux";
import {getNet} from "../../utils/wallet.js";
import {BitcoinUnit} from "bitcoin-units";
import {FormatAddress, formatImage, formatUSD, handleBg} from "../../utils/general.js";
import {Params} from "../../utils/constant.js";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import Pending from "../pending.jsx";

const NewBtn = styled.button`

    height: 35px;
    border-radius: 10px;
    cursor: pointer;
    border:1px solid #FF9500;
    color: #ff9500;
    background: none;
    padding: 0 30px;
`

const TableBox = styled.div`
    margin: 10px 0;
    a{
        text-decoration: underline;
    }
    th{
        text-transform: uppercase;
    }
    .tagBg{
        border: 0;
        font-family: Roboto-Bold;
        font-size: 14px;
        text-transform: capitalize;
    }
    .photo{
        width: 60px;
        height: 60px;
        border-radius: 5px;
    }
    .sold{
        color: #FF5A5F;
        border: 0;
    }
    .updated{
        color:#8AC926;
    }
    .listed{
        color: #3DA5D9;
    }
    .unlisted{
        color: #FF914C;
    }
    .pending{
        color: #F600FF;
    }

    .preview{
        width: 60px;
        height: 60px;
        object-position: center;
        object-fit: cover;
    }
    .btc{

        span{
            font-family: din_bold;
            font-size: 16px;
        }
    }
    .price{
        opacity: 0.6;
        font-family:din_regular;
    }
    a.time{
        color: #FFF9F2;
        text-decoration: none;
    }
    @media (max-width: 1274px) {
        padding-left: 10px;
        width: 100%;
        box-sizing: border-box;
    }
`
export default function MyOrderListBTC({selectType}){
    const {t } = useTranslation();
    const {id} = useParams()
    const [page,setPage] = useState(1)
    const [total,setTotal] = useState(0)
    const [pageSize,setPageSize] = useState(10)
    const BtcPrice = useSelector(store => store.price)
    const [net,setNet] = useState("testnet");
    const type = useSelector(store => store.type)
    const {  notification } = App.useApp();
    const [list,setList] = useState([])


    useEffect(()=>{
        getAssets(1)
        getNetwork()
    },[selectType])

    const getNetwork = async () =>{
        let rt = await getNet(type)
        setNet(rt)
    }

    const getAssets = async(e) =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await myOrderList(id,(e>1?e-1:0) * pageSize,pageSize,selectType)
            const {size,data,total,offset} = rt
            setPageSize(size)
            setTotal(total)
            let page = Math.ceil(offset/size)
            setPage(page?page+1:1)
            setList(data)

        }catch (e) {
            console.error("collectionsList",e)

            notification.error({
                message: t("error.MyOrders"),
                description:e?.response?.data?.message || e?.message || e?.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }


    const handlePage = (e) =>{
        setPage(e)
        getAssets(e)
    }

    const handleCancel = async(order_id) =>{
        console.log(order_id)
        store.dispatch(saveLoading(true))
        try{
            let rt = await cancelOrder(order_id);
            console.log(rt)
            notification.success({
                message: t("error.CancelSuccess"),
            });
            getAssets()
        }catch (e) {
            console.error("cancelOrder",e)

            notification.error({
                message:t("error.CancelFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }


    }



    const columns = [
        {
            title: t('table.Preview'),
            dataIndex: 'media_info',
            fixed: 'left',
            render: (_,row) =><div className="photo" style={{background: handleBg(row)}}>
                {
                    // row.media_info.content_type.indexOf("image") > -1 && <img src={row.media_info?.url} className="preview"/>
                    row.media_info.content_type.indexOf("image") > -1 && <img src={formatImage(row)} className="preview"/>
                }
                {
                    row.media_info.content_type.indexOf("text") > -1 && <div className="innerText">{row.media_info?.content_data}</div>
                }
            </div>,
        },
        {

            title:t('table.DOBID'),
            dataIndex: 'dob_id',
            render: (_,row) => <Link to={`/item-detail/${row.asset_id}`}>{FormatAddress(row.dob_id)}</Link>,
        },
        {
            title: t('table.Event'),
            dataIndex: 'status',
            render: (text) =><Tag className={`${text} tagBg`}>{t(`table.${text}`)}{text==="pending" && <Pending />}</Tag>,
        },

        {
            title:t('table.Value'),
            dataIndex: 'price',
            render: (_, { price }) =><div >
                <div className="btc"><span>{new BitcoinUnit(price ?? 0, 'sats').to('BTC').toString()}</span> BTC</div>
                <div className="price">$ {formatUSD(BtcPrice,price)}</div></div>,
        },
        {
            title:t('table.From'),
            dataIndex: 'from_address',
            render: (text) => <a href={`${Params[net]?.scanUrl}address/${text}`} rel="noreferrer" target="_blank">{FormatAddress(text)}</a>,
        },
        {
            title: t('table.To'),
            dataIndex: 'to_address',
            render: (text) => <a href={`${Params[net]?.scanUrl}address/${text}`} rel="noreferrer" target="_blank">{FormatAddress(text)}</a>,
        },
        {
            title: t('table.Time'),
            dataIndex: 'update_ts',
            render: (text) => <a className="time">{dayjs(Number(text)).format('YYYY-MM-DD HH:mm')}</a>,
        },
        {
            title: t('table.Operate'),
            dataIndex: 'time',
            render: (_,row) => <div>
                {row.status === "listed" &&<NewBtn onClick={()=>handleCancel(row.order_id)}>{t('general.Cancel')}</NewBtn>
                }
            </div>,
        }
    ];


    return <TableBox>
        <Table  columns={columns} dataSource={list}
                scroll={{
                    x:850
                }}
                rowKey={() => Math.random().toString(36).substr(2)}
                pagination={{
                    position:["bottomCenter"],
                    pageSize,
                    current:page,
                    total,
                    showSizeChanger:false,
                    onChange: (page) => {
                        handlePage(page);
                    }
                }}
        />
    </TableBox>
}
