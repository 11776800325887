import {BI, config, helpers, Indexer, utils} from "@ckb-lumos/lumos";
import {predefined} from "@ckb-lumos/config-manager";
import {getNet} from "./wallet.js";
import {
    CKB_CONTRACT,
    CKB_FEE_CONTRACT,
    CKB_networkList,
    DID_CONTRACT,
    MINiPrice,
    OMNILOCK,
    UTXO_PEROID
} from "./constant.js";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {parseUnit} from "@ckb-lumos/bi";
import {
    calculateFeeCompatible,
    generateSporeCoBuild_Single, getJoyid_lock_type,
    getTransactionSizeByTx,
    updateWitness
} from "./ckbUtils.js";
import {Uint8, bytes, Uint64BE} from "@ckb-lumos/lumos/codec";
import store from "../store/index.js";
import {getUtxoStore, isCCC} from "./general.js";


const MAX_FEE = BI.from("20000000")

export const build_buy_dob = async (fee,typeObj,address,price,seller,serviceAddress,type,serviceFeePercent,assetType) =>{

    const net = await getNet()

    const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;
    config.initializeConfig(newConfig);
    const rpcUrl = CKB_networkList[net].node
    const indexUrl = CKB_networkList[net].indexer
    const indexer = new Indexer(indexUrl, rpcUrl);

    let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

    const sporeConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

    let myScript = helpers.addressToScript(address,{config:newConfig})

    // const myHash = utils.computeScriptHash(myScript);
    const myHash = myScript.args;
    const myFee = indexer.collector({
        lock: {
            script: {
                codeHash:CKB_FEE_CONTRACT[net].CODE_HASH,
                hashType:CKB_FEE_CONTRACT[net].HASH_TYPE,
                args:myHash
            },
            searchMode: "exact",
        },
        type: "empty",
    });



    let myFeeArr = []

    for await (const collect of myFee.collect()) {
        myFeeArr.push(collect)
    }


    let serviceFee

    const priceNew = BI.from(price);
    const newServiceFee = priceNew.mul(serviceFeePercent.toString()).div("100");

    if(!myFeeArr.length){
        const minCapacity = parseUnit(MINiPrice,"ckb")

        if(newServiceFee.lt(minCapacity)){
            serviceFee = minCapacity
        }else{
            serviceFee = newServiceFee;
        }
    }else{
        serviceFee = newServiceFee;
    }



    let needCapacity = BI.from(
        helpers.minimalCellCapacity({
            cellOutput: {
                lock: myScript,
                capacity: BI.from(0).toHexString(),
            },
            data: "0x",
        })
    ).add(MAX_FEE).add(serviceFee).add(priceNew);


    const collect_ckb = indexer.collector({
        lock: {
            script: myScript,
            searchMode: "exact",
        },
        type: "empty",
    });

    const utxoFilter = getUtxoStore();
    const inputs_ckb = [];
    let ckb_sum = BI.from(0);
    for await (const collect of collect_ckb.collect()) {
        const useUtxo = utxoFilter.find((utxo)=> utxo.txHash === collect.outPoint.txHash && utxo.index === collect.outPoint.index)

        if (useUtxo) {
            continue;
        }

        inputs_ckb.push(collect);
        ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
        if (ckb_sum.gte(needCapacity)) {
            break;
        }
    }

    if (ckb_sum.lt(needCapacity)) {
        throw new Error("Not Enough capacity found");
    }



    let cellDep_script_lock ;
    const {codeHash:myCodeHash,hashType:myHashType} = myScript

    if(type === "joyid_ckb"){
        cellDep_script_lock = getJoyid_lock_type(net)
    }else if(isCCC(type)){
        cellDep_script_lock = OMNILOCK[net];

    }else {
        for (let key in newConfig.SCRIPTS) {
            let item = newConfig.SCRIPTS[key]
            if (item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType) {
                cellDep_script_lock = item;
                break;
            }
            throw new Error("script not found")
        }
    }
    const version = net === 'testnet'?"preview":"latest";
    const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);

    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(...inputs_ckb)

    );



    if(myFeeArr.length){
        txSkeleton = txSkeleton.update("inputs", (inputs) =>
            inputs.push(myFeeArr[0])

        );
    }


    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(typeObj)
    );


    const allFee = serviceFee.add(MAX_FEE).add(priceNew)

    const outputCapcityChange  = ckb_sum.sub(allFee);


    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${outputCapcityChange.toString(16)}` ,
                lock:myScript,
            },
            data:"0x"
        })
    );



    let inputCapacity = typeObj.cellOutput.capacity;
    let inputOccupid = helpers.minimalCellCapacityCompatible(typeObj);
    let inputMargin = BI.from(inputCapacity).sub(inputOccupid)

    const outputObj = JSON.parse(JSON.stringify(typeObj));
    outputObj.cellOutput.lock = myScript;

    let outputMinimal = BI.from(
        helpers.minimalCellCapacity(outputObj)
    );

    let outputCapacity = outputMinimal.add(inputMargin)
    let diff = BI.from(inputCapacity).sub(outputCapacity)
    outputObj.cellOutput.capacity = `0x${outputCapacity.toString(16)}`;


    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push(outputObj)
    );

    config.initializeConfig(newConfig);

    let tolockScript = helpers.addressToScript(seller,{config:newConfig})
    const newSellerPrice = priceNew.add(diff);

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${newSellerPrice.toString(16)}` ,
                lock: tolockScript,
            },
            data:"0x"
        })
    );

    let serviceFeeChange = serviceFee;

    if(myFeeArr.length){
        serviceFeeChange = serviceFee.add(myFeeArr[0].cellOutput.capacity)
    }

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${serviceFeeChange.toString(16)}`,
                lock: {
                    codeHash:CKB_FEE_CONTRACT[net].CODE_HASH,
                    hashType:CKB_FEE_CONTRACT[net].HASH_TYPE,
                    args:myHash
                },
            },
            data:"0x"
        })
    );



    const {TX_HASH:tx_hash_lock,INDEX:index_lock,DEP_TYPE:dep_type_lock} = cellDep_script_lock

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_hash_lock,
                "index": index_lock
            },
            "depType": dep_type_lock
        })
    );

    //fee contract

    const {TX_HASH:tx_fee,INDEX:index_fee,DEP_TYPE:type_fee} = CKB_FEE_CONTRACT[net];

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_fee,
                "index": index_fee
            },
            "depType": type_fee
        })
    );


    const {TX_HASH,INDEX,DEP_TYPE} = CKB_CONTRACT[net];

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": TX_HASH,
                "index": INDEX
            },
            "depType": DEP_TYPE
        })
    );


    if(assetType === 0 ){
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push( sporeTypeScript.cellDep)
        );


    }else if(assetType === 1){
        const state = store.getState();
        const {didHash} = state;
        const {INDEX,DEP_TYPE} = DID_CONTRACT[net];
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push(    {
                "outPoint": {
                    "txHash": didHash,
                    "index": INDEX
                },
                "depType": DEP_TYPE
            })
        );

    }




    let  sporeCoBuild = generateSporeCoBuild_Single(typeObj, outputObj)
    txSkeleton = await updateWitness(txSkeleton,myScript,typeObj.cellOutput.type.codeHash,sporeCoBuild,type)


    console.log("===updateWitness==",txSkeleton)

    const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton,{validate:false});
    console.log("===unsignedTx==",unsignedTx)

    const size =  getTransactionSizeByTx(unsignedTx)

    console.log("===size==",size)
    const newFee = calculateFeeCompatible(size,fee);

    const allFeeFact = serviceFee.add(newFee).add(priceNew)
    // const outputCapacityFact  = ckb_sum.sub(allFeeFact).add(diff);
    const outputCapacityFact  = ckb_sum.sub(allFeeFact);


    let outputs = txSkeleton.get("outputs").toArray();
    let item = outputs[0];
    item.cellOutput.capacity = outputCapacityFact.toHexString();
    txSkeleton = txSkeleton.update("outputs", (outputs) => {
        outputs.set(0,item)
        return outputs;
    });



    return {txSkeleton,txFee:newFee,sFee:serviceFee}
}

export const build_list_dob = async (fee,typeObj,address,price,type,assetType) =>{

    const net = await getNet()
    const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

    const {TX_HASH,INDEX,DEP_TYPE,CODE_HASH,HASH_TYPE} = CKB_CONTRACT[net]
    const sporeConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

    const rpcUrl = CKB_networkList[net].node
    const indexUrl = CKB_networkList[net].indexer
    const indexer = new Indexer(indexUrl, rpcUrl);

    let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

    let fromScript = helpers.addressToScript(address,{config:newConfig});
    const fromHash = utils.computeScriptHash(fromScript);
    let fromBytes =  bytes.bytify(fromHash);


    const typeBytes = Uint8.pack("0x1") // contract transaction type  1 list

    const priceFormat = parseUnit(price,"ckb");


    const priceBytes = Uint64BE.pack(priceFormat);

    // <32-bytes_seller_lock_hash><1-bytes_order_type><8-bytes_sell_price>

    const argsArr = bytes.concat(fromBytes,typeBytes,priceBytes)

    const argsHex = bytes.hexify(argsArr)

    const to = {
        codeHash: CODE_HASH,
        args:argsHex,
        hashType:HASH_TYPE,
    }

    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(typeObj)
    );

    const outputObj = JSON.parse(JSON.stringify(typeObj));
    outputObj.cellOutput.lock = to;

    let inputMin = typeObj.cellOutput.capacity;
    let inputOccupid = helpers.minimalCellCapacityCompatible(typeObj);
    let newMargin = BI.from(inputMin).sub(inputOccupid)
    let outputMin = helpers.minimalCellCapacityCompatible(outputObj).add(newMargin);
    let minBi = outputMin.sub(inputMin.toString());
    let amount;

    if(minBi.gt("0")){
        amount = minBi
    }else{
        amount = BI.from("0")
    }


    let capcityFormat = BI.from(inputMin)

    outputObj.cellOutput.capacity  = amount.add(capcityFormat).toHexString();

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push(outputObj)
    );


    let needCapacity = BI.from(
        helpers.minimalCellCapacity({
            cellOutput: {
                lock: fromScript,
                capacity: BI.from(0).toHexString(),
            },
            data: "0x",
        })
    ).add(MAX_FEE).add(amount);


    const collect_ckb = indexer.collector({
        lock: {
            script: fromScript,
            searchMode: "exact",
        },
        type: "empty",
    });

    const utxoFilter = getUtxoStore();

    const inputs_ckb = [];
    let ckb_sum = BI.from(0);
    for await (const collect of collect_ckb.collect()) {
        const useUtxo = utxoFilter.find((utxo)=> utxo.txHash === collect.outPoint.txHash && utxo.index === collect.outPoint.index)

        if (useUtxo) {
            continue;
        }
        inputs_ckb.push(collect);
        ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
        if (ckb_sum.gte(needCapacity)) {
            break;
        }
    }

    if (ckb_sum.lt(needCapacity)) {
        throw new Error("Not Enough capacity found");
    }

    const {codeHash:myCodeHash,hashType:myHashType} = fromScript
    let cellDep_script_lock;


    if(type === "joyid_ckb"){
        cellDep_script_lock = getJoyid_lock_type(net)
    }else if(isCCC(type)){
        cellDep_script_lock = OMNILOCK[net];
        console.log("====aaa",cellDep_script_lock)

    }else{
        for(let key in newConfig.SCRIPTS){
            let item = newConfig.SCRIPTS[key]
            console.log(myCodeHash,myHashType,key)
            if(item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType){
                cellDep_script_lock = item;
                break;
            }
            throw new Error("script not found")
        }
    }


    const version = net === 'testnet'?"preview":"latest";
    const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
    let oldInputs = txSkeleton.get("inputs");

    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.clear()
    );
    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(...inputs_ckb, ...oldInputs)
    );

    const outputCapacity  = ckb_sum.sub(MAX_FEE).sub(amount);

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${outputCapacity.toString(16)}` ,
                lock: fromScript,
            },
            data:"0x"
        })
    );

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash":TX_HASH,
                "index": INDEX
            },
            "depType": DEP_TYPE
        })
    );

    const {TX_HASH:tx_hash_lock,INDEX:index_lock,DEP_TYPE:dep_type_lock} = cellDep_script_lock

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_hash_lock,
                "index": index_lock
            },
            "depType": dep_type_lock
        })
    );


    if(assetType === 0 ){
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push( sporeTypeScript.cellDep)
        );


    }else if(assetType === 1){
        const state = store.getState();
        const {didHash} = state;
        const {INDEX,DEP_TYPE} = DID_CONTRACT[net];
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push(    {
                "outPoint": {
                    "txHash": didHash,
                    "index": INDEX
                },
                "depType": DEP_TYPE
            })
        );

    }

    let  sporeCoBuild = generateSporeCoBuild_Single(typeObj, outputObj)

    txSkeleton = await updateWitness(txSkeleton,fromScript,typeObj.cellOutput.type.codeHash,sporeCoBuild,type)
    const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton);

    const size =  getTransactionSizeByTx(unsignedTx)
    const newFee = calculateFeeCompatible(size,fee);
    const outputCapacityFact  = ckb_sum.sub(newFee).sub(amount);
    let outputs = txSkeleton.get("outputs").toArray();
    let item = outputs[1];
    item.cellOutput.capacity = outputCapacityFact.toHexString();
    txSkeleton = txSkeleton.update("outputs", (outputs) => {
        outputs.set(1,item)
        return outputs;
    });



    return txSkeleton
}

export const build_unlist = async(fee,typeObj,address,type,assetType) =>{
    const net = await getNet()
    const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

    const {TX_HASH,INDEX,DEP_TYPE} = CKB_CONTRACT[net]
    const sporeConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

    const rpcUrl = CKB_networkList[net].node
    const indexUrl = CKB_networkList[net].indexer
    const indexer = new Indexer(indexUrl, rpcUrl);
    let myScript = helpers.addressToScript(address,{config:newConfig})

    let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

    let needCapacity = BI.from(
        helpers.minimalCellCapacity({
            cellOutput: {
                lock: myScript,
                capacity: BI.from(0).toHexString(),
            },
            data: "0x",
        })
    ).add(MAX_FEE);


    const collect_ckb = indexer.collector({
        lock: {
            script: myScript,
            searchMode: "exact",
        },
        type: "empty",
    });

    const utxoFilter = getUtxoStore();
    const inputs_ckb = [];
    let ckb_sum = BI.from(0);
    for await (const collect of collect_ckb.collect()) {
        const useUtxo = utxoFilter.find((utxo)=> utxo.txHash === collect.outPoint.txHash && utxo.index === collect.outPoint.index)

        if (useUtxo) {
            continue;
        }

        inputs_ckb.push(collect);
        ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
        if (ckb_sum.gte(needCapacity)) {
            break;
        }
    }

    if (ckb_sum.lt(needCapacity)) {
        throw new Error("Not Enough capacity found");
    }

    const {codeHash:myCodeHash,hashType:myHashType} = myScript

    let cellDep_script_lock ;

    if(type === "joyid_ckb"){
        cellDep_script_lock = getJoyid_lock_type(net)
    }else if(isCCC(type)){
        cellDep_script_lock = OMNILOCK[net];

    }else {
        for (let key in newConfig.SCRIPTS) {
            let item = newConfig.SCRIPTS[key]
            if (item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType) {
                cellDep_script_lock = item;
                break;
            }
            throw new Error("script not found")
        }
    }


    const version = net === 'testnet'?"preview":"latest";
    const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(...inputs_ckb)

    );


    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(typeObj)
    );

    let inputCapacity = typeObj.cellOutput.capacity;
    let inputOccupid = helpers.minimalCellCapacityCompatible(typeObj);
    let inputMargin = BI.from(inputCapacity).sub(inputOccupid)

    const outputObj = JSON.parse(JSON.stringify(typeObj));
    outputObj.cellOutput.lock = myScript;

    let outputMinimal = BI.from(
        helpers.minimalCellCapacity(outputObj)
    );

    let outputCapacity = outputMinimal.add(inputMargin)
    let diff = BI.from(inputCapacity).sub(outputCapacity)

    outputObj.cellOutput.capacity = `0x${outputCapacity.toString(16)}`;

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push(outputObj)
    );


    const outputCapacityChange  = ckb_sum.sub(MAX_FEE);

    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${outputCapacityChange.toString(16)}` ,
                lock: myScript,
            },
            data:"0x"
        })
    );



    const {TX_HASH:tx_hash_lock,INDEX:index_lock,DEP_TYPE:dep_type_lock} = cellDep_script_lock

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_hash_lock,
                "index": index_lock
            },
            "depType": dep_type_lock
        })
    );


    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": TX_HASH,
                "index": INDEX
            },
            "depType": DEP_TYPE
        })
    );

    if(assetType === 0 ){
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push( sporeTypeScript.cellDep)
        );


    }else if(assetType === 1){
        const state = store.getState();
        const {didHash} = state;
        const {INDEX,DEP_TYPE} = DID_CONTRACT[net];
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push(    {
                "outPoint": {
                    "txHash": didHash,
                    "index": INDEX
                },
                "depType": DEP_TYPE
            })
        );

    }

    let  sporeCoBuild = generateSporeCoBuild_Single(typeObj, outputObj)
    txSkeleton =await updateWitness(txSkeleton,myScript,typeObj.cellOutput.type.codeHash,sporeCoBuild,type)
    const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton);


    const size =  getTransactionSizeByTx(unsignedTx)
    const newFee = calculateFeeCompatible(size,fee);


    const outputCapacityFact  = ckb_sum.sub(newFee).add(diff);

    let outputs = txSkeleton.get("outputs").toArray();
    let item = outputs[1];
    item.cellOutput.capacity = outputCapacityFact.toHexString();
    txSkeleton = txSkeleton.update("outputs", (outputs) => {
        outputs.set(1,item)
        return outputs;
    });

    return txSkeleton
}
