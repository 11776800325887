import { createGlobalStyle } from "styled-components";
import "../assets/styles/font.scss";
import {handleBg} from "./general.js";

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
      font-family:Roboto-Regular;
      color: #FFF9F2;
        padding: 0;
      margin: 0;
      background: #090808;
  }
  a{
      color: #ff9500;
  }

  
    ul,li,dt,dl,dd{
        padding: 0;
        margin: 0;
        list-style: none;
    }
  .ant-tabs-top >.ant-tabs-nav::before, .ant-tabs-bottom >.ant-tabs-nav::before, .ant-tabs-top >div>.ant-tabs-nav::before, .ant-tabs-bottom >div>.ant-tabs-nav::before{
      border-bottom: 0 !important;
  }

  .ant-pagination-item-ellipsis{
      color: rgba(255,255,255,0.85)!important;
  }
  .ant-empty-description{
      color: rgba(255,255,255,0.85)!important;
  }

  input[type="text"],
  input[type="password"],
  textarea {
      -webkit-text-size-adjust: none;
  }
  .ant-pagination .ant-pagination-item{
        background-color: transparent!important;
  }
`;




export default GlobalStyle;
