import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import store from "../../store/index.js";
import {saveAccount, saveSecondary} from "../../store/reducer.js";
import useCCCSigner from "../../hook/useCCCSigner.jsx";

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    font-family: din_bold;
    font-size: 18px;

    .lftImg{
        cursor: pointer;
    }
    .lft{
        flex-grow: 1;
        text-align: center;

    }
    .icon{
        cursor: pointer;
    }

`

const UlBox = styled.ul`
    padding-top: 30px;
    li{
        word-break: break-all;
        border:1px solid #191A1E;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        &.active{
            border:1px solid #ff9500;
            color: #ff9500;
        }
    }
`


export default function ChangeAddress({handleClose}){
    const account = useSelector(state => state.account);
    const type = useSelector(state => state.type);
    const [list,setList] = useState([]);
    const {signer} = useCCCSigner(type);

    useEffect(()=>{
        if(!type || !signer)return;
        getList()
    },[type,signer])


    const getList = async() =>{
        let arr = await signer.getAddresses();
        setList(arr);
    }

    const handleSelect = (index) =>{
        store.dispatch(saveSecondary(list[1-index]));
        store.dispatch(saveAccount(list[index]));
        handleClose()
    }


    return <MaskBox>
        <ContentBox>
            <TitleBox>
                <div className="lft">Change Address</div>

                <CloseOutlined className="icon" onClick={() => handleClose()}/>
            </TitleBox>
            <UlBox>
                {
                    list.map((item, index) => (<li key={index} className={item === account? "active":"" } onClick={()=>handleSelect(index)}>{item}</li>))
                }

            </UlBox>
        </ContentBox></MaskBox>
}
