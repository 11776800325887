import DidImg from "../../assets/images/did.svg";
import {useEffect, useState} from "react";
import {toJSONStr} from "../../utils/general.js";
import {getDOBImage} from "../../api/assets_ckb.js";

export default function ImageItem({row}){
    const [image,setImage] = useState("");
    useEffect(() => {
        const sporeObj = toJSONStr(row?.sporeData)
        if(sporeObj.contentType.indexOf("application/json")>-1){
            getDetail()

        }

    }, [row]);

    const getDetail = async() =>{
        try{
            let rt = await getDOBImage(row?.dobsId)
            setImage(rt.url)

        }catch(e){
            console.error("get dob image error",row?.dobsId)
        }
    }
    return <div className="photo">
        {
            row.assetType === 0 && <img src={row?.spore?.imgUrl || row.dobsPreview || image} className="preview"/>
        }

        {
            row.assetType === 1 && <img src={DidImg} className="preview"/>
        }
    </div>
}
