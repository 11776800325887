import {
    calculateFeeCompatible, generateSporeCoBuild,
 getJoyid_lock_type,
    getTransactionSizeByTx,
    updateWitness
} from "./ckbUtils.js";
import { helpers, Indexer} from "@ckb-lumos/lumos";
import {predefined} from "@ckb-lumos/config-manager";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {BI} from "@ckb-lumos/bi";

import {getUtxoStore, isCCC} from "./general.js";
import {CKB_networkList, DID_CONTRACT, OMNILOCK} from "./constant.js";
import {getNet} from "./wallet.js";
import store from "../store/index.js";

const MAX_FEE = BI.from("20000000")
// const MAX_FEE = parseUnit("2","ckb")

export const build_transfer_dob = async (fee,typeObj,address,toAddress,type,assetType) =>{

    const net = await getNet()
    const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

    const sporeConfig = net==="testnet"?  predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

    const rpcUrl = CKB_networkList[net].node
    const indexUrl = CKB_networkList[net].indexer
    const indexer = new Indexer(indexUrl, rpcUrl);
    let myScript = helpers.addressToScript(address,{config:newConfig})
    const toScript =helpers.addressToScript(toAddress,{config:newConfig})

    let txSkeleton = helpers.TransactionSkeleton({ cellProvider: indexer });

    let diffSUM = BI.from(0)
    let outputArr = []


    typeObj.map((spore)=>{

        txSkeleton = txSkeleton.update("inputs", (inputs) =>
            inputs.push(spore)
        );

        let inputCapacity = spore.cellOutput.capacity;
        let inputOccupid = helpers.minimalCellCapacityCompatible(spore);
        let inputMargin = BI.from(inputCapacity).sub(inputOccupid)

        const outputObj = JSON.parse(JSON.stringify(spore));
        outputObj.cellOutput.lock = toScript;

        let outputMinimal = BI.from(
            helpers.minimalCellCapacity(outputObj)
        );

        let outputCapacity = outputMinimal.add(inputMargin)

        let diff

        if(type === "joyid_ckb"){
            diff = BI.from(inputCapacity).sub(outputCapacity)
        }else{
            diff = BI.from(outputCapacity).sub(inputCapacity)
        }

        diffSUM = diffSUM.add(diff)

        outputObj.cellOutput.capacity = `0x${outputCapacity.toString(16)}`;

        outputArr.push(outputObj);
        txSkeleton = txSkeleton.update("outputs", (outputs) =>
            outputs.push(outputObj)
        );

    })

    let needCapacity = BI.from(
        helpers.minimalCellCapacity({
            cellOutput: {
                lock: toScript,
                capacity: BI.from(0).toHexString(),
            },
            data: "0x",
        })
    ).add(MAX_FEE);


    if(type !== "joyid_ckb"){
        needCapacity.add(diffSUM)
    }
    const collect_ckb = indexer.collector({
        lock: {
            script: myScript,
            searchMode: "exact",
        },
        type: "empty",
    });

    const utxoFilter = getUtxoStore();
    const inputs_ckb = [];
    let ckb_sum = BI.from(0);
    for await (const collect of collect_ckb.collect()) {
        const useUtxo = utxoFilter.find((utxo)=> utxo.txHash === collect.outPoint.txHash && utxo.index === collect.outPoint.index)

        if (useUtxo) {
            continue;
        }

        inputs_ckb.push(collect);
        ckb_sum = ckb_sum.add(collect.cellOutput.capacity);
        if (ckb_sum.gte(needCapacity)) {
            break;
        }
    }

    if (ckb_sum.lt(needCapacity)) {
        throw new Error("Not Enough capacity found");
    }

    const {codeHash:myCodeHash,hashType:myHashType} = myScript

    let cellDep_script_lock ;

    if(type === "joyid_ckb"){
        cellDep_script_lock = getJoyid_lock_type(net)
    }else if(isCCC(type)){
        cellDep_script_lock = OMNILOCK[net];

    }else {
        for (let key in newConfig.SCRIPTS) {
            let item = newConfig.SCRIPTS[key]
            if (item.CODE_HASH === myCodeHash && item.HASH_TYPE === myHashType) {
                cellDep_script_lock = item;
                break;
            }
            throw new Error("script not found")
        }
    }


    const version = net === 'testnet'?"preview":"latest";
    const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);
    txSkeleton = txSkeleton.update("inputs", (inputs) =>
        inputs.push(...inputs_ckb)
    );
    console.log("inputs_ckb",inputs_ckb);

    let outputCapacityChange  = ckb_sum.sub(MAX_FEE);

    if(type !== "joyid_ckb"){
        outputCapacityChange.sub(diffSUM);
    }


    txSkeleton = txSkeleton.update("outputs", (outputs) =>
        outputs.push({
            cellOutput: {
                capacity:`0x${outputCapacityChange.toString(16)}` ,
                lock: myScript,
            },
            data:"0x"
        })
    );


    const {TX_HASH:tx_hash_lock,INDEX:index_lock,DEP_TYPE:dep_type_lock} = cellDep_script_lock

    txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
        cellDeps.push(    {
            "outPoint": {
                "txHash": tx_hash_lock,
                "index": index_lock
            },
            "depType": dep_type_lock
        })
    );

    if(assetType === 0 ){
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push( sporeTypeScript.cellDep)
        );


    }else if(assetType === 1){
        const state = store.getState();
        const {didHash} = state;
        const {INDEX,DEP_TYPE} = DID_CONTRACT[net];
        txSkeleton = txSkeleton.update("cellDeps", (cellDeps) =>
            cellDeps.push(    {
                "outPoint": {
                    "txHash": didHash,
                    "index": INDEX
                },
                "depType": DEP_TYPE
            })
        );

    }


    let  sporeCoBuild = generateSporeCoBuild(typeObj, outputArr)
    txSkeleton = await updateWitness(txSkeleton,myScript,typeObj[0].cellOutput.type.codeHash,sporeCoBuild,type)

    const unsignedTx = helpers.createTransactionFromSkeleton(txSkeleton);


    const size =  getTransactionSizeByTx(unsignedTx)
    const newFee = calculateFeeCompatible(size,fee);

    let outputCapacityFact
    if(type === "joyid_ckb"){
         outputCapacityFact  = ckb_sum.sub(newFee).add(diffSUM);
    }else{
        outputCapacityFact  = ckb_sum.sub(newFee).sub(diffSUM);
    }

    console.log("joyid_ckb",ckb_sum.toString(),newFee.toString(),diffSUM.toString());


    let outputs = txSkeleton.get("outputs").toArray();
    let item = outputs[outputs.length - 1];
    item.cellOutput.capacity = outputCapacityFact.toHexString();
    txSkeleton = txSkeleton.update("outputs", (outputs) => {
        outputs.set(outputs.size - 1,item)
        return outputs;
    });

    return txSkeleton
}
