import {ccc, ClientPublicMainnet, ClientPublicTestnet} from "@ckb-ccc/ccc";
import {useEffect, useState} from "react";
import {SIG_WORD} from "../utils/constant.js";
import store from "../store/index.js";
import {saveAccount, saveLoading, saveSecondary, saveSignature, saveType, saveWalletList} from "../store/reducer.js";
import {useSelector} from "react-redux";
import {t} from "i18next";
import LogoImg from "../assets/images/newlogo.png";

const walletObj ={
    unisatCKB:"Unisat",
    OkxBTCCKB:"OKX",
    okxEvmCKB:"OKX",
    metamaskCKB:"Metamask",
}


export default function useCCCSigner(type){
    const client = import.meta.env.VITE_CHAIN === "testnet" ? new ClientPublicTestnet() :new ClientPublicMainnet();

    const [signer, setSigner] = useState(null);
    const walletList = useSelector(store => store.walletList);

    useEffect(() => {
        if(!type)return;

        getSigner(type)
    }, [type]);

    const getSigner = () =>{
        let signer;
        let eip6963Manager;
        let signerArr;
        switch (type) {
            case "reiCKB":
                signer = ccc.Rei.getReiSigners(client).find(item=>item.name === "CKB");
                setSigner(signer?.signer)
                break;
            case "joyidCCC":
                signer = ccc.JoyId.getJoyIdSigners(client,"Dobby",`${window.location.origin}/images/newlogo.png`).find(item=>item.name === "CKB");
                setSigner(signer?.signer)
                break;

            case "unisatCKB":
                signer = ccc.UniSat.getUniSatSigners(client).find(item=>item.name === "BTC");
                setSigner(signer?.signer)
                break;

            case "OkxBTCCKB":
                signerArr = ccc.Okx.getOKXSigners(client);
                signer = signerArr.find(item=>item.name === "BTC")
                setSigner(signer?.signer)
                break;

            case "metamaskCKB":
            case "okxEvmCKB":
                eip6963Manager = new ccc.Eip6963.SignerFactory(client);
                eip6963Manager.subscribeSigners((signer,detail) => {
                    if(type === "metamaskCKB" && detail?.info?.name === "MetaMask"){
                        setSigner(signer)
                    }else if(type === "okxEvmCKB" && detail?.info?.name === "OKX Wallet"){
                        setSigner(signer)
                    }
                });
                break;
        }

    }


    const checkValid = () =>{
        let validWallet=true ;
        switch (type) {
            case "unisatCKB":
                if(!window?.unisat){
                    validWallet = false;
                }
                break;
            case "metamaskCKB":
                if(!window?.ethereum){
                    validWallet = false;
                }
                break;
            case "OkxBTCCKB":
            case "okxEvmCKB":
                if(!window?.okxwallet){
                    validWallet = false;
                }
                break;

        }
        return validWallet;

    }

    const connectCCC = async() =>{
        if(!checkValid()){
            throw new Error(t("error.NOInstall",{type:walletObj[type]}))
        }
        if(!signer)return;


        await signer.connect();
        const account = await signer.getRecommendedAddress();
        const accountArr = await signer.getAddresses();

        const secondary = accountArr.find((item)=>item!== account);

        const signature = await signer.signMessage(SIG_WORD);

        let ckb = {
            account,
            type,
            signature
        }

        store.dispatch(saveWalletList({
            btc:walletList.btc,
            ckb
        }));

        store.dispatch(saveAccount(account));
        store.dispatch(saveSecondary(secondary));
        store.dispatch(saveType(type));
        store.dispatch(saveSignature(signature.signature));
        store.dispatch(saveLoading(false));
    }

    return {signer,connectCCC}
}
