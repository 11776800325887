import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import BTCImg from "../../assets/images/btc.png";
import CKBImg from "../../assets/images/ckb.png";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {FormatAddress, isBTC} from "../../utils/general.js";
import {useTranslation} from "react-i18next";
import store from "../../store/index.js";
import {
    saveAccount,
    saveJoyidInfo,
    saveLoading,
    saveSignature,
    saveToken,
    saveType,
    saveWalletList
} from "../../store/reducer.js";


const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 90;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    .lft{
        flex-grow: 1;
        font-family: din_bold;
        font-size: 18px;
        text-align: center;
    }
    .icon{
        cursor: pointer;
    }
    
`

const DlBox = styled.div`
    margin-top: 20px;
    dl{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        background: #000;
        padding: 0 20px;
        border-radius: 10px;
        gap: 20px;
        cursor: pointer;
        box-sizing: border-box;
        &.active{
            border: 1px solid #FF9500;
            .account{
                color: #FF9500;
            }
            button{
                color: #FF9500;
                background: transparent;
                border: 1px solid #FF9500;
                padding: 5px 10px;
                border-radius: 4px;
            }
        }

    }
    dt{
        text-align: right;
    }
    img{
        height: 30px;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 100px;
        margin: 10px 0;
    }
    dd{
        font-size: 14px;
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
   
    }
    span{
        display: inline-block;
        flex-grow: 1;
        padding: 10px 0;
    }

    button{
        color: #fff;
        background: transparent;
        border: 1px solid #fff;
        padding: 5px 10px;
        border-radius: 4px;
    }
`

export default function SwitchWallet({handleClose,handleNext,switchTochain}){
    const {t } = useTranslation();
    const type = useSelector(store => store.type);
    const account = useSelector(store => store.account);
    const walletList = useSelector(store => store.walletList);

    const [showType, setShowType] = useState("");


    useEffect(()=>{
        if(type){
            if(isBTC(type)){
                setShowType("btc")
            }else{
                setShowType("ckb")
            }
        }else{
            setShowType("")
        }

    },[walletList,type,account])

    useEffect(() => {

        const {btc,ckb} = walletList;
        if(!btc && !ckb)return;
        if(!btc){
            switchCurrent("ckb")
        }
        if(!ckb){
            switchCurrent("btc")
        }

    }, [walletList]);



    const disconnect = (chain) =>{


        const {btc,ckb} = walletList;

        let newList
        if(chain === "btc"){
            newList = {
                btc:null,
                ckb
            }
            localStorage.setItem("joyidType",null);

        }else {
            newList = {
                btc,
                ckb:null
            }
            store.dispatch(saveJoyidInfo(null));
        }

        store.dispatch(saveWalletList(newList));
        store.dispatch(saveAccount(null));

        store.dispatch(saveType(null));
        // store.dispatch(saveToken(null));

    }

    const handleswitchCurrent = (chain) =>{
        switchCurrent(chain)
        handleClose()
    }

    const switchCurrent = (chain) =>{
        let current = walletList[chain];

        if(!current)return;
        const {account,type,signature} = current
        store.dispatch(saveAccount(account));
        store.dispatch(saveType(type));
        store.dispatch(saveSignature(signature));


    }


    return <MaskBox>
        <ContentBox>
            <TitleBox>
                <div className="lft">{t('general.switch')}</div>
                <CloseOutlined className="icon" onClick={() => handleClose()} />
            </TitleBox>
            <DlBox>
                {
                    (!switchTochain || switchTochain === "btc") && <dl className={showType === "btc" ? "active" : ""}>
                        <dt>
                            <img src={BTCImg} alt=""/>
                        </dt>
                        {
                            !walletList.btc && <dd onClick={(e) => handleNext(e, "btc")}>
                                <span>{t('general.connectBTC')}</span>
                            </dd>
                        }
                        {
                            !!walletList.btc && <dd>
                                <span className="account"
                                      onClick={() => handleswitchCurrent("btc")}>{FormatAddress(walletList.btc.account)}</span>
                                <button onClick={() => disconnect("btc")}>{t('general.Disconnect')}</button>
                            </dd>
                        }

                    </dl>
                }
                {
                    (!switchTochain || switchTochain === "ckb") && <dl className={showType === "ckb" ? "active" : ""}>
                        <dt>
                            <img src={CKBImg} alt=""/>
                        </dt>

                        {
                            !walletList.ckb && <dd onClick={(e) => handleNext(e, "ckb")}>
                                <span>{t('general.connectCkB')}</span>
                            </dd>
                        }
                        {
                            !!walletList.ckb && <dd>
                            <span className="account"
                                  onClick={() => handleswitchCurrent("ckb")}>{FormatAddress(walletList.ckb.account)}</span>
                                <button onClick={() => disconnect("ckb")}>{t('general.Disconnect')}</button>
                            </dd>
                        }
                    </dl>
                }


            </DlBox>
        </ContentBox>
    </MaskBox>
}
