import styled from "styled-components";

import {useState} from "react";

import SendStep1_btc from "./sendStep1_btc.jsx";
import SendStep2_btc from "./SendStep2_btc.jsx";



const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`




export default function SendModal({handleClose,selectItem,refresh}){
    const [step,setStep]=useState(1);
    const [address,setAddress]= useState("");

    const handleStep = (num) =>{
        setStep(num)
    }

    const handleSend = (addr) =>{
        setAddress(addr)
        setStep(2)
    }



    return <MaskBox>
        <ContentBox>

            {
                step === 1 && <SendStep1_btc handleClose={handleClose} handleSend={handleSend} selectedItem={selectItem}   />
            }
            {
                step === 2 && <SendStep2_btc handleStep={handleStep} handleClose={handleClose} address={address} selectedItem={selectItem} refresh={refresh} />
            }
        </ContentBox>
        </MaskBox>
}
