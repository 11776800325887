import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import BTCImg from "../../assets/images/btc.png";
import CKBImg from "../../assets/images/ckb.png";
import {useTranslation} from "react-i18next";



const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    .lft{
        flex-grow: 1;
        font-family: din_bold;
        font-size: 18px;
        text-align: center;
    }
    .icon{
        cursor: pointer;
    }
    
`

const DlBox = styled.div`
    margin-top: 20px;
    dl{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background: #000;
        padding: 10px;
        border-radius: 10px;
        gap: 20px;
        cursor: pointer;
        box-sizing: border-box;
    }
    dt{
        width: 26%;
        text-align: right;
    }
    img{
        height: 30px;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 100px;
    }
    dd{
        font-size: 18px;
    }
`

export default function Chain({handleClose,handleNext}){
    const {t } = useTranslation();
    const handleChain = (e,chain) =>{
        // store.dispatch(saveChain(chain));
        handleNext(e,chain)
    }
    return <MaskBox>
        <ContentBox>
            <TitleBox>
                <div className="lft"> {t('general.network')}</div>

                <CloseOutlined className="icon" onClick={() => handleClose()} />
            </TitleBox>
            <DlBox>
                <dl onClick={(e)=>handleChain(e,"btc")}>
                    <dt>
                        <img src={BTCImg} alt=""/>
                    </dt>
                    <dd>BTC</dd>
                </dl>
                <dl onClick={(e)=>handleChain(e,"ckb")}>
                    <dt>
                        <img src={CKBImg} alt=""/>
                    </dt>
                    <dd>CKB</dd>
                </dl>
            </DlBox>
        </ContentBox>
    </MaskBox>
}
