import {
    getTransactionSize,
    serializeScript,
} from "@nervosnetwork/ckb-sdk-utils";
import {
    DataSource,
    ErrorCodes,
    NetworkType,
    TxBuildError,
    TxBuilder,
    bitcoin,
    createSendUtxosBuilder,
    networkTypeToConfig,
    FeeEstimator,
    utxoToInput,
} from "@rgbpp-sdk/btc";
import {
    Collector,
    NoRgbppLiveCellError,
    RGBPP_WITNESS_PLACEHOLDER,
    TypeAssetNotSupportedError,
    append0x,
    buildRgbppLockArgs,
    calculateCommitment,
    calculateTransactionFee,
    getRgbppLockConfigDep,
    getRgbppLockDep,
    getSecp256k1CellDep,
    getXudtDep,
    isBtcTimeLockCell,
    isRgbppLockCell,
    compareInputs,
    getSporeTypeDep,
    RGBPP_TX_WITNESS_MAX_SIZE,
    getRgbppLockScript,
    RgbppUtxoBindMultiTypeAssetsError,
} from "@rgbpp-sdk/ckb";
import { BtcAssetsApi } from "@rgbpp-sdk/service";
import { unpackRgbppLockArgs } from "@rgbpp-sdk/ckb";
import * as btcSigner from "@scure/btc-signer";

import {
    buildPreLockArgs,
    calculateRgbppCellCapacity,
    estimateWitnessSize,
    genRgbppLockScript,
    generateSporeTransferCoBuild,
    isTypeAssetSupported,
    throwErrorWhenTxInputsExceeded,
    u128ToLe,
} from "@rgbpp-sdk/ckb";
import { blockchain } from "@ckb-lumos/base";
import store from "../store/index.js";
import { getNet, handlePublicKey, handleSignPsbt } from "../utils/wallet.js";
import { Params, TOKEN_CONFIG, SPV_CONFIG } from "../utils/constant.js";
import { Buffer } from 'buffer/';


let BTC_ASSETS_API_URL
let BTC_ASSETS_ORGIN
let BTC_ASSETS_TOKEN
let DEFAULT_WITNESS_ADDR
let DEFAULT_WITNESS_PUBKEY

const configBTC = () => {

    BTC_ASSETS_API_URL = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
    BTC_ASSETS_ORGIN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
    BTC_ASSETS_TOKEN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

    DEFAULT_WITNESS_ADDR = SPV_CONFIG[import.meta.env.VITE_MODE].DEFAULT_WITNESS_ADDR;
    DEFAULT_WITNESS_PUBKEY = SPV_CONFIG[import.meta.env.VITE_MODE].DEFAULT_WITNESS_PUBKEY;
}
configBTC()


function network2RGBPPNetworkType(network /* from testnet / livenet to NetworkType */) {
    return network === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;
};

export class TxHelper {
    // private static _instance: TxHelper;
    // private constructor() {}

    static get instance() {
        if (!TxHelper._instance) {
            TxHelper._instance = new TxHelper();
        }
        return this._instance;
    }

    async createListPsbt(params) {

        const networkType = network2RGBPPNetworkType(params.network);
        const isMainnet = (networkType === NetworkType.MAINNET);

        const cfg = networkTypeToConfig(networkType);

        console.log("====== create list psbt", cfg);

        const service = BtcAssetsApi.fromToken(
            //BTC_ASSETS_API_URL
            BTC_ASSETS_API_URL,
            // BTC_ASSETS_TOKEN
            BTC_ASSETS_TOKEN,
            // BTC_ASSETS_ORGIN
            BTC_ASSETS_ORGIN
        );

        // >>
        const source = new DataSource(service, networkType);

        const utxo = await source.getUtxo(params.rgbpp_txHash, params.rgbpp_txIdx);
        if (!utxo) {
            throw TxBuildError.withComment(
                ErrorCodes.CANNOT_FIND_UTXO,
                `hash: ${params.rgbpp_txHash}, index: ${params.rgbpp_txIdx}`
            );
        }
        if (utxo.address !== params.fromAddress) {
            throw TxBuildError.withComment(
                ErrorCodes.REFERENCED_UNPROVABLE_UTXO,
                `hash: ${params.rgbpp_txHash}, index: ${params.rgbpp_txIdx}`
            );
        }

        // const config = networkTypeToConfig(source.networkType);
        // const minUtxoSatoshi = config.rgbppUtxoDustLimit;


        const { builder } = await this.dex_createSendUtxosBuilder(
            {
                inputs: [
                    {
                        ...utxo,
                        txid: "0000000000000000000000000000000000000000000000000000000000000001",
                        vout: 0,
                        value: 0,
                        address: params.witnessAddr,
                        pubkey: params.witnessPubkey,
                    },
                    {
                        ...utxo,
                        pubkey: params.fromPubkey,
                    },
                ],
                outputs: [
                    {
                        data: Buffer.from("0x" + "00".repeat(32), "hex"), // RGBPP commitment
                        value: 0,
                        fixed: true, // mark as fixed, so the output.value will not be changed
                    },
                    {
                        address: params.fromAddress,
                        value: params.price,
                        fixed: true,
                        minUtxoSatoshi: params.price,
                    },
                ],
                from: params.fromAddress,
                fromPubkey: params.fromPubkey,
                changeAddress: params.fromAddress,
                feeRate: 1,
                source,
                minUtxoSatoshi: 0,
            },
            false
        );

        const psbt = builder.toPsbt();

        psbt.updateInput(1, {
            sighashType: btcSigner.SigHash.SINGLE_ANYONECANPAY,
            // sighashType: [btcSigner.SigHash.SINGLE_ANYONECANPAY],
        });

        return psbt;
    }

    async createBuyPsbt(params) {
        const network = params.network;
        const collector = new Collector({
            ckbNodeUrl: Params[network].node,
            ckbIndexerUrl: Params[network].indexer,
        });
        // >>
        const networkType = network2RGBPPNetworkType(network);

        const isMainnet = networkType === NetworkType.MAINNET;

        const cfg = networkTypeToConfig(networkType);

        const salePsbt = bitcoin.Psbt.fromHex(params.psbtHex, {
            network: cfg.network,
        });

        const txHash = Buffer.from(salePsbt.txInputs[1].hash.reverse()).toString(
            "hex"
        );
        const sporeRgbppLockArgs = buildRgbppLockArgs(
            salePsbt.txInputs[1].index,
            txHash
        );

        const service = BtcAssetsApi.fromToken(
            //BTC_ASSETS_API_URL
            BTC_ASSETS_API_URL,
            // BTC_ASSETS_TOKEN
            BTC_ASSETS_TOKEN,
            // BTC_ASSETS_ORGIN
            BTC_ASSETS_ORGIN
        );
        // >>
        const source = new DataSource(service, networkType);


        let ckbVirtualTxResult;

        if (params.itemType === "spore") {
            const sporeTypeBytes = serializeScript(params.script);
            ckbVirtualTxResult = await this.dex_genTransferSporeCkbVirtualTx({
                collector,
                sporeRgbppLockArgs,
                sporeTypeBytes,
                isMainnet,
            });
        } else if (params.itemType === "xudt") {
            const xudtTypeBytes = serializeScript(params.script);
            ckbVirtualTxResult = await this.dex_genBtcTransferCkbVirtualTx({
                collector,
                rgbppLockArgsList: [sporeRgbppLockArgs],
                xudtTypeBytes,
                transferAmount: BigInt(10000), // [TODO] why 10000 ????
                isMainnet,
            });
        } else {
            throw new Error("Unknown item type");
        }


        const { commitment, ckbRawTx } = ckbVirtualTxResult;

        console.log("====ckbVirtualTxResult=", ckbVirtualTxResult);

        // Send BTC tx
        const psbt = await this.dex_sendRgbppUtxosBuilder({
            isTestnet: !isMainnet,

            ckbVirtualTx: ckbRawTx,
            commitment,
            ckbCollector: collector,
            source,

            psbtHex: params.psbtHex,

            buyerAddr: params.buyAddress,
            buyerPubkey: params.buyPubKey,

            feeRate: params.feeRate,
        });
        // >>

        return { psbt, ckbVirtualTxResult };
    }

    dex_genTransferSporeCkbVirtualTx = async ({
        collector,
        sporeRgbppLockArgs,
        sporeTypeBytes,
        isMainnet,
        witnessLockPlaceholderSize,
        ckbFeeRate,
    }) => {
        const sporeRgbppLock = {
            ...getRgbppLockScript(isMainnet),
            args: append0x(sporeRgbppLockArgs),
        };
        const sporeCells = await collector.getCells({
            lock: sporeRgbppLock,
            // isDataEmpty: false,
            isDataMustBeEmpty: false,
        });
        if (!sporeCells || sporeCells.length === 0) {
            throw new NoRgbppLiveCellError(
                "No spore rgbpp cells found with the spore rgbpp lock args"
            );
        }
        if (sporeCells.length > 1) {
            throw new RgbppUtxoBindMultiTypeAssetsError(
                "The UTXO is bound to multiple spores"
            );
        }
        const sporeCell = sporeCells[0];

        if (!sporeCell.output.type) {
            throw new RgbppUtxoBindMultiTypeAssetsError(
                "The cell with the rgbpp lock args has no spore asset"
            );
        }

        if (
            append0x(serializeScript(sporeCell.output.type)) !==
            append0x(sporeTypeBytes)
        ) {
            throw new RgbppUtxoBindMultiTypeAssetsError(
                "The cell type with the rgbpp lock args does not match"
            );
        }

        const inputs = [
            {
                previousOutput: sporeCell.outPoint,
                since: "0x0",
            },
        ];

        const outputs = [
            {
                ...sporeCell.output,
                // The BTC transaction Vouts[0] for OP_RETURN, Vouts[1] for spore
                lock: genRgbppLockScript(buildPreLockArgs(2), isMainnet),
            },
        ];
        const outputsData = [sporeCell.outputData];
        const cellDeps = [
            getRgbppLockDep(isMainnet),
            getRgbppLockConfigDep(isMainnet),
            getSporeTypeDep(isMainnet),
        ];
        const sporeCoBuild = generateSporeTransferCoBuild(sporeCell, outputs[0]);
        const witnesses = [RGBPP_WITNESS_PLACEHOLDER, sporeCoBuild];

        const ckbRawTx = {
            version: "0x0",
            cellDeps,
            headerDeps: [],
            inputs,
            outputs,
            outputsData,
            witnesses,
        };

        let changeCapacity = BigInt(sporeCell.output.capacity);
        const txSize =
            getTransactionSize(ckbRawTx) +
            (witnessLockPlaceholderSize ?? RGBPP_TX_WITNESS_MAX_SIZE);
        const estimatedTxFee = calculateTransactionFee(txSize, ckbFeeRate);
        changeCapacity -= estimatedTxFee;

        ckbRawTx.outputs[ckbRawTx.outputs.length - 1].capacity = append0x(
            changeCapacity.toString(16)
        );

        const virtualTx = {
            ...ckbRawTx,
        };
        const commitment = calculateCommitment(virtualTx);

        return {
            ckbRawTx,
            commitment,
            sporeCell,
            needPaymasterCell: false,
            sumInputsCapacity: sporeCell.output.capacity,
        };
    };

    dex_genBtcTransferCkbVirtualTx = async ({
        collector,
        xudtTypeBytes,
        rgbppLockArgsList,
        transferAmount,
        isMainnet,
        noMergeOutputCells,
        witnessLockPlaceholderSize,
        ckbFeeRate,
    }) => {
        const xudtType = blockchain.Script.unpack(
            xudtTypeBytes
        );

        if (!isTypeAssetSupported(xudtType, isMainnet)) {
            throw new TypeAssetNotSupportedError(
                "The type script asset is not supported now"
            );
        }

        const rgbppLocks = rgbppLockArgsList.map((args) =>
            genRgbppLockScript(args, isMainnet)
        );
        let rgbppCells = [];
        for await (const rgbppLock of rgbppLocks) {
            const cells = await collector.getCells({
                lock: rgbppLock,
                type: xudtType,
            });
            if (!cells || cells.length === 0) {
                throw new NoRgbppLiveCellError(
                    "No rgbpp cells found with the xudt type script and the rgbpp lock args"
                );
            }
            rgbppCells = [...rgbppCells, ...cells];
        }
        rgbppCells = rgbppCells.sort(compareInputs);

        let inputs = [];
        let sumInputsCapacity = BigInt(0);
        const outputs = [];
        const outputsData = [];
        let changeCapacity = BigInt(0);

        if (noMergeOutputCells) {
            for (const [index, rgbppCell] of rgbppCells.entries()) {
                inputs.push({
                    previousOutput: rgbppCell.outPoint,
                    since: "0x0",
                });
                sumInputsCapacity += BigInt(rgbppCell.output.capacity);
                outputs.push({
                    ...rgbppCell.output,
                    // The Vouts[0] for OP_RETURN and Vouts[1], Vouts[2], ... for RGBPP assets
                    lock: genRgbppLockScript(buildPreLockArgs(index + 1), isMainnet),
                });
                outputsData.push(rgbppCell.outputData);
            }
            changeCapacity = BigInt(
                rgbppCells[rgbppCells.length - 1].output.capacity
            );
        } else {
            const collectResult = collector.collectUdtInputs({
                liveCells: rgbppCells,
                needAmount: transferAmount,
            });
            inputs = collectResult.inputs;

            throwErrorWhenTxInputsExceeded(inputs.length);

            sumInputsCapacity = collectResult.sumInputsCapacity;

            rgbppCells = rgbppCells.slice(0, inputs.length);

            const rpbppCellCapacity = calculateRgbppCellCapacity(xudtType);
            outputsData.push(append0x(u128ToLe(transferAmount)));

            changeCapacity = sumInputsCapacity;
            // The Vouts[0] for OP_RETURN and Vouts[1], Vouts[2], ... for RGBPP assets
            outputs.push({
                lock: genRgbppLockScript(buildPreLockArgs(2), isMainnet),
                type: xudtType,
                capacity: append0x(rpbppCellCapacity.toString(16)),
            });
            if (collectResult.sumAmount > transferAmount) {
                outputs.push({
                    lock: genRgbppLockScript(buildPreLockArgs(3), isMainnet),
                    type: xudtType,
                    capacity: append0x(rpbppCellCapacity.toString(16)),
                });
                outputsData.push(
                    append0x(u128ToLe(collectResult.sumAmount - transferAmount))
                );
                changeCapacity -= rpbppCellCapacity;
            }
        }

        const cellDeps = [
            getRgbppLockDep(isMainnet),
            getXudtDep(isMainnet),
            getRgbppLockConfigDep(isMainnet),
        ];
        const needPaymasterCell = inputs.length < outputs.length;
        if (needPaymasterCell) {
            cellDeps.push(getSecp256k1CellDep(isMainnet));
        }
        const witnesses = [];
        const lockArgsSet = new Set();
        for (const cell of rgbppCells) {
            if (lockArgsSet.has(cell.output.lock.args)) {
                witnesses.push("0x");
            } else {
                lockArgsSet.add(cell.output.lock.args);
                witnesses.push(RGBPP_WITNESS_PLACEHOLDER);
            }
        }

        const ckbRawTx = {
            version: "0x0",
            cellDeps,
            headerDeps: [],
            inputs,
            outputs,
            outputsData,
            witnesses,
        };

        if (!needPaymasterCell) {
            const txSize =
                getTransactionSize(ckbRawTx) +
                (witnessLockPlaceholderSize ?? estimateWitnessSize(rgbppLockArgsList));
            const estimatedTxFee = calculateTransactionFee(txSize, ckbFeeRate);

            changeCapacity -= estimatedTxFee;
            ckbRawTx.outputs[ckbRawTx.outputs.length - 1].capacity = append0x(
                changeCapacity.toString(16)
            );
        }

        const virtualTx = {
            ...ckbRawTx,
        };
        const commitment = calculateCommitment(virtualTx);

        return {
            ckbRawTx,
            commitment,
            needPaymasterCell,
            sumInputsCapacity: append0x(sumInputsCapacity.toString(16)),
        };
    };

    async dex_sendRgbppUtxosBuilder(props) {
        console.log("========== dex_sendRgbppUtxosBuilder", props);

        const networkType = props.isTestnet ? NetworkType.TESTNET : NetworkType.MAINNET;

        const isMainnet = networkType === NetworkType.MAINNET;

        const cfg = networkTypeToConfig(networkType);

        const salePsbt = bitcoin.Psbt.fromHex(props.psbtHex, {
            network: cfg.network,
            maximumFeeRate: props.feeRate * 1000,
        });
        console.log("========== sale psbt", salePsbt);

        const saleAddress = salePsbt.txOutputs[1].address;

        const btcInputs = [];
        const btcOutputs = [];
        let lastCkbTypeOutputIndex = -1;

        const ckbVirtualTx = props.ckbVirtualTx;
        const config = networkTypeToConfig(props.source.networkType);
        const isCkbMainnet = props.source.networkType === NetworkType.MAINNET;

        console.log("========== isCkbMainnet", isCkbMainnet);

        // Handle and check inputs
        for (let i = 0; i < ckbVirtualTx.inputs.length; i++) {
            const ckbInput = ckbVirtualTx.inputs[i];

            const ckbLiveCell = await props.ckbCollector.getLiveCell(
                ckbInput.previousOutput
            );
            const isRgbppLock = isRgbppLockCell(ckbLiveCell.output, isCkbMainnet);


            console.log("========== isRgbppLock", isRgbppLock);


            // If input.lock == RgbppLock, add to inputs if:
            // 1. input.lock.args can be unpacked to RgbppLockArgs
            // 2. utxo can be found via the DataSource.getUtxo() API
            // 3. utxo.scriptPk == addressToScriptPk(props.from)
            // 4. utxo is not duplicated in the inputs
            if (isRgbppLock) {
                const args = unpackRgbppLockArgs(ckbLiveCell.output.lock.args);
                const utxo = await props.source.getUtxo(args.btcTxId, args.outIndex);

                console.log("========== args", args, ckbLiveCell.output.lock.args);
                console.log("========== utxo", utxo);
                console.log("========== utxo.address", utxo.address, saleAddress);

                if (!utxo) {
                    throw TxBuildError.withComment(
                        ErrorCodes.CANNOT_FIND_UTXO,
                        `hash: ${args.btcTxid}, index: ${args.outIndex}`
                    );
                }
                if (utxo.address !== saleAddress) {
                    throw TxBuildError.withComment(
                        ErrorCodes.REFERENCED_UNPROVABLE_UTXO,
                        `hash: ${args.btcTxid}, index: ${args.outIndex}`
                    );
                }

                const foundInInputs = btcInputs.some(
                    (v) => v.txid === utxo.txid && v.vout === utxo.vout
                );
                if (foundInInputs) {
                    continue;
                }

                btcInputs.push({
                    ...utxo,
                    pubkey: props.buyerPubkey, // for pass build tx
                });
            }
        }

        // The inputs.length should be >= 1
        if (btcInputs.length < 1) {
            throw new TxBuildError(ErrorCodes.CKB_INVALID_INPUTS);
        }

        // Handle and check outputs
        // console.log("======== ckbVirtualTx", ckbVirtualTx);
        const toAddress = [props.buyerAddr];
        for (let i = 0; i < ckbVirtualTx.outputs.length; i++) {
            const ckbOutput = ckbVirtualTx.outputs[i];
            const isRgbppLock = isRgbppLockCell(ckbOutput, isCkbMainnet);
            const isBtcTimeLock = isBtcTimeLockCell(ckbOutput, isCkbMainnet);

            // If output.type !== null, then the output.lock must be RgbppLock or RgbppTimeLock
            if (ckbOutput.type) {
                if (!isRgbppLock && !isBtcTimeLock) {
                    throw new TxBuildError(ErrorCodes.CKB_INVALID_CELL_LOCK);
                }

                // If output.type !== null，update lastTypeInput
                lastCkbTypeOutputIndex = i;
            }

            // If output.lock == RgbppLock, generate a corresponding output in outputs
            if (isRgbppLock) {
                const toBtcAddress = toAddress[i];
                const minUtxoSatoshi = config.rgbppUtxoDustLimit;
                btcOutputs.push({
                    fixed: true,
                    address: toBtcAddress ?? saleAddress,
                    value: minUtxoSatoshi,
                    minUtxoSatoshi,
                });
            }
        }

        // By rules, the length of type outputs should be >= 1
        // The "lastTypeOutputIndex" is -1 by default so if (index < 0) it's invalid
        if (lastCkbTypeOutputIndex < 0) {
            throw new TxBuildError(ErrorCodes.CKB_INVALID_OUTPUTS);
        }

        // Verify the provided commitment
        const calculatedCommitment = calculateCommitment({
            inputs: ckbVirtualTx.inputs,
            outputs: ckbVirtualTx.outputs.slice(0, lastCkbTypeOutputIndex + 1),
            outputsData: ckbVirtualTx.outputsData.slice(
                0,
                lastCkbTypeOutputIndex + 1
            ),
        });
        if (props.commitment !== calculatedCommitment) {
            throw new TxBuildError(ErrorCodes.CKB_UNMATCHED_COMMITMENT);
        }

        const mergedBtcOutputs = (() => {
            const merged = [];

            // Add commitment to the beginning of outputs
            merged.push({
                data: props.commitment,
                fixed: true,
                value: 0,
            });

            for (let i = 1; i < salePsbt.txOutputs.length; i++) {
                const output = salePsbt.txOutputs[i];
                merged.push({
                    address: output.address,
                    script: output.script,
                    fixed: true,
                    value: output.value,
                    minUtxoSatoshi: output.value,
                });
            }

            // Add outputs
            merged.push(...btcOutputs);

            // Add Service Fee
            let serviceFeeRate = props.serviceFeeRate ?? 0.02; // set default to 2%
            let serviceFee = Math.ceil(salePsbt.txOutputs[1].value * serviceFeeRate);
            merged.push({
                address: DEFAULT_WITNESS_ADDR,
                value: serviceFee,
                fixed: true,
                minUtxoSatoshi: serviceFee,
            });

            return merged;
        })();

        console.log("======== mergedBtcOutputs", mergedBtcOutputs);

        // Use RGBPP SDK to build the BTC transaction
        // recount feeRate
        // <<
        const estimator = FeeEstimator.fromRandom(props.source.networkType);
        btcInputs.forEach((input) => {
            const replacedUtxo = estimator.replaceUtxo(input);
            return utxoToInput(replacedUtxo);
        });
        const { builder, feeRate, fee } = await createSendUtxosBuilder({
            inputs: btcInputs,
            // inputs: [],
            outputs: mergedBtcOutputs,
            from: props.buyerAddr,
            source: props.source,
            feeRate: props.feeRate,
            fromPubkey: props.buyerPubkey,
            onlyConfirmedUtxos: true,
        });
        // >>

        console.log("======== builder---", builder);

        console.log("======== feeRate, fee", feeRate, fee);

        const psbt = builder.toPsbt();

        console.log("======== before psbt", psbt, salePsbt);

        // inputs
        salePsbt.data.globalMap.unsignedTx["tx"]["ins"][0] =
            psbt.data.globalMap.unsignedTx["tx"]["ins"][1];
        salePsbt.data.inputs[0] = psbt.data.inputs[1];

        for (let i = 2; i < psbt.txInputs.length; i++) {
            const input = psbt.txInputs[i];

            console.log("===i", i, psbt.data.inputs[i])

            if (psbt.data.inputs[i].tapInternalKey != undefined) {
                salePsbt.addInput({
                    hash: input.hash,
                    index: input.index,
                    witnessUtxo: psbt.data.inputs[i].witnessUtxo,
                    tapInternalKey: psbt.data.inputs[i].tapInternalKey,
                });
            } else {
                salePsbt.addInput({
                    hash: input.hash,
                    index: input.index,
                    witnessUtxo: psbt.data.inputs[i].witnessUtxo,
                });
            }

        }

        console.log("===salePsbt", salePsbt);

        // outputs
        salePsbt.data.globalMap.unsignedTx["tx"]["outs"][0] =
            psbt.data.globalMap.unsignedTx["tx"]["outs"][0];
        salePsbt.data.outputs[0] = psbt.data.outputs[0];

        salePsbt.data.globalMap.unsignedTx["tx"]["outs"][1] =
            psbt.data.globalMap.unsignedTx["tx"]["outs"][1];
        salePsbt.data.outputs[1] = psbt.data.outputs[1];

        for (let i = 2; i < psbt.txOutputs.length; i++) {
            const output = psbt.txOutputs[i];

            if (i == psbt.txOutputs.length - 1) {
                salePsbt.data.addOutput({
                    address: output.address,
                    script: output.script,
                    value: output.value,
                });
            } else {
                salePsbt.data.addOutput({
                    address: output.address,
                    script: output.script,
                    value: output.value,
                });
            }
        }

        console.log("======== after psbt", salePsbt);
        return salePsbt;
    }

    async dex_createSendUtxosBuilder(
        props,
        isPayFee
    ) {
        const tx = new TxBuilder({
            source: props.source,
            feeRate: props.feeRate,
            minUtxoSatoshi: props.minUtxoSatoshi,
            onlyConfirmedUtxos: props.onlyConfirmedUtxos,
            onlyNonRgbppUtxos: true,
        });

        tx.addInputs(props.inputs);
        tx.addOutputs(props.outputs);

        if (props.onlyConfirmedUtxos) {
            await tx.validateInputs();
        }

        if (isPayFee) {
            const paid = await tx.payFee({
                address: props.from,
                publicKey: props.fromPubkey,
                changeAddress: props.changeAddress,
            });

            return {
                builder: tx,
                fee: paid.fee,
                feeRate: paid.feeRate,
            };
        } else {
            return {
                builder: tx,
                fee: 0,
                feeRate: 0,
            };
        }
    }
}



export const testListPsbt = async (item, sell_price) => {
    const account = store.getState().account;
    const type = store.getState().type;
    let network = await getNet(type)

    const networkType = network2RGBPPNetworkType(network);

    // const isMainnet = networkType === NetworkType.MAINNET;

    const isMobile =/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    let publicKey = await handlePublicKey(type,isMobile)

    console.log("publicKey", publicKey)

    if (!item?.utxo_data) return;
    let newObj = JSON.parse(item?.utxo_data)
    if (!newObj?.ckb) return;

    const { txHash: rgbpp_txHash, idx: rgbpp_txIdx } = newObj.ckb;
    const listPsbt = await TxHelper.instance.createListPsbt({
        network,
        rgbpp_txHash: rgbpp_txHash,
        rgbpp_txIdx: rgbpp_txIdx,
        price: sell_price,
        fromAddress: account,
        fromPubkey: publicKey,
        witnessAddr: DEFAULT_WITNESS_ADDR,
        witnessPubkey: DEFAULT_WITNESS_PUBKEY,
    });

    console.log("listPsbt", listPsbt, type)


    const psbtHex = await handleSignPsbt(
        listPsbt.toHex(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type,
        {
            autoFinalized: false,
            toSignInputs: [
                {
                    index: 1,
                    address: account,
                    publicKey: publicKey,
                    sighashTypes: [btcSigner.SigHash.SINGLE_ANYONECANPAY],

                },
            ],
        }
    );

    console.log("==psbtHex", psbtHex)

    const cfg = networkTypeToConfig(networkType);
    console.log(
        "====== psbtHex",
        psbtHex,
        bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })
    );
    return psbtHex
};

export const handleBuyPsbt = async (psbtObj, utxo_data, txFeeRate /* tx fee rate is choose by client */, service_fee /* service fee is total price * fee rate */) => {

    const account = store.getState().account;
    const type = store.getState().type;
    let network = await getNet(type)
    const networkType = network2RGBPPNetworkType(network);

    const isMainnet = networkType === NetworkType.MAINNET;

    const isMobile =/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    let publicKey = await handlePublicKey(type,isMobile)

    console.log("publicKey", publicKey)

    let { psbt: listPsbtHex } = psbtObj;
    const utxoData = JSON.parse(utxo_data)
    console.log("utxoData", utxoData)

    const listItemType = utxoData?.ckb?.ckbCellInfo.udt_type.indexOf("spore") > -1 ? "spore" : 'xudt';
    const listItemScript = utxoData?.ckb?.ckbCellInfo.type_script;

    console.log("listItemScript", listItemScript)

    const { psbt: buyPsbt, ckbVirtualTxResult } =
        await TxHelper.instance.createBuyPsbt({
            network,
            psbtHex: listPsbtHex,
            buyAddress: account,
            buyPubKey: publicKey,
            itemType: listItemType,
            script: listItemScript,
            feeRate: txFeeRate,
            serviceFee: service_fee, // default is 1%
        });

    console.log("createBuyPsbt", buyPsbt, ckbVirtualTxResult)


    const signInputList = [];

    for (let i = 0; i < buyPsbt.txInputs.length; i++) {
        if (!buyPsbt.data.inputs[i].tapKeySig && !buyPsbt.data.inputs[i].partialSig) {
            signInputList.push({
                index: i,
                address: account,
                publicKey: publicKey,
            });
        }
    }



    const psbtHex = await handleSignPsbt(
        buyPsbt.toHex(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type,
        {
            autoFinalized: false,
            toSignInputs: signInputList,
        }
    );

    console.log("====psbtHex", psbtHex);

    const cfg = networkTypeToConfig(networkType);
    const newPsbt = bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network });

    console.log("====== psbtHex", psbtHex, newPsbt);
    newPsbt.finalizeAllInputs();
    console.log("====== after finalize newPsbt", newPsbt, newPsbt.toHex());

    const btcTx = newPsbt.extractTransaction();

    console.log("======= btc tx is ", btcTx.toHex());

    return {
        txHash: btcTx.toHex(),
        ckbVirtualTxResult
    };

};
