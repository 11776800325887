import styled from "styled-components";
import {App, Input} from 'antd';

import {CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
const { TextArea } = Input;

import {getAddressInfo, validate} from 'bitcoin-address-validation';
import {useSelector} from "react-redux";
import {getUtxoOutspend} from "../../api/btc.js";
import LoadingBtn from "../loadingBtn.jsx";

const MidBox = styled.div`
    display: flex;
    flex-direction: column;

    .btnBox{
        padding: 15px;
        align-items: center;
        justify-content: center;
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        color: #0D0C0B;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        gap: 10px;
        &:disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
`

const TextBox = styled(TextArea)`
    margin: 30px 0 50px;
    resize: none!important;
    border: 1px solid #ff9500;
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    font-family: din_bold;
    font-size: 18px;

    .lftImg{
        cursor: pointer;
    }
    .lft{
        flex-grow: 1;
        text-align: center;

    }
    .icon{
        cursor: pointer;
    }
    
`


export default function SendStep1_btc ({handleClose,handleSend,selectedItem}){
    const {t } = useTranslation();
    const account = useSelector(store => store.account);
    const {  notification } = App.useApp();

    const [input,setInput] = useState("");
    const [isValidated, setIsValidated] = useState(false);

    const net = import.meta.env.VITE_CHAIN === "testnet"?"testnet":"mainnet"

    useEffect(() => {
        if(!selectedItem)return;
        getValidate()
    }, [selectedItem]);

    const getValidate = async() =>{
        const utxoBefore = selectedItem?.utxo_data ? JSON.parse(selectedItem.utxo_data) : null;

        const {ckb:{txHash,idx}} = utxoBefore;

        const isMainnet = import.meta.env.VITE_CHAIN !== "testnet";
        try{
            let rt = await getUtxoOutspend(isMainnet,txHash,idx);

            if(rt?.spent){
                notification.error({
                    message: "Error",
                    duration: 0,
                    description:t("error.inProgress")
                });
            }else{
                setIsValidated(true)
            }
        }catch (e) {
            console.log(e)
            notification.error({
                message: "Error",
                description:e?.response?.data?.message || e.message || e.reason
            });
        }

    }

    const handleConfirm  = () =>{
        if(!validate(input, net)) {
            notification.error({
                message: "Error",
                description: t("error.accountTypeBTC")
            });

            return;
        }
        if(account.toLowerCase() === input.toLowerCase() ){
            notification.error({
                message: "Error",
                description: t("error.sameAccount")
            });

            return;
        }

        if(getAddressInfo(input).type !== "p2tr" && getAddressInfo(input).type !== "p2wpkh"){
            notification.error({
                message: "Error",
                description:t("error.incorrect")
            });
            return;
        }
        handleSend(input)
    }

    return <div>
        <TitleBox>

            <div className="lft">{t("detail.sendDob")}</div>
            <CloseOutlined className="icon" onClick={() => handleClose()}/>
        </TitleBox>
        <MidBox>
            <TextBox value={input} autoSize={{
                minRows: 1,
                maxRows: 10,
            }}   onChange={e => setInput(e.target.value)} />
            <button className="btnBox" disabled={!input || !isValidated } onClick={()=>handleConfirm()}>{t("detail.Send")}

                {
                    !isValidated &&  <LoadingBtn />
                }
            </button>
        </MidBox>
    </div>
}
