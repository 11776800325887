import styled from "styled-components";
import {BrowserRouter as Router} from "react-router-dom";
import RouterLink from "../../router/router.jsx";
import Header from "./header.jsx";
import Footer from "./footerNew.jsx";
import {useEffect, useState} from "react";
import Assistant from "../modal/assistant.jsx";

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`


const ContentBox = styled.div`
    width: 1274px;
    flex-grow: 1;
    margin: 64px auto 20px;
    @media (max-width: 1274px) {
        margin:80px auto 20px;
        width: 100%;

        box-sizing: border-box;
    }
`
export default function LayoutBox(){

    return  <Router>
        <Layout>
        <Header />
            <Assistant />
        <ContentBox>
                <RouterLink />
        </ContentBox>
        <Footer />
    </Layout>
    </Router>
}
