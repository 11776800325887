import styled from "styled-components";
import { CloseOutlined,UpOutlined,DownOutlined } from '@ant-design/icons';
import {App, Input, Slider} from 'antd';
import {useEffect, useState} from "react";
// import {assetsCreate} from "../../api/assets.js";
import {useSelector} from "react-redux";
import store from "../../store/index.js";
import {getFeeRate} from "../../api/btc.js";
import {saveLoading} from "../../store/reducer.js";
import {assetsBuy, getPsbt} from "../../api/assets.js";
import {getNet} from "../../utils/wallet.js";
import {FormatAddress, formatImage, handleBg} from "../../utils/general.js";
import {BitcoinUnit} from "bitcoin-units";
import {handleBuyPsbt} from "../../lib/TxHelper.js";
import BigNumber from "bignumber.js";
import LoadingBtn from "../loadingBtn.jsx";
import {bitcoin, NetworkType, networkTypeToConfig} from "@rgbpp-sdk/btc";
import {useTranslation} from "react-i18next";

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    .lft{
        flex-grow: 1;

    }
    .icon{
        cursor: pointer;
    }
    
`
const Tips = styled.div`
    font-size: 12px;
    opacity: 0.6;
    padding-top: 10px;
    margin-bottom: 10px;
`

const DLBox = styled.div`
    dl{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child{
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    dd{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .num{
        font-family: din_bold;
    }
    .sym{
        opacity: 0.6;
    }
    .tag{
        border: 1px solid rgba(255,255,255,1);
        font-size: 12px;
        padding:2px 5px;
        border-radius: 4px;
        opacity: 0.5;
        line-height: 1em;
    }
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .innerImg{
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        background: ${props => props.bg};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            //image-rendering: pixelated;
        }
    }
    .innerTxt{
        background: #000;
        width: 80px;
        height: 80px;
        font-size: 12px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap:break-word;
        -webkit-line-clamp:3;
        -webkit-box-orient: vertical;
    }
    .title{
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 400px;
    }
    .id{
        color: #ff9500;
        font-family:din_regular;
        margin-top: 10px;
    }
    @media (max-width: 1274px) {
        .title{
            width: 58vw;
            word-break: break-all;
        }
    }
`

const BtmBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 20px;
    .price{
        color: #ff9500;
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button{
        width: 49%;
        height: 40px;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        &:disabled{
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .confirm{
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
    }
    .cancel{
        border:1px solid #FF9500;
        color: #ff9500;
        background: none;
    }
`
const FeeLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .custom{
        border:1px solid #FF9500;
        color: #ff9500;
        padding:5px 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .num{
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
        padding-left: 5px;
    }
    .rhtNum{
        color: #ff9500;
    }
`

const CustomLine = styled.div`
    .fst{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        width: 49%;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover,&.active{
            cursor: pointer;
            border: 1px solid #ff9500;
        }
     
    }
    .none{
        display: none;
    }
    .title{
        font-size: 12px;
        opacity: 0.6;
    }
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-right: 10px;
    }
    .sym{
        font-size: 12px;
        font-weight: bold;
    }
    @media (max-width: 1274px) {
        .lBtm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`

const SliderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .lft{
        flex-grow: 1;
    }
    .rht{
        flex-shrink: 0;
        width: 80px;
    }
`

const Tips2 = styled.div`
    color: #ff9500;
    font-size: 16px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 5px;
`

export default function Buy({ModalClose,selectedItem,getAssets}){
    const {t } = useTranslation();
    const {  notification } = App.useApp();
    const [show,setShow] = useState(true);
    const [inputValue, setInputValue] = useState(1);
    const [current,setCurrent] = useState(1)
    const type = useSelector(store => store.type)
    const [fee,setFee] = useState({});
    const serviceFee = useSelector(store => store.serviceFee)
    const [net,setNet] = useState("testnet");
    const [psbtObj,setPsbtObj] = useState(null);

    const account = useSelector(store => store.account);

    const [disableTx,setDisableTx] = useState(true)
    const [ disableFee,setDisableFee] = useState(true)
    const [ disablePsbt,setDisablePsbt] = useState(true)

    useEffect(()=>{

        setDisableTx(disableFee||disablePsbt)

    },[disableFee,disablePsbt])




    useEffect(()=>{
        getNetwork()
    },[])

    const getNetwork = async () =>{
        let rt = await getNet(type)
        setNet(rt)
    }



    const [list] = useState([
        {
            name:t("modal.Low"),
            value:0
        },
        {
            name:t("modal.Normal"),
            value:0
        },
        {
            name:t("modal.Custom"),
            value:0
        }
    ])


    useEffect(()=>{
        getRate()
        handlePBST()

        // FIXME: Here we need fix the problem of reset the custom fee rate [f]
        let timer = setInterval(()=>{
            getRate()
        }, 30 * 1000)

        return () =>{
            clearInterval(timer)
        }
    },[])


    const handlePBST = async() =>{
        store.dispatch(saveLoading(true))
        // setLoadingPSBT(true)
        try{

            let rt = await getPsbt(selectedItem?.order?.order_id)

            console.log("~~~~~~handlePBST",rt)

            const {psbt:psbtHex} = rt;

            //<<<<< check tapKeySig & partialSig in input[1]
            let network = await getNet(type)
            const networkType = network === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;
            const cfg = networkTypeToConfig(networkType);


            // check tapKeySig & partialSig in input[1]
            let checkSign =  bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })

            if(!checkSign.data.inputs[1].tapKeySig && !checkSign.data.inputs[1].partialSig){
                notification.error({
                    message:  t("modal.InvalidSig"),
                    description:t("modal.updateInfo")
                });
                // setLoading(false)
                return;
            }
            //>>>>>

            setPsbtObj(rt)
            setDisablePsbt(false)
        }catch (e) {
            setDisablePsbt(true)
            notification.error({
                message:t("error.orderInfo"),
                description:e?.response?.data?.message || e?.message || e?.reason
            });

        }finally {
            store.dispatch(saveLoading(false))
            // setLoadingPSBT(false)
        }
    }


    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {hourFee,halfHourFee,fastestFee} = rt;
            setDisableFee(false)
            let arr = [...list];
            // arr[0].value = hourFee;
            // arr[1].value = halfHourFee;
            // arr[2].value = fastestFee;
            arr[1].value = halfHourFee;
            arr[2].value = fastestFee;
            setInputValue(fastestFee)
            setFee(rt.data)
        }catch (e) {
            console.error("https://mempool.space/api/v1/fees/recommended",e)
            setDisableFee(true)
            notification.error({
                message: t("modal.feeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    const onChange = (newValue) => {

        setInputValue(newValue);
    };

    const handleValue = (e) =>{
        const {value} = e.target;
        setInputValue(value);
    }

    const handleClose =  () =>{
        setShow(!show)
    }
    const handleCurrent = (item) =>{
        setCurrent(item)
    }

    const buy = async () =>{
        console.log("psbtObj",psbtObj)


        store.dispatch(saveLoading(true));

        // FIXME: need handle vb_size here!
        let txFeeRate = (current<2?list[current].value:inputValue);
        let txFee = ((selectedItem?.vb_size?selectedItem?.vb_size:560) * txFeeRate);
        let sFee = selectedItem?.order?.notional * serviceFee?.taker_fee;

        try{

            let txObj = await handleBuyPsbt(psbtObj,selectedItem?.utxo_data, txFeeRate, serviceFee?.taker_fee);
            const {txHash,ckbVirtualTxResult} = txObj;


            let obj = {

                "price": selectedItem?.price,
                "amount":selectedItem?.order?.amount,
                "notional": selectedItem?.order?.notional,
                ckb_virtual_tx_result:ckbVirtualTxResult,
                "service_fee_rate": serviceFee?.taker_fee,
                "service_fee": Math.ceil(sFee),

                "vb_size": selectedItem?.vb_size,
                "transaction_fee_rate": txFeeRate,
                "transaction_fee": txFee,

                "total_payments":Number(selectedItem?.order?.notional) +  Math.ceil(sFee) + txFee,
                "currency": selectedItem?.order?.currency,

                "raw_tx": txHash
            }


            await assetsBuy(selectedItem?.order?.order_id,obj);
            notification.success({
                message: 'Success!',
            });
            // FIXME: why we need getAssets here??? [f]
            getAssets()

        }catch (e) {
            console.error("buy",e)

            notification.error({
                message: 'Error!',
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            ModalClose()
            store.dispatch(saveLoading(false))
        }


    }



    const formatFee = () =>{
        let vbSize = new BigNumber(selectedItem?.vb_size?selectedItem?.vb_size:560)
        let fR = new BigNumber(current<2?list[current].value:inputValue)

        return Math.ceil(vbSize * fR).toString()
    }

    const formatPrice = () =>{

        let vbSize = new BigNumber(selectedItem?.vb_size?selectedItem?.vb_size:560)
        let pr = new BigNumber(current<2?list[current].value:inputValue)
        let txFee = vbSize.times(pr);

        let notional = new BigNumber(selectedItem?.order?.notional)
        let takerFee  = new BigNumber(serviceFee?.taker_fee);
        let sFeeBefore  = notional.times(takerFee);
        let sFee = sFeeBefore.integerValue(BigNumber.ROUND_CEIL);

        let newPrice = notional.plus(sFee).plus(txFee).toString()
        return new BitcoinUnit(newPrice, 'sats').to('BTC').toString()
    }
    const mathServiceFee = () =>{
        let notional = new BigNumber(selectedItem?.order?.notional);
        let feeRate = new BigNumber(serviceFee?.taker_fee)
       return Math.ceil(notional.times(feeRate)).toString()
    }

    return <MaskBox>
        <ContentBox>

            <TitleBox>
                <div className="lft"> {t("modal.Confirmation")}</div>

                <CloseOutlined className="icon" onClick={()=>ModalClose()} />
            </TitleBox>
            <Tips>{t("modal.confirmTips")}:</Tips>

            <FirstLine bg={handleBg(selectedItem)}>
                {
                    selectedItem?.media_info?.content_type?.indexOf("image") > -1 && <div className="innerImg">
                        <img
                            // src={selectedItem?.media_info?.url}
                            src={formatImage(selectedItem)}
                            alt=""/>
                    </div>
                }
                {
                    selectedItem?.media_info?.content_type?.indexOf("text") > -1 && <div className="innerTxt">
                        {selectedItem?.media_info?.content_data}
                    </div>
                }
                <div className="rht">
                    <div className="title">{selectedItem?.display_name}</div>
                    <div className="id">#{FormatAddress(selectedItem?.dob_id)}</div>
                </div>
            </FirstLine>
            <DLBox>
                <dl>
                    <dt>{t("modal.TotalValue")}</dt>
                    <dd>
                        <span className="num">{selectedItem?.order?.notional}</span>
                        <span className="sym">{selectedItem?.currency}</span>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("modal.ServiceFee")} <span className="tag">{(serviceFee?.taker_fee * 100).toFixed(2)}%</span></dt>
                    <dd>
                        <span className="num">{mathServiceFee()}</span>
                        <span className="sym">{selectedItem?.currency}</span>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("modal.TransactionFee")}</dt>
                    <dd>
                        <span className="num">{formatFee()}</span>
                        <span className="sym">{selectedItem?.currency}</span>
                    </dd>
                </dl>

            </DLBox>
            <FeeLine>
                <div>
                    <span className="num">{selectedItem?.vb_size?selectedItem?.vb_size:560}</span>
                    <span className="sym">vB</span> * <span className="num rhtNum">{current<2?list[current].value:inputValue}</span>
                    <span className="sym">{selectedItem?.currency}/vB</span></div>
                <div className="custom" onClick={()=>handleClose()}>{t("modal.Custom")}
                    {
                        show &&<UpOutlined />
                    }
                    {
                        !show &&<DownOutlined />
                    }

                </div>

            </FeeLine>
            {
               (!disableTx && show) && <CustomLine>
                    <div className="fst">

                        {
                            list.map((item,index)=> (<div key={index} className={index !==0 ?(current === index ? "item active" : "item"):"none"}
                                                          onClick={() => handleCurrent(index)}>
                                <div className="title">{item.name}</div>
                                <div className="lBtm">
                                    <span className="num">{index === 2? inputValue :item.value}</span>
                                    <span className="sym">{selectedItem?.currency} / vB</span>
                                </div>
                            </div>))
                        }
                    </div>
                    {
                        current === 2 && <SliderBox>
                            <div className="lft">
                                <Slider
                                    min={list[1]?.value}
                                    max={list[2]?.value * 10}
                                    onChange={onChange}
                                    value={typeof inputValue === 'number' ? inputValue : 0}
                                />
                            </div>

                            <div className="rht">
                                <Input
                                    min={1}
                                    max={list[2]?.value * 10}
                                    value={inputValue}
                                    onChange={handleValue}
                                />
                            </div>
                        </SliderBox>
                    }


                </CustomLine>
            }

            <BtmBox>
                <div>{t("modal.YouPay")}</div>
                <div>
                    <span className="price">{formatPrice()}</span> <span className="sym">BTC</span></div>
            </BtmBox>

            <Tips2>{t("modal.warnings")}</Tips2>
            <ButtonGroup>


                <button className="cancel" onClick={()=>ModalClose()}>{t("modal.Cancel")}</button>
                <button className="confirm" disabled={!account || disableTx} onClick={()=>buy()}>
                    {t("modal.Confirm")}
                    {
                        disableTx &&  <LoadingBtn />
                    }

                </button>
            </ButtonGroup>
        </ContentBox>
    </MaskBox>
}
