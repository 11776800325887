import JoyidImg from "../../assets/images/Joyid.svg";

import store from "../../store";
import { saveLoading} from "../../store/reducer";

import {App} from "antd";
import {useTranslation} from "react-i18next";

import styled from "styled-components";

import useCCCSigner from "../../hook/useCCCSigner.jsx";

const RhtBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 12px;
    span{
        opacity: 0.7;
        color: #fff;
    }
    img{
        border: 0;
    }

`

export default function JoyidCCC({handleClose}){
    const {  notification } = App.useApp();
    const {t } = useTranslation();

    const {connectCCC} = useCCCSigner("joyidCCC");

    const onConnect = async() =>{
        store.dispatch(saveLoading(true));
        try {
            await connectCCC()
            handleClose()
        } catch (error) {
            console.error(error);

            notification.error({
                message:  t("error.Joyid"),
                description:error.message || error.reason
            });
            store.dispatch(saveLoading(false));
        }
    }

    return <dl>
        <dt>
            <img src={JoyidImg} alt=""/>
        </dt>
        <dd onClick={() => onConnect()}>
            <span>JoyID CKB</span>


                <RhtBox>
                    <span>CCC</span>
                </RhtBox>



        </dd>
    </dl>
}
