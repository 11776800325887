import {createSlice} from '@reduxjs/toolkit';
import InitState from './initState';

const mainSlice = createSlice({
    name: 'main',
    initialState: InitState,
    reducers: {
        saveAccount(state, action) {
            state.account = action.payload;
        },
        saveSecondary(state, action) {
            state.secondary = action.payload;
        },
        saveToken(state, action){
            state.token = action.payload;
        },
        saveLoading(state, action) {
            state.loading = action.payload;
        },
        saveType(state, action) {
            state.type = action.payload;
        },
        saveSignature(state, action){
            state.signature = action.payload;
        },
        savePrice(state, action){
            state.price = action.payload;
        },
        saveInfoCKB(state, action){
            state.infoCKb = action.payload;
        },
        saveServiceFee(state, action){
            state.serviceFee = action.payload;
        },
        saveTokenExp(state, action){
            state.tokenExp = action.payload;
        },
        saveJoyidInfo(state, action){
            state.joyidInfo = action.payload;
        },
        saveWalletList(state, action){
            state.walletList = action.payload;
        },
        saveHash(state, action){
            state.didHash = action.payload;
        },
        saveUtxo(state, action){
            state.utxo = action.payload;
        }
    },
});

export const {
    saveAccount,
    saveType,
    saveToken,
    saveSignature,
    saveTokenExp,
    saveJoyidInfo,
    saveSecondary,
    saveWalletList,
    savePrice,
    saveInfoCKB,
    saveServiceFee,
    saveUtxo,
    saveHash,
    saveLoading
} = mainSlice.actions;
export default mainSlice.reducer;
