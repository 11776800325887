import MetamaskImg from "../../assets/images/metamask.png";
import store from "../../store";
import {saveAccount, saveLoading, saveSignature, saveType, saveWalletList} from "../../store/reducer";

import {SIG_WORD} from "../../utils/constant.js";
import {App} from "antd";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import styled from "styled-components";
import useCCCSigner from "../../hook/useCCCSigner.jsx";

const RhtBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 12px;
    span{
        opacity: 0.7;
        color: #fff;
    }
    img{
        border: 0;
    }

`

export default function MetamaskCKB({handleClose}){
    const {  notification } = App.useApp();
    const {t } = useTranslation();

    const {connectCCC} = useCCCSigner("metamaskCKB");

    const onConnect = async() =>{
        store.dispatch(saveLoading(true));
        try {
            await connectCCC()
            handleClose()
        } catch (error) {
            console.error(error);

            notification.error({
                message:  t("error.metamaskCKB"),
                description:error.message || error.reason
            });
            store.dispatch(saveLoading(false));
        }
    }



    return <dl >
        <dt>
            <img src={MetamaskImg} alt=""/>
        </dt>
        <dd onClick={() => onConnect()}>
            <span>Metamask</span>

                <RhtBox>
                    <span>CCC</span>
                </RhtBox>



        </dd>
    </dl>
}
