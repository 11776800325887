import OkxImg from "../../assets/images/okx.png";
import {useEffect, useRef, useState} from "react";
import store from "../../store";
import {saveAccount, saveLoading, saveSignature, saveType, saveWalletList} from "../../store/reducer";
import useLogin from "../../hook/useLogin.jsx";
import {SIG_WORD} from "../../utils/constant.js";
import {App} from "antd";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
export default function OKX({handleClose}){
    const {  notification } = App.useApp();
    const [disable,setdisable] = useState(false);
    const {t } = useTranslation();

    const walletList = useSelector(store => store.walletList);
    const {okxwallet} = window;

    const selfRef = useRef ({
        accounts: [],
    });
    const self = selfRef.current;

    useLogin(handleClose,"okx");

    useEffect(() => {
        const checkOKX = async () => {
            if (!okxwallet) {
                setdisable(true)
                return;
            }
            setdisable(false)
        }
        checkOKX();
    }, []);


    const connect = async() =>{

        store.dispatch(saveLoading(true));


        try{
            if(disable){
                throw new Error(t("error.okxNOInstall"))
            }
            let result
            if(import.meta.env.VITE_CHAIN==="testnet") {
                result = await okxwallet?.bitcoinTestnet.connect();
            }else{
                result = await okxwallet?.bitcoin.connect();
            }

            self.accounts = result.address;
            if (result.address) {
                let signature

                if (import.meta.env.VITE_CHAIN === "testnet"){
                    signature = await okxwallet?.bitcoinTestnet.signMessage(SIG_WORD);
                }else{
                    signature = await okxwallet?.bitcoin.signMessage(SIG_WORD);
                }


                store.dispatch(saveSignature(signature));

                let btc = {
                    account : result.address,
                    type:"okx",
                    signature
                }

                store.dispatch(saveWalletList({
                    btc:btc,
                    ckb:walletList.ckb
                }));

                store.dispatch(saveAccount(result.address));
                store.dispatch(saveType('okx'));

                handleClose()
            }
        }catch (e) {
            console.error(e)

            notification.error({
                message:  t("error.OKX"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));
        }


    }


    return <dl onClick={()=>connect()}>
        <dt>
            <img src={OkxImg} alt=""/>
        </dt>
        <dd>OKX Wallet</dd>
    </dl>
}
