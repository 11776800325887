import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {FormatAddress, handleBg} from "../../utils/general.js";
import {useTranslation} from "react-i18next";
import DidImg from "../../assets/images/did.svg";
import Pending from "../pending.jsx";
import CkbImg from "../../assets/images/ckb.png";
import {formatUnit} from "@ckb-lumos/bi";

const Box = styled.div`
    width: 100%;
    border-radius: 10px;
    background: rgba(255,255,255,0.1);
    box-sizing: border-box;
    overflow: hidden;
    border: 2px solid rgba(255,255,255,0.01);
    position: relative;
    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        z-index: 8;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .btnBox{
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff9500;
        padding: 10px;
        border-radius: 5px;
        color: #ff9500;
        cursor: pointer;
        background: transparent;
        &:disabled{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
`

const BgBox = styled.div`
    .photo{
        display: flex !important;
        overflow: hidden;
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                //image-rendering: pixelated;
            }
        }
    }
    .chainImg{
        position: absolute;
        z-index: 9;
        width: 32px;
        height: 32px;
        left: 10px;
        top: 10px;
        border-radius: 100%;
        border: 1px solid #ddd;
        img{
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }
    }
    .innerTxt{
        background: rgba(255, 149, 0, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title{
            font-size: 30px;
            margin-bottom: 5px;
            font-family: din_bold;
        }
        .tips{
            opacity: 0.6;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:9;
            -webkit-box-orient: vertical;
            line-height: 1.5em;
        }
    }
`
const MetaBox = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .last{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btc{
        font-family: din_bold;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .price{
        opacity: 0.8;
        font-family:din_regular;
    }
`
const TitleBox = styled.div`
    padding-bottom: 10px;
    .id{
        font-size: 14px;
    }

    .desc{
        font-family: din_bold;
        padding-top: 5px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
`

const ItemImgBox = styled.div`
    position: relative;;
    line-height: 0;
    img{
        width: 100%;
    }
    .nameBox{
        position: absolute;
        color: #ffe9b7;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        top:53%;
        line-height: 1.5em;
      
        .title{
            font-size: 14px;
            font-family: din_bold;
        }
        .bit{
            font-size: 12px;
        }
    }
`

const FlexLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.6;
    margin-bottom: 5px;
    .name{
        width:70%;
        overflow:hidden; 
        text-align: right;
        text-overflow:ellipsis; 
        white-space:nowrap;
    }
`
const LastLine = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    button{
        flex:1;
    }
`

export default function Item_ckb({item,handleList,show,ModalClose,handleShow,handleSendShow}){
    const navigate = useNavigate();
    const {t } = useTranslation();
    const toDetail = () =>{
        navigate(`/my-detail_ckb/${item.dobsId}`)
    }

    return <Box  onClick={()=>toDetail()}>
        <BgBox bg={handleBg(item)}>

            {
                item?.status === 1 && <div className="listedTag">
                    {t("item.Listed")}
                </div>
            }

            {
                item?.status === 3 && <div className="listedTag">
                    <Pending /><span>{t("item.Pending")}</span>
                </div>
            }
            <div className="chainImg">
                <img src={CkbImg} alt=""/>
            </div>


            <div className="photo">
                <div className="aspect"/>
                <div className="content">
                    {
                        item.assetType === 0 && <div className="innerImg">
                            <img
                                src={item?.spore?.url || item?.spore?.imgUrl}
                                alt=""/>
                        </div>
                    }


                    {
                        item.assetType === 1 && <ItemImgBox>
                            <div className="nameBox">
                                <div className="title">
                                    {item?.spore?.DidName.split(".")[0]}
                                </div>
                                <div className="bit">
                                    .bit
                                </div>

                            </div>
                            <img src={DidImg} alt=""/>
                        </ItemImgBox>
                    }
                    {/*{*/}
                    {/*    item?.media_info?.content_type.indexOf("text") > -1 && <div className="innerTxt">*/}
                    {/*        /!*<div className="title">66n.x</div>*!/*/}
                    {/*        <div className="tips">{item?.media_info?.content_data}</div>*/}
                    {/*    </div>*/}
                    {/*}*/}

                </div>
            </div>
        </BgBox>
        <MetaBox>
            <TitleBox>
                <FlexLine>
                    <div>Cluster</div>
                    <div className="name">{item?.clusterMetaData?.name}</div>
                </FlexLine>
                <FlexLine>
                    <div>ID</div>
                    <div className="id">#{FormatAddress(item?.dobsId)}</div>
                </FlexLine>{
            }
                <FlexLine>
                    <div>Capacity</div>
                    <div className="id">{formatUnit(item?.occupied,"ckb")} CKB</div>
                </FlexLine>

                {/*<div className="desc">{item?.display_name}</div>*/}
            </TitleBox>
            <LastLine>
                <button className="btnBox" disabled={item?.status !== 0}
                        onClick={(e) => handleShow(e, item)}> {t("item.ListNow")}</button>

                <button className="btnBox" disabled={item?.status !== 0}
                        onClick={(e) => handleSendShow(e, item)}> {t("detail.Send")}</button>
            </LastLine>

        </MetaBox>
    </Box>
}
