import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import Unisat from "../wallet/unisat.jsx";
import OKX from "../wallet/okx.jsx";
import Joyid from "../wallet/joyid.jsx";
import JoyidSEG from "../wallet/joyidSEG.jsx";
import JoyidCKB from "../wallet/joyidCKB.jsx";
import Rei from "../wallet/Rei.jsx";
import {useTranslation} from "react-i18next";
import {MoveLeft} from "lucide-react";
import MetamaskCKB from "../wallet/metamaskCKB.jsx";
import OkxEvmCKB from "../wallet/okxEvmCKB.jsx";
import OkxBTCCKB from "../wallet/okxBTCCKB.jsx";
import UnisatCKB from "../wallet/unisatCKB.jsx";
import ReiCKB from "../wallet/ReiCKB.jsx";
import JoyidCCC from "../wallet/JoyidCCC.jsx";

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding:30px 20px ;
    width: 400px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    font-family: din_bold;
    font-size: 18px;

    .lftImg{
        cursor: pointer;
    }
    .lft{
        flex-grow: 1;
        text-align: center;

    }
    .icon{
        cursor: pointer;
    }
    
`

const DlBox = styled.div`
    margin-top: 20px;
    dl{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        background: #000;
        padding: 10px;
        border-radius: 10px;
        gap: 20px;
        cursor: pointer;
        box-sizing: border-box;
    }
    dt{
        width: 26%;
        text-align: right;
    }
    img{
        height: 30px;
        border: 1px solid rgba(255,255,255,0.3);
        border-radius: 100px;
    }
    dd{
        font-size: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
    }
    .pc{
        display: flex;
    }
    @media (max-width: 1274px) {
        .pc{
            display: none;
        }
    }
    
`

const TypeBox = styled.div`
    border-bottom: 1px solid rgba(255,255,255,0.3);
    position: relative;
    min-height: 30px;
    .ant-tabs-top >.ant-tabs-nav{
        margin-bottom: 0;
    }
  
`

export default function Wallet({handleClose,chain,leftClose}){
    const {t } = useTranslation();


    return <MaskBox>
        <ContentBox>
            <TitleBox>
                {
                    !!leftClose && <div onClick={() => leftClose()} className="lftImg"><MoveLeft size={28} /></div>
                }

                <div className="lft">{chain === "ckb"?t('general.connectCkB'):t('general.connectBTC')}</div>
                <CloseOutlined className="icon" onClick={() => handleClose()}/>
            </TitleBox>
            {/*<TypeBox>*/}
            {/*    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />*/}
            {/*</TypeBox>*/}

            {
                chain === "btc" &&  <DlBox>
                    <Unisat handleClose={handleClose}/>
                    <OKX handleClose={handleClose}/>
                    <Joyid handleClose={handleClose}/>
                    <JoyidSEG handleClose={handleClose}/>
                </DlBox>
            }

            {
                chain === "ckb" && <DlBox>
                    {/*<JoyidCKB handleClose={handleClose}/>*/}
                    {/*<Rei handleClose={handleClose}/>*/}
                    <ReiCKB handleClose={handleClose}/>
                    <UnisatCKB handleClose={handleClose}/>
                    <MetamaskCKB handleClose={handleClose}/>
                    <OkxEvmCKB handleClose={handleClose}/>
                    <JoyidCCC handleClose={handleClose}/>
                </DlBox>
            }
        </ContentBox>
    </MaskBox>
}
