
import { RPC} from "@ckb-lumos/lumos";
import {getNet} from "../utils/wallet.js";
import {CKB_networkList} from "../utils/constant.js";



export const getFeeRate = async() =>{
    const net = await getNet();
    const rpcUrl = CKB_networkList[net].node;
    const rpc = new RPC(rpcUrl)

    let result = await rpc.getFeeRateStatistics()

    let maxNum = Math.max(parseInt(result.median),1100).toString(16);
    result.median = `0x${maxNum}`;
    console.log("getFeeRate",result);
    return result;
}


