import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import {FormatAddress, formatImage, handleBg} from "../../utils/general.js";
import {useSelector} from "react-redux";
import {MoveLeft} from "lucide-react";
import { handlePublicKey, handleSignPsbt} from "../../utils/wallet.js";
import {TOKEN_CONFIG} from "../../utils/constant.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {App, Input, Slider} from "antd";
import { sendPsbt } from "@joyid/bitcoin";

import {RgbppHelper} from "dobs-dex-sdk";
import {sendToSendServer} from "../../api/assets.js";
import {bitcoin, NetworkType, networkTypeToConfig} from "@rgbpp-sdk/btc";
import {useEffect, useState} from "react";
import {getFeeRate} from "../../api/btc.js";

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .lft{
        flex-grow: 1;

    }
    .icon{
        cursor: pointer;
    }
    
`
const Tips = styled.div`
    font-size: 12px;
    opacity: 0.6;
    padding-top: 10px;
    margin-bottom: 10px;
`



const FirstLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .innerImg{
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        background: ${props => props.bg};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            //image-rendering: pixelated;
        }
    }
    .innerTxt{
        background: #000;
        width: 80px;
        height: 80px;
        font-size: 12px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap:break-word;
        -webkit-line-clamp:3;
        -webkit-box-orient: vertical;
    }
    .title{
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 400px;
    }
    .id{
        //color: #ff9500;
        font-family:din_regular;
    }
    .capacity{
        display: flex;
        gap: 5px;
    }
    @media (max-width: 1274px) {
        .title{
            width: 58vw;
            word-break: break-all;
        }
    }
`

const BtmBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 20px;
    .price{
        color: #ff9500;
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button{
        width: 49%;
        height: 40px;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        &:disabled{
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .confirm{
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
    }
    .cancel{
        border:1px solid #FF9500;
        color: #ff9500;
        background: none;
    }
`
const FeeLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .custom{
        border:1px solid #FF9500;
        color: #ff9500;
        padding:5px 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .num{
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
        padding-left: 5px;
    }
    .rhtNum{
        color: #ff9500;
    }
`

const CustomLine = styled.div`
    .fst{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        width: 49%;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover,&.active{
            cursor: pointer;
            border: 1px solid #ff9500;
        }
     
    }
    .none{
        display: none;
    }
    .title{
        font-size: 12px;
        opacity: 0.6;
    }
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-right: 10px;
    }
    .sym{
        font-size: 12px;
        font-weight: bold;
    }
    @media (max-width: 1274px) {
        .lBtm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`

const SliderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .lft{
        flex-grow: 1;
    }
    .rht{
        flex-shrink: 0;
        width: 80px;
    }
`

const Tips2 = styled.div`
    color: #ff9500;
    font-size: 16px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 5px;
`
const SendTo = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`


const BtmBoxBtm = styled.div`
    word-break: break-all;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 10px;
    color: #ff9500;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
 
`

export default function SendStep2_btc ({handleClose,selectedItem,handleStep,address}){
    const {t } = useTranslation();
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)
    const {  notification } = App.useApp();
    const [inputValue, setInputValue] = useState(1);
    const [current,setCurrent] = useState(1)
    const [fee,setFee] = useState({});

    const [list] = useState([
        {
            name:t("modal.Low"),
            value:0
        },
        {
            name:t("modal.Normal"),
            value:0
        },
        {
            name:t("modal.Custom"),
            value:0
        }
    ])




    const sendlogic = async(sporeArgs, toBtcAddress, fromBtcAddress, fromBtcPubkey) => {
        const isMainnet = import.meta.env.VITE_CHAIN === "livenet"

        const BTC_ASSETS_API_URL = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
        const BTC_ASSETS_ORGIN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
        const BTC_ASSETS_TOKEN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

        const rgbppHelper = new RgbppHelper(isMainnet, BTC_ASSETS_API_URL, BTC_ASSETS_TOKEN, BTC_ASSETS_ORGIN)


        const {psbt, ckbVirtualTxResult} = await rgbppHelper.buildTransferPsbt(sporeArgs, toBtcAddress, fromBtcAddress, fromBtcPubkey, inputValue) // feeRate



        const signInputList = [];

        for (let i = 0; i < psbt.txInputs.length; i++) {
            if (!psbt.data.inputs[i].tapKeySig && !psbt.data.inputs[i].partialSig) {
                signInputList.push({
                    index: i,
                    address: account,
                    publicKey: fromBtcPubkey,
                });
            }
        }

        console.log(psbt)

        let psbtSigned;

        psbtSigned = await handleSignPsbt(
            psbt.toHex(),
            type,
            {
                autoFinalized: false,
                toSignInputs: signInputList,
            }
        );

        console.log(psbt.toHex())



        const networkType = import.meta.env.VITE_CHAIN  === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;

        const cfg = networkTypeToConfig(networkType);

        console.log(cfg)

        //
        const newPsbt = bitcoin.Psbt.fromHex(psbtSigned, { network: cfg.network });
        // const rawTx = bitcoin.Transaction.fromHex(psbt.toHex(), { network: cfg.network });
        //
        newPsbt.finalizeAllInputs();
        const btcTx = newPsbt.extractTransaction();
        //
        console.log("btcTx",btcTx.toHex())




        const rt = await sendToSendServer(selectedItem?.asset_id,btcTx.toHex(),ckbVirtualTxResult)


        window.location.reload()

        // const {btcTxHash} = rt;
        // if(type !== "joyid"){
        //
        //     psbtSigned = await handleSignPsbt(
        //         psbt.toHex(),
        //         type,
        //         {
        //             autoFinalized: true,
        //             toSignInputs: signInputList,
        //         }
        //     );
        // }else{
        //     psbtSigned = ""
        // }


        // const btcTxHash = await pushPsbt(psbtSigned,type,psbt.toHex(),{
        //     autoFinalized: true,
        //     toSignInputs: signInputList,
        // });
        //
        // return await rgbppHelper.btcService.sendRgbppCkbTransaction({ btc_txid: btcTxHash, ckb_virtual_result: ckbVirtualTxResult });
    }


    const pushPsbt = async (psbtHex, walletType,psbtUnsigned,options) => {

        const network  = import.meta.env.VITE_CHAIN
        if (walletType === "unisat") {
            const unisat = window["unisat"];
            if (typeof unisat !== "undefined") {
                console.log("UniSat Wallet is installed!");

                const curNetwork = await unisat.getNetwork();
                if (curNetwork !== network) {
                    await unisat.switchNetwork(network);
                }
                return await unisat.pushPsbt(psbtHex);
            } else {
                throw new Error("UniSat Wallet is no installed!");
            }
        } else if (walletType === "okx") {
            const okxwallet = window["okxwallet"];
            if (typeof okxwallet !== "undefined") {
                console.log("OKX is installed!");

                // {address publicKey}
                if (import.meta.env.VITE_CHAIN !== "testnet") {
                    const result = await okxwallet?.bitcoin.pushPsbt(psbtHex);
                    return result;
                } else {
                    throw new Error("OKX Wallet is no testnet push psbt");
                }
            } else {
                throw new Error("OKX Wallet is no installed!");
            }
        } else if (walletType.indexOf("joyid")>-1 ) {
            const result = await sendPsbt(psbtUnsigned,options);
            return result;
        }

        throw new Error("Please connect btc wallet");
    }

    const transfer = async() =>{
        store.dispatch(saveLoading(true))




        try{

            const isMobile =/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


            let publicKey = await handlePublicKey(type,isMobile)





            let result = await sendlogic(`0x${selectedItem.dob_id}`, address, account, publicKey)

            console.log("result",result)

            notification.success({
                message: t("modal.SendSuccess"),
                // description: result
            });

            setTimeout(()=>{
                handleClose()
            },1000)

        }catch(e){
            console.error(e)

            notification.error({
                message: t("modal.SendFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false))
        }

    }

    const handleValue = (e) =>{
        const {value} = e.target;
        setInputValue(value);
    }


    useEffect(()=>{
        getRate()
        // handlePBST()

        // FIXME: Here we need fix the problem of reset the custom fee rate [f]
        let timer = setInterval(()=>{
            getRate()
        }, 30 * 1000)

        return () =>{
            clearInterval(timer)
        }
    },[])

    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {hourFee,halfHourFee,fastestFee} = rt;
            let arr = [...list];
            // arr[0].value = hourFee;
            // arr[1].value = halfHourFee;
            // arr[2].value = fastestFee;
            arr[1].value = halfHourFee;
            arr[2].value = fastestFee;
            setInputValue(fastestFee)
            setFee(rt.data)
        }catch (e) {
            console.error("https://mempool.space/api/v1/fees/recommended",e)
            notification.error({
                message: t("modal.feeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    const onChange = (newValue) => {

        setInputValue(newValue);
    };



    const handleCurrent = (item) =>{
        setCurrent(item)
    }

    return <div>
        <TitleBox>

            <div onClick={() => handleStep(1)} className="lftImg"><MoveLeft size={24}/></div>
            <CloseOutlined className="icon" onClick={() => handleClose()}/>
        </TitleBox>
        <div className="lft"> {t("detail.Send")} {t("modal.Confirmation")}</div>
        <Tips>{t("modal.confirmTips")}:</Tips>

        <FirstLine bg={handleBg(selectedItem)}>
            {
                selectedItem?.media_info?.content_type?.indexOf("image") > -1 && <div className="innerImg">
                    <img
                        src={formatImage(selectedItem)}
                        alt=""/>
                </div>
            }
            {
                selectedItem?.media_info?.content_type?.indexOf("text") > -1 && <div className="innerTxt">
                    {selectedItem?.media_info?.content_data}
                </div>
            }
            <div className="rht">
                <div className="title">{selectedItem?.display_name}</div>
                <div className="id">#{FormatAddress(selectedItem?.dob_id)}</div>
            </div>
        </FirstLine>

        <SendTo>
            <div>{t("modal.SendTo")}</div>
            <BtmBoxBtm>
                {address}
            </BtmBoxBtm>
        </SendTo>

        {
              <CustomLine>
                <div className="fst">

                    {
                        list.map((item,index)=> (<div key={index} className={index !==0 ?(current === index ? "item active" : "item"):"none"}
                                                      onClick={() => handleCurrent(index)}>
                            <div className="title">{item.name}</div>
                            <div className="lBtm">
                                <span className="num">{index === 2? inputValue :item.value}</span>
                                <span className="sym">{selectedItem?.currency?.length?selectedItem?.currency: "sats"} / vB</span>
                            </div>
                        </div>))
                    }
                </div>
                {
                    current === 2 && <SliderBox>
                        <div className="lft">
                            <Slider
                                min={list[1]?.value}
                                max={list[2]?.value * 10}
                                onChange={onChange}
                                value={typeof inputValue === 'number' ? inputValue : 0}
                            />
                        </div>

                        <div className="rht">
                            <Input
                                min={1}
                                max={list[2]?.value * 10}
                                value={inputValue}
                                onChange={handleValue}
                            />
                        </div>
                    </SliderBox>
                }


            </CustomLine>
        }
        <ButtonGroup>
            <button className="cancel" onClick={() => handleClose()}>{t("modal.Cancel")}</button>
            <button className="confirm" disabled={!account} onClick={() => transfer()}>
                {t("modal.Confirm")}
                {/*{*/}
                {/*    disableTx &&  <LoadingBtn />*/}
                {/*}*/}

            </button>
        </ButtonGroup>
    </div>
}
