import {BitcoinUnit} from "bitcoin-units";
import BigNumber from "bignumber.js";
import {formatUnit} from "@ckb-lumos/bi";
import store from "../store/index.js";
import {UTXO_PEROID} from "./constant.js";
import {saveUtxo} from "../store/reducer.js";
import {Buffer} from "buffer";
import {bufferToRawString} from "@spore-sdk/core";

export const FormatAddress = (addr,num=6) => {
    if(!addr)return;
    const address  = addr?.trim().toString();
    const frontStr = address.substring(0, num);
    const afterStr = address.substring(address.length - num, address.length);

    return `${frontStr}...${afterStr}`;
};

export const  addCommasToNumber =(number) =>{
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") ?? 0;

}

export const formatUSD = (price,itemPrice) =>{
    let pr =   new BitcoinUnit(itemPrice ?? 0, 'sats').to('BTC').getValue()
    let prBN = new BigNumber(pr)
    return price>0?prBN.times(price).toFixed(2):"--";
}

export const handleBg = (item) =>{

    if(!item || (!item?.metadata && !item?.asset_metadata)  || item.currency === "CKB") return "";
    let rawData = JSON.parse(item?.metadata?.raw || item?.asset_metadata?.raw)
    let traitsBox = JSON.parse(rawData?.render_output);

    let bg = traitsBox.filter((item)=>item.name ==="prev.bgcolor")
    if(!bg.length) return "";
    return bg[0]?.traits[0]?.String ?? ""


}

export const handleBgCKB = (rt) =>{


    if(!rt || !rt?.metaData )return "";
    let metadata = toJSONStr(rt?.metaData)
    let rawData = metadata?toJSONStr(metadata.raw):""

    let traitsBox = rawData?.render_output?toJSONStr(rawData?.render_output) : [];
    const arr = traitsBox.find((item)=>item.name === ("prev.bgcolor")) ;

    return arr?.traits[0]?.String ?? ""


}

export const  checkFileExtension = (url) => {
    let type
    var fileExtension = url.split('.').pop().toLowerCase();

    if (fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png" || fileExtension === "gif") {
        type = "image"
    } else if (fileExtension === "mp4" || fileExtension === "mov" || fileExtension === "avi" || fileExtension === "mkv") {
        type = "video"
    } else {
        type = "other"
    }
    return type
}

export const formatUSD_ckb = (price,itemPrice) =>{

    if(!price)return "--";
    let prBI = formatUnit(itemPrice,"ckb");
    let usdtPr = price ? new BigNumber(price) : 0;
    return usdtPr.times(prBI).toString()



}

export const isBTC = (type)=>{
    return type === "unisat" || type === "okx" || type === "joyid";
}

export const isCCC = (type) =>{
    return type === "unisatCKB" || type === "metamaskCKB" || type === "OkxBTCCKB"|| type === "okxEvmCKB"|| type === "reiCKB"|| type === "joyidCCC";
}


export const  toJSONStr = (str,keyName) => {
    if (typeof str !== 'string') {

        return  keyName?str[keyName]:str;
    }

    try {
        let rt = JSON.parse(str);
        if(typeof rt !== "string"){
            return keyName?rt[keyName]:rt
        }else{
            return rt;
        }

    } catch (e) {
        return str;
    }
}

export const getUtxoStore = () =>{
    const state = store.getState();
    const {utxo} = state;
    const date = new Date().valueOf();
    return  utxo.filter((utx)=>utx.timeStamp  + UTXO_PEROID > date );
}

export const setUtxo = (txSkeleton) => {
    const inputsArr = txSkeleton.get("inputs").toArray();
    const utxoList = getUtxoStore();
    for (let i = 0; i < inputsArr.length; i++) {
        utxoList.push({
            timeStamp:new Date().valueOf(),
            index:inputsArr[i].outPoint.index,
            txHash:inputsArr[i].outPoint.txHash
        })
    }
    store.dispatch(saveUtxo(utxoList))

}

const svgToBase64 = (svgCode) => {
    if (typeof window !== "undefined") {
        return `data:image/svg+xml;base64,${window.btoa(svgCode)}`;
    }
    return `data:image/svg+xml;base64,${Buffer.from(svgCode).toString("base64")}`;
}


export const formatImage = (item) =>{

    if(item?.media_info?.url.endsWith('png') && item.media_info.content_type.endsWith('png') ) {
        return item?.media_info?.url
    }else{
        if(!item?.metadata?.raw && !item?.asset_metadata?.raw )return "";

        const raw =item?.metadata?.raw || item?.asset_metadata?.raw

        const Obj = JSON.parse(raw);
        const newObj = JSON.parse(Obj?.render_output)
        const svgArr = newObj.find((ob)=>ob.name === "IMAGE");
        const svgStr = svgArr.traits[0].SVG;
        return  svgToBase64(svgStr);
    }

}
