import styled from "styled-components";
import Info from "../components/collection_ckb/info.jsx";
import {Select, Tabs} from 'antd';
import All from "../components/collection_ckb/all.jsx";
import {useEffect, useState} from "react";
import TableList from "../components/collection_ckb/tableList.jsx";
import {useNavigate} from "react-router-dom";
import {ArrowRightOutlined, RightOutlined, UnorderedListOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import MyOrderList from "../components/collection_ckb/myOrderList.jsx";
import Listed from "../components/collection_ckb/listed.jsx";
import {useTranslation} from "react-i18next";
import {isBTC} from "../utils/general.js";

const Box = styled.div`
    display: flex;
flex-direction: column;
    margin-top: 50px;
`

const FirstLine = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 249, 242,0.2);
    margin-top: 20px;
    .ant-tabs-top >.ant-tabs-nav{
        margin-bottom: 0;
    }
    @media (max-width: 1274px) {
        margin-top: 0;
        box-sizing: border-box;
        padding: 0 10px;
    }
`

const ButtonBox = styled.div`
    background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
    padding:10px 20px;
    border-radius: 5px;
    color: #0D0C0B;
    display: flex;
    align-items: center;
    gap: 10px;
    
`

const FlexRht = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 10px;

   .ant-select .ant-select-arrow{
       color: rgba(255,255,255,0.3);
   }
    @media (max-width: 1274px) {
        display: none;
    }
`
const TabBox = styled.div`
    flex-grow: 1;
`

const FlexMobile = styled.div`
    display: none;
    color: #ff9500;
    padding: 5px 5px 10px 20px;
    font-size: 20px;
    cursor: pointer;
    font-weight: bold;
    @media (max-width: 1274px) {
        display: block;
        
    }
`

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 99;
`

const DropBox = styled.div`
    box-sizing: border-box;
    background: #090808;
    width: 60vw;
    border: 1px solid #ff950050;
    padding:10px 10px;
    border-radius: 10px;

    
    .li{
        border-bottom: 1px solid rgba(255,255,255,0.1);
        padding: 10px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon{
            color: #ff9500;
        }
        span{
            margin-left: 10px;
        }
        &:last-child{
            border-bottom: 0;
        }
    }
    .lft{
        padding-left: 10px;
    }
    .selectBox{
        width: 100%;
    }
    .ant-select{

        &:focus-visible{
            outline: none;
        }
    }
    .rhtIcon{
        margin-right: 10px;
        color: #ff9500;
    }
    .ant-select-selection-item{
        font-size: 14px;
    }
    .ant-select-selector{
        border: 0!important;
        background: none!important;
   
    }
    .ant-select-arrow{
        color: #ff9500;
    }
`

export default function Collection_ckb(){
    const {t } = useTranslation();
    const [current,setCurrent] = useState(1)
    const navigate = useNavigate()
    const account = useSelector(store => store.account);
    const [selectType,setSelectType] = useState('')
    const [ orderList,setOrderList] = useState("")
    const type = useSelector(store => store.type)
    const [ show,setShow]  = useState(false);

    const items = [
        {
            key: '1',
            label: t("collection.All"),
        },
        {
            key: '2',
            label: t("collection.Listed"),
        },
        {
            key: '3',
            label: t("collection.Activities"),
        },
        // {
        //     key: '4',
        //     label: t("collection.MyOrders"),
        //     disabled: !account|| isBTC(type)
        // },
    ];

    const onChange = (key) => {
        setCurrent(Number(key))
    };

    const toAssets = (tab) =>{
        navigate(`/myAssets_ckb?tab=${tab}`)
    }

    const handleChange = (value) => {
        setSelectType(value)
    };


    useEffect(() => {
        document.addEventListener("click", (e) =>{
            setShow(false)

        });
    }, [])

    const handleOpen = (e) =>{
        e.nativeEvent.stopImmediatePropagation()
        setShow(true)
    }


    const handleEvent = (e) =>{
        e.nativeEvent.stopImmediatePropagation()
    }

    useEffect(() => {
        handleOrder()
    }, [selectType]);

    const handleOrder= () => {
        console.log("selectType",selectType)
        // 0 Unlist，1List，2Success
        switch (selectType){
            case "listed":
                setOrderList("status_1")
                break;

            case "sold":
                setOrderList("status_2")
                break;

            case "unlisted":
                setOrderList("status_0")
                break;


        }
    };



    return <Box>
        <Info/>
        <FirstLine>
            <TabBox>
                <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
            </TabBox>
            {
                show &&  <MaskBox >
                    <DropBox onClick={(e) => handleEvent(e)}>
                        <div className="li" onClick={() => toAssets("2")}>
                            <span className="lft">{t('collection.MyOrders')}</span>
                            <div className="rhtIcon">
                                <RightOutlined/>
                            </div>

                        </div>
                        <div className="li" onClick={() => toAssets("1")}>
                            <span className="lft">{t('general.MyAssets')}</span>
                            <div className="rhtIcon">
                                <RightOutlined/>
                            </div>

                        </div>

                        {
                            current > 2 && <div className="li"><Select
                                defaultValue=""
                                size="large"
                                className="selectBox"
                                onChange={handleChange}
                                dropdownStyle={{background: "#141414"}}
                                options={[
                                    {value: '', label: t("collection.AllEvents")},
                                    {value: 'listed', label: t("collection.Listed")},
                                    {value: 'sold', label: t("collection.Sold")},
                                    {value: 'unlisted', label: t("collection.Unlisted")},
                                ]}
                            /></div>
                        }


                    </DropBox>
                </MaskBox>
            }


            <FlexMobile onClick={(e) => handleOpen(e)}>
                <UnorderedListOutlined/>
            </FlexMobile>

            <FlexRht>
                {
                    current > 2 && <Select
                        defaultValue=""
                        size="large"
                        style={{width: "150px"}}
                        onChange={handleChange}
                        dropdownStyle={{background: "#141414"}}
                        options={[
                            {value: '', label: t("collection.AllEvents")},
                            {value: 'listed', label: t("collection.Listed")},
                            {value: 'sold', label:t("collection.Sold")},
                            {value: 'unlisted', label:t("collection.Unlisted")},
                        ]}
                    />
                }

                {
                    !!account && !isBTC(type) && <>
                        <ButtonBox onClick={() => toAssets("2")}>
                            <span className="text">{t('collection.MyOrders')}</span>
                            <ArrowRightOutlined/>
                        </ButtonBox>

                    </>
                }

                {
                    !!account && !isBTC(type) && <>
                        <ButtonBox onClick={() => toAssets("1")}>
                            <span className="text">{t('general.MyAssets')}</span>
                            <ArrowRightOutlined/>
                        </ButtonBox>

                    </>
                }
            </FlexRht>


        </FirstLine>

        {
            current === 1 && <All/>
        }
        {
            current === 2 && <Listed/>
        }
        {
            current === 3 && <TableList searchList={orderList}/>
        }
        {/*{*/}
        {/*    current === 4 && <MyOrderList searchList={orderList}/>*/}
        {/*}*/}
    </Box>;
}
