import styled from "styled-components";
import BtcImg from "../../assets/images/btc.svg";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Buy from "../modal/buy.jsx";
import {FormatAddress, formatImage, formatUSD, handleBg, isBTC} from "../../utils/general";
import {BitcoinUnit} from "bitcoin-units";
import {useSelector} from "react-redux";
import Pending from "../pending.jsx";
import {useTranslation} from "react-i18next";
import Wallet from "../modal/wallet.jsx";
import SwitchWallet from "../modal/switchWallet.jsx";

const Box = styled.div`
    width: 100%;
    border-radius: 10px;
    background: rgba(255,255,255,0.1);
    box-sizing: border-box;
    overflow: hidden;
    border: 2px solid rgba(255,255,255,0.01);
    &:hover,&.active{
        border: 2px solid #ff9500;
        cursor: pointer;

        .btnBox{
            background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
            color: #0D0C0B;
        }
    }
    
    .btnBox{
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff9500;
        padding: 10px;
        border-radius: 5px;
        color: #ff9500;
        cursor: pointer;
        background: none;
        &:disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
`

const BgBox = styled.div`
    position: relative;
    .photo{
        display: flex !important;
        overflow: hidden;
        background: ${props => props.bg};
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                //image-rendering: pixelated;
            }
        }
    }
    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .innerTxt{
        background: rgba(255, 149, 0, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
        .title{
            font-size: 30px;
            margin-bottom: 5px;
            font-family: din_bold;
        }
        .tips{
            opacity: 0.6;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:9;
            -webkit-box-orient: vertical;
            line-height: 1.5em;
        }
    }
`
const MetaBox = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .last{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:10px;
    }
    .btc{
        font-family: din_bold;
        display: flex;
        align-items: center;
        width: 55%;
        gap: 5px;
        flex-grow: 1;

        span{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }

      
    }
    .price{
        opacity: 0.6;
        flex-shrink: 0;
        font-family:din_regular;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 44%;
        white-space: nowrap;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        
    }
    @media (max-width: 1274px) {
        padding: 0 10px;
        .last{
            flex-direction: column;
            align-items: flex-start;
            .btc{
                width: 100%;
                span{
                    overflow: visible;
                }
            }
            .price{
                width: 100%;
                text-align: left;
            }
        }
    }
`
const TitleBox = styled.div`
    padding-bottom: 10px;
    .id{
        opacity: 0.6;
        font-size: 14px;
    }

    .desc{
        font-family: din_bold;
        padding-top: 5px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`

export default function Item({item,getAssets}){
    const {t } = useTranslation();
    const navigate = useNavigate();
    const account = useSelector(store => store.account);

    const [show,setShow] = useState(false);
    const [selectedItem,setSelectedItem] = useState(null);
    const price = useSelector(store => store.price)
    const type = useSelector(store => store.type)
    const [showChain,setShowChain] = useState(false);
    const walletList = useSelector(store => store.walletList);
    const [showSwitch,setShowSwitch] = useState(false);

    const toDetail = () =>{
        navigate(`/item-detail/${item.asset_id}`)
    }

    const handleShow = (e) =>{
        e.stopPropagation();
        setShow(true)
        setSelectedItem(item)
    }

    const ModalClose = () =>{
        setShow(false)
    }



    const handleChain = (e) =>{
        e.stopPropagation();
        if(walletList.btc){
            setShowSwitch(true)
        }else{
            setShowChain(true)
        }

    }

    const handleChainClose = () =>{
        setShowChain(false)
        setShowSwitch(false)
    }


    return <>
        {
            show && <Buy ModalClose={ModalClose} selectedItem={selectedItem} getAssets={getAssets} />
        }

        {
            showChain && <Wallet handleClose={handleChainClose} chain="btc"/>
        }

        {
            showSwitch &&   <SwitchWallet handleClose={handleChainClose} handleNext={handleChain} switchTochain="btc" />
        }
        <Box onClick={()=>toDetail()}>
            <BgBox bg={handleBg(item)}>
                {
                    item.owner_address === account && !item.is_pending && <div className="listedTag">
                         {t("item.My")}
                    </div>
                }
                {
                    item.owner_address === "1BitcoinEaterAddressDontSendf59kuE" &&<div className="listedTag">
                        <span>{t("item.Burned")}</span>
                    </div>
                }
                {
                    item.is_pending && <div className="listedTag">
                        <Pending/><span>{t("item.Pending")}</span>
                    </div>
                }

                <div className="photo">
                <div className="aspect"/>
                    <div className="content">
                        {
                            item?.media_info?.content_type?.indexOf("image") > -1 && <div className="innerImg">
                                <img
                                    // src={item?.media_info?.url}
                                    src={formatImage(item)}
                                    alt=""/>
                            </div>
                        }
                        {
                            item?.media_info?.content_type?.indexOf("text") > -1 && <div className="innerTxt">
                                {/*<div className="title">{item.display_name}</div>*/}
                                <div className="tips">{item?.media_info?.content_data}</div>
                            </div>
                        }

                    </div>
                </div>
            </BgBox>
            <MetaBox>
                <TitleBox>
                    <div className="id">#{FormatAddress(item?.dob_id)}</div>
                <div className="desc">{item?.display_name}</div>

            </TitleBox>

            <div className="last">
                <div className="btc">
                    <img src={BtcImg} alt=""/>
                    <span>{ item?.order ? new BitcoinUnit(item?.price ?? 0, 'sats').to('BTC').toString() :"--"}</span>
                   </div>

                <div className="price">$ {item?.order? formatUSD(price,item?.price) : "--"}</div>
            </div>

                {
                    !account && <button className="btnBox"   onClick={(e) => handleChain(e)} >{t('collection.buyNow')}</button>
                }
                {
                    account&& !!item?.order && isBTC(type) && <button className="btnBox"
                                       disabled={ !item?.order || !isBTC(type) || item.owner_address === account || item.is_pending || !isBTC(type)}
                                       onClick={(e) => handleShow(e)}>{t('collection.buyNow')}</button>
                }
                {
                    account&& !!item?.order  && !isBTC(type) && <button className="btnBox"
                                                      onClick={(e) => handleChain(e)}>{t('collection.buyNow')}</button>
                }

                {
                    account && !item?.order  && <button className="btnBox" disabled>{t('collection.MakeOffer')}</button>
                }

            </MetaBox>
        </Box>
    </>

}
