import styled from "styled-components";

import {Pagination, App} from 'antd';

import {useEffect, useState} from "react";

import Loading from "../loading.jsx";


import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import Item_ckb from "./item_ckb.jsx";
import List_ckb from "../modal/list_ckb.jsx";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import { myOrderListCKB_assets} from "../../api/assets_ckb.js";
import { build_list_dob} from "../../utils/ckbTransaction.js";
import {getNet} from "../../utils/wallet.js";
import {CKB_networkList, DID_CONTRACT, MINiPrice} from "../../utils/constant.js";
import {getFeeRate} from "../../api/ckb.js";
import {BI, parseUnit} from "@ckb-lumos/bi";
import {CellCollector, helpers, Indexer} from "@ckb-lumos/lumos";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {sendRawTx} from "../../utils/ckbUtils.js";
import {setUtxo, toJSONStr} from "../../utils/general.js";
import {Buffer} from "buffer";
import SendModal from "../modal/Send_ckb.jsx";
import useCCCSigner from "../../hook/useCCCSigner.jsx";


const Box = styled.div`
    margin-top: 10px;
    min-height: 400px;
    .ant-select .ant-select-arrow{
        color: rgba(255,255,255,0.3);
    }
`

const PageBox = styled.div`
    margin:40px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const FilterBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
const IconBox = styled.div`
    border: 1px solid #FFFAF2;
    height: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 0.5;
    padding: 0 10px;
    gap: 10px;
    background: #141414;
    span{
        font-size: 14px;
    }
    &.active{
        color: #ff9500;
        opacity: 1;
        border: 1px solid #ff9500;
    }
`

const RhtBox = styled.div`
    display: flex;
    justify-content: flex-end;
    gap:10px;
`

const UlBox = styled.ul`
    width: 100%;
    margin-top: 20px;
    &:after{content:"";display:block;clear:both;}
    li{
        width: calc((100% - 80px)/5);
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5n){
            margin-right: 0;
        }
    }

    @media (max-width: 1274px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        box-sizing: border-box;
        li{
            width: 48%;
            margin-right: 0;

        }

    }
`

export default function Collections_ckb(){
    const {t } = useTranslation();
    const [page,setPage] = useState(1)
    const [total,setTotal] = useState(0)
    const [pageSize,setPageSize] = useState(20)
    const [loading,setLoading] = useState(false);
    const [selectItem,setSelectItem] = useState(false);
    const {  notification } = App.useApp();
    const type = useSelector(store => store.type);
    const account = useSelector(store => store.account)
    const {signer} = useCCCSigner(type);
    const [fee, setFee] = useState(null);

    const [show,setShow] = useState(false);

    const [list,setList] = useState([])

    const [showSend,setShowSend] = useState(false);

    useEffect(() => {
        if(!account)return;
        getList()
    }, [account]);

    const getList = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await myOrderListCKB_assets(page,pageSize,account)
            const {current,data,total} = rt

            data.map((item)=>{
                let obj = toJSONStr(item?.metaData)
                let rawData = toJSONStr(obj.raw)

                const clusterInfo = toJSONStr(item.clusterMetaData)

                item.clusterMetaData=clusterInfo;
                const sporeObj = toJSONStr(item?.sporeData)

                let imgUrl;
                let txt;

                if(sporeObj?.contentType?.indexOf("image")>-1){
                    const buffer = Buffer.from(sporeObj?.content.toString().slice(2), 'hex');
                    const base64 = buffer.toString("base64");
                    imgUrl =  `data:${sporeObj?.contentType};base64,${base64}`;
                }else if(sporeObj?.contentType?.indexOf("text")>-1){
                    const buffer = Buffer.from(sporeObj?.content.toString().slice(2), 'hex');
                    txt =  buffer.toString()
                }


                let traitsBox = rawData?.render_output?toJSONStr(rawData?.render_output) : [];


                let arr = []
                let arrPrev = ""

                traitsBox.map((item)=>{
                    if(item?.name === "Account"){
                        arrPrev = item?.traits[0].String;
                    }else{
                        arr.push(item)
                    }

                })


                item.spore = {
                    ...obj,
                    imgUrl,
                    txt,
                    traitsBox:arr,
                    DidName:arrPrev,
                    raw: !!obj.raw && toJSONStr(obj.raw)
                }



            })
            setTotal(total)
            setPage(current)
            setList(data)

        }catch (e) {
            console.error("collectionsList",e)

            notification.error({
                message: t("error.MyOrders"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }

    }

    useEffect(()=>{
        getRate()

        let timer = setInterval(()=>{
            getRate()
        }, 30 * 1000)

        return () =>{
            clearInterval(timer)
        }
    },[])


    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {median} = rt;

            let feeRate = BI.from(median).toString()
            // setDisableFee(false)
            setFee(feeRate)
        }catch (e) {
            console.error(e)
            // setDisableFee(true)
            notification.error({
                message: t("modal.feeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }



    const handleList = async(inputValue,item) =>{
        const priceFormat = parseUnit(inputValue,"ckb");
        const minPriceFormat = parseUnit(MINiPrice,"ckb");
        if(priceFormat.lt(minPriceFormat))return;

        store.dispatch(saveLoading(true));
        try{

            const net = await getNet()

            const rpcUrl = CKB_networkList[net].node
            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);


            const sporeConfig = net==="testnet"? predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

            const version = net === 'testnet'?"preview":"latest";
            const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);


            let selectItemObj;

            if(item.assetType === 0){
                const {script:{codeHash,hashType}} = sporeTypeScript;

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:codeHash,
                        hashType:hashType,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }else if(item.assetType === 1){


                const {CODE_HASH,HASH_TYPE} = DID_CONTRACT[net]

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:CODE_HASH,
                        hashType:HASH_TYPE,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }


            let txSkeleton = await build_list_dob(fee,selectItemObj,account,inputValue,type,item.assetType)
            console.log("txSkeleton",txSkeleton)


            let result = await sendRawTx(txSkeleton,type,account,false,getList,signer)
            setUtxo(txSkeleton);

            notification.success({
                message: t("modal.listSuccess"),
                description: result
            });
            ModalClose()

        }catch (e) {
            console.error(e)
            notification.error({
                message: t("modal.listFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));

        }

    }
    const handleShow = (e,item) =>{
        e.stopPropagation();
        setShow(true)
        setSelectItem(item)
    }

    const ModalClose = () =>{
        setShow(false)
    }

    const handlePage = (e) =>{
        setPage(e)
        getList(e)
    }

    const handleCloseSend = () =>{
        setShowSend(false)
    }

    const handleSendShow = (e,item) =>{
        e.stopPropagation();
        setShowSend(true)
        setSelectItem(item)
    }

    return <Box>

        {
            show && <List_ckb ModalClose={ModalClose} item={selectItem} handleList={handleList}/>
        }
        {
            showSend && <SendModal selectItem={selectItem} handleClose={handleCloseSend} refresh={getList} />
        }


        {/*<div>*/}
        {/*    <button onClick={() => postUtxo()}>Post</button>*/}
        {/*</div>*/}
        {
            loading &&  <Loading />
        }
        <UlBox>
            {
                list.map((item, index) => (<li key={`collection_${index}`}>
                    <Item_ckb item={item} handleList={handleList} handleShow={handleShow} ModalClose={ModalClose} show={show} handleSendShow={handleSendShow} />
                </li>))
            }

        </UlBox>
        {
            total >= pageSize &&   <PageBox>
                <Pagination defaultCurrent={page} total={total} pageSize={pageSize} onChange={handlePage}  showSizeChanger={false}  />
            </PageBox>
        }


    </Box>
}
