import UnisatImg from "../../assets/images/unisat.png";
import store from "../../store";
import {useEffect, useRef, useState} from "react";
import {saveAccount, saveType, saveSignature, saveToken, saveLoading, saveWalletList} from "../../store/reducer.js";
import useLogin from "../../hook/useLogin.jsx";
import { SIG_WORD} from "../../utils/constant.js";
import {App} from "antd";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
export default function Unisat({handleClose}){
    const {  notification } = App.useApp();
    const {t } = useTranslation();
    const [disable,setdisable] = useState(false);
    const walletList = useSelector(store => store.walletList);
    const {unisat} = window;
    const selfRef = useRef ({
        accounts: [],
    });
    const self = selfRef.current;

    useLogin(handleClose,"unisat");


    useEffect(() => {
        const checkUnisat =  () => {
            if (!unisat) {
                setdisable(true)
                return;
            }
            setdisable(false)
        }
        checkUnisat();
    }, []);


    const connect = async() =>{
        store.dispatch(saveLoading(true));
        try{
            if(disable){
                throw new Error(t("error.unisatNOInstall"))
            }
            await unisat.switchNetwork(import.meta.env.VITE_CHAIN);

            const result = await unisat.requestAccounts();
            self.accounts = result;
            if (result.length > 0) {
                const signature = await unisat.signMessage(SIG_WORD);

                let btc = {
                    account : result[0],
                    type:"unisat",
                    signature
                }

                store.dispatch(saveWalletList({
                    btc:btc,
                    ckb:walletList.ckb
                }));

                store.dispatch(saveSignature(signature));
                store.dispatch(saveAccount(result[0]));
                store.dispatch(saveType('unisat'));
            }

        }catch (e) {
            console.error(e)


            notification.error({
                message:  t("error.Unisate"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));
        }
    }

    return <dl  onClick={()=>connect()}>
        <dt>
            <img src={UnisatImg} alt=""/>
        </dt>
    <dd>Unisat Wallet</dd>
    </dl>
}
