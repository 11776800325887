import styled from "styled-components";

import Version from "../../version.jsx";
import LogoImg from "../../assets/images/newlogo.png";
import {useNavigate} from "react-router-dom";
import {Links, Resources, Tools} from "../../utils/constant.js";

const FooterBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.1);
    font-size: 14px;

`
const ContentBox = styled.div`
    width: 1274px;
    margin: 0 auto;
    
    @media (max-width: 1274px) {
        width: 100%;
        box-sizing: border-box;
    }
`

const TopFirst = styled.div`
    padding: 40px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media (max-width: 1274px) {
        flex-wrap: wrap;
        justify-content: flex-start;
    }
`

const VersionBox = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const LastBox = styled.div`
    border-top: 1px solid rgba(255, 249, 242,0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding: 20px 0;
    @media (max-width: 1274px) {
        flex-direction: column;
    }
`
const RhtBox = styled.div`
    display: flex;
    align-items: flex-end;
    gap:20px;
`

const LftIntro = styled.div`
    width: 30%;
    .logo{
        margin-bottom: 20px;
        img{
            height: 40px;

        }
    }
    .title{
        font-family: Roboto-Bold;
        font-size: 20px;
        margin-bottom: 10px;
    }

    @media (max-width: 1274px) {
        width: 100%;
        box-sizing: border-box;
        padding: 20px;
    }
`

const LinkBox = styled.div`
    .titleTop{
        font-family: Roboto-Bold;
        font-size: 20px;
        margin-bottom:20px;
    }
    a{
        color: #fff; 
    }
    .links{
        margin-bottom: 10px;
    }

    @media (max-width: 1274px) {
        width: 50%;
        padding: 20px;
        box-sizing: border-box;
        &.none{
            display: none;
        }
    }
`


export default function Footer(){
    const navigate = useNavigate();

    const handleTo = (url) =>{
        navigate(url);
    }


    return <FooterBox>
        <ContentBox>
            <TopFirst>
                <LftIntro>
                    <div className="logo">
                        <img src={LogoImg} alt=""/>
                    </div>
                    <div className="title">Dobby Market</div>
                    <div>The first digital objects (DOBs) platform backed by RGB++ protocol on Bitcoin and other UTXO chains including Nervos CKB. Create, buy, sell, manage, and discover exclusive digital objects all over the world.</div>
                </LftIntro>
                <LinkBox>
                    <div className="titleTop">
                        Tools
                    </div>
                    {
                        Tools.map((item,index)=> (<div className="links" key={index}>
                            <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                        </div>))
                    }
                </LinkBox>
                <LinkBox>
                    <div className="titleTop">
                    Links
                    </div>
                    {
                        Links.map((item,index)=> (<div className="links" key={index}>
                            <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                        </div>))
                    }

                </LinkBox>
                <LinkBox>
                    <div className="titleTop">
                        Resources
                    </div>
                    {
                        Resources.map((item,index)=> (<div className="links" key={`Resource_${index}`}>
                            <a href={item.link} target="_blank" rel="noreferrer">{item.name}</a>
                        </div>))
                    }

                </LinkBox>

            </TopFirst>
            <LastBox>
                <VersionBox>
                    Copyright &copy; Dobby
                    <Version/>
                </VersionBox>
                <RhtBox>
                    {/* <span onClick={()=>handleTo("/privacy")}>Privacy Policy</span>
                    <span onClick={()=>handleTo("/terms")}>Terms of Service</span> */}
                    {/*<span onClick={()=>{}}>Privacy Policy</span>*/}
                    {/*<span onClick={()=>{}}>Terms of Service</span>*/}
                </RhtBox>
            </LastBox>

        </ContentBox>


    </FooterBox>
}
