import store,{persistor} from "./store";
import { Provider } from "react-redux";
import GlobalStyle from "./utils/GlobalStyle";
import { ConfigProvider } from 'antd';
import LayoutBox from "./components/layout/layout.jsx";

import {PersistGate} from "redux-persist/integration/react";
import {App as AntdApp}  from "antd";

import * as Sentry from "@sentry/react";
import {initConfig} from "@joyid/bitcoin";
import {initConfig as initConfigCkb} from "@joyid/ckb";



if(import.meta.env.VITE_CHAIN === "livenet" ){
    Sentry.init({
        dsn: "https://fc618a181c7c57f5ffe58999cf49dd98@o4507175892353024.ingest.us.sentry.io/4507175898906624",
        integrations: [
            Sentry.replayIntegration(),
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}




function App() {

    let joyidType = localStorage.getItem("joyidType");

    initConfig({
        name: "Dobby",
        logo: `${window.location.origin}/images/newlogo.png`,
        requestAddressType:joyidType??'p2tr',
        joyidAppURL: import.meta.env.VITE_CHAIN==="testnet"?"https://testnet.joyid.dev":"https://app.joy.id",
    });
    initConfigCkb({
        name: "Dobby",
        logo: `${window.location.origin}/images/newlogo.png`,
        requestAddressType:joyidType??'p2tr',
        joyidAppURL: import.meta.env.VITE_CHAIN==="testnet"?"https://testnet.joyid.dev":"https://app.joy.id",
    });

  return (
      <PersistGate loading={null} persistor={persistor} >
          <Provider store={store}>
              <ConfigProvider
                  theme={{
                      token: {
                          colorPrimary: '#ff9500',
                          fontFamily: "Roboto-Regular",
                      },
                      components: {
                          Tabs: {
                              colorText: "#fff",
                              fontSize: "16px"
                          },
                          Button: {
                              colorPrimary: '#141414',
                              colorPrimaryHover: "#141414",
                              colorPrimaryActive: "#141414",
                              defaultBg: "#141414",
                              defaultBorderColor: "#424242",
                          },

                          Input: {
                              colorText: 'rgba(255, 255, 255, 0.85)',
                              colorTextPlaceholder: 'rgba(255, 255, 255, 0.2)',
                              colorBgContainer: "#141414",
                              colorBorder: "rgba(255, 255, 255, 0.3)"
                          },
                          Table: {
                              headerBg: "#1d1d1d",
                              headerColor: "rgba(255, 255, 255, 0.85)",
                              colorText: 'rgba(255, 255, 255, 0.85)',
                              colorBgContainer: "#141414",
                              borderColor: "#303030",
                              headerSplitColor: "#000",
                              rowHoverBg:"#ff950010"
                          },
                          Progress: {
                              remainingColor: "rgba(255, 255, 255, 0.12)",
                              circleTextColor: "#c8c8c8",
                              colorText: "rgba(255, 255, 255, 0.85)",
                          },
                          Pagination: {
                              itemActiveBg: "#141414",
                              colorPrimary: "#ff9500",
                              colorText: 'rgba(255, 255, 255, 0.85)',
                          },
                          Modal: {
                              contentBg: "#141414",
                              headerBg: "#141414",
                              colorText: 'rgba(255, 255, 255, 0.85)',
                              titleColor: '#fff',
                              colorIcon: 'rgba(255, 255, 255, 0.45)',
                              colorIconHover: 'rgba(255, 255, 255, 0.85)',
                          },
                          Tag: {
                              defaultBg: "#141414",
                              colorText: "rgba(255, 255, 255, 0.85)",
                              colorBorder: "#424242"
                          },
                          Radio: {
                              buttonBg: "#141414",
                              colorBorder: "#303030",
                              buttonColor: 'rgba(255, 255, 255, 0.85)',
                              buttonCheckedBg: "#141414",
                              buttonSolidCheckedBg: "#Fff"

                          },
                          Slider: {
                              railBg: "rgba(255, 255, 255, 0.2)",
                              railHoverBg: "rgba(255, 255, 255, 0.2)",
                              trackBg: "#ff9500",
                              handleColor: "#ff9500",
                              dotSize: 6,
                              trackHoverBg: "#ff9500"
                          },
                          Popover: {
                              zIndexPopup: 99999,
                              sizePopupArrow: 0,
                              colorBgElevated: "#191A1E",
                              colorText: "rgba(255, 255, 255, 0.85)",
                          },
                          Select: {
                              colorBgContainer: "#141414",
                              colorText: "#fff",
                              optionActiveBg: "rgba(255, 255, 255, 0.1)",
                              optionSelectedBg: "rgba(255, 255, 255, 0.1)",
                              colorBorder: "rgba(255, 255, 255, 0.3)",
                          },
                          Notification: {
                              colorPrimary: "#fff",
                              colorIcon: "#000",
                              colorWarning: "#fff",
                              colorTextDescription: "#fff"
                          },
                          App: {
                              colorText: "#fff",
                              fontSize: 14
                          }
                      },
                  }}
              >

                  <AntdApp>
                      <LayoutBox/>
                  </AntdApp>
              </ConfigProvider>
              <GlobalStyle/>
          </Provider>
      </PersistGate>
  )
}

export default App
