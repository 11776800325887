import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import {App, Input} from "antd";
import {useEffect, useState} from "react";
import {FormatAddress, handleBg} from "../../utils/general.js";
import {useSelector} from "react-redux";
import {MINiPrice} from "../../utils/constant.js";
import {useTranslation} from "react-i18next";
import {collectionsDetailCKB} from "../../api/assets_ckb.js";
import {formatUnit, parseUnit} from "@ckb-lumos/bi";

import DidImg from "../../assets/images/did.svg";

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    .lft{
        flex-grow: 1;

    }
    .icon{
        cursor: pointer;
    }
    
`

const Tips = styled.div`
    font-size: 12px;
    opacity: 0.6;
    padding-top: 10px;
    margin-bottom: 20px;
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .innerImg{
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        background: ${props => props.bg};
    }
    img{
        width: 80px;
        height: 80px;
        object-fit: cover;
        object-position: center;
        //image-rendering: pixelated;
        border-radius: 10px;
    }
    .title{
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 400px;
    }
    .capacity{
        display: flex;
        gap: 5px;
        font-family:din_regular;
    }
    .id{
        //color: #ff9500;
        font-family:din_regular;
        //margin-top: 10px;
    }
    @media (max-width: 1274px) {
        .title{
            width: 58vw;
            word-break: break-all;
        }
    }
`

const DLBox = styled.div`
    dl{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child{
            font-size: 12px;
            opacity: 0.6;
        }
        &:last-child{
            border-bottom: 0;
            margin-bottom: 0;
        }
        span{
            //color: #ff9500;
            padding-left: 10px;
        }
    }
    dd{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .apply{
        background: transparent;
        //color: #ff9500;
        color: #fff;
        //border: 1px solid #ff9500;
        border: 1px solid #fff;
        font-size: 12px;
        padding: 3px 10px;
        border-radius: 5px;
        cursor: pointer;
        &:disabled{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .num{
        font-family: din_bold;
    }
    .sym{
        opacity: 0.6;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type=number] {
        -moz-appearance:textfield;
    }
`

const FeeLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .custom{
        border:1px solid #FF9500;
        color: #ff9500;
        padding:5px 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .num{
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
        padding-left: 5px;
    }
    .rhtNum{
        color: #ff9500;
    }
`

const CustomLine = styled.div`
    .fst{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        width: 32%;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover,&.active{
            cursor: pointer;
            border: 1px solid #ff9500;
        }
    }
    .title{
        font-size: 12px;
        opacity: 0.6;
        
    }
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-right: 10px;
    }
    .sym{
        font-size: 12px;
        font-weight: bold;
    }

`


const BtmBox = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 20px;
    &>div,dl{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    dl{
        font-size: 12px;
        padding-bottom:5px;
        opacity: 0.6;
        
    }
    dd{
        display: flex;
        align-items: center;
        gap:5px;
    }
    .price{
        color: #ff9500;
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button{
        width: 49%;
        height: 40px;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        &:disabled{
            opacity: 0.5;
        }
    }
    .confirm{
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
    }
    .cancel{
        border:1px solid #FF9500;
        color: #ff9500;
        background: none;
    }
`

const TipsBox = styled.div`
    color: #f00;
    opacity: 0.6;
    font-size: 12px;
`

export default function List_ckb({ModalClose,item,handleList}){
    const {t } = useTranslation();
    const {  notification } = App.useApp();
    const [inputValue, setInputValue] = useState('');
    const serviceFee = useSelector(store => store.serviceFee);
    const [show,setShow] = useState(false);
    const [floor,setFloor] = useState(0)
    const minPriceFormat = parseUnit(MINiPrice,"ckb");


    useEffect(()=>{
        if(!item)return;
        getFloorprice()
    },[item])

    const getFloorprice = async() =>{
        try{
            let rt = await collectionsDetailCKB(item?.clusterId)
            setFloor(rt)
        }catch (e) {
            notification.error({
                message: t("error.floor"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }


    }

    const onChange = (e) => {
        const {value} = e.target;
        setShow(!checkDecimalPlaces(value))
        setInputValue(value);
    };


    const  checkDecimalPlaces = (num) => {
        const numStr = num.toString();
        let dec = import.meta.env.VITE_CHAIN === "testnet" ? 8:3


        if (numStr.includes('.')) {
            const decimalPart = numStr.split('.')[1];
            if (decimalPart.length <= dec) {
                return true; // <8
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const handleApply = () =>{
        let newPr = formatUnit(floor.floorPrice,"ckb");
        setInputValue(newPr)
    }


    return <MaskBox>
        <ContentBox>
            <TitleBox>
                <div className="lft">{t("modal.ListConfirmation")}</div>

                <CloseOutlined className="icon" onClick={() => ModalClose()}/>
            </TitleBox>
            <Tips>{t("modal.confirmTips")}:</Tips>
            <FirstLine bg={handleBg(item)}>
                {
                    item.assetType === 0 && <div className="innerImg">
                        <img
                            src={item?.spore?.url || item?.spore?.imgUrl}
                            alt=""/>
                    </div>
                }
                {
                    item.assetType === 1 && <div className="innerImg">
                        <img
                            src={DidImg}
                            alt=""/>
                    </div>
                }


                <div className="rht">
                    {
                        item.assetType === 1 &&
                        <div className="title"> {item?.spore?.DidName.split(".")[0]}<span>.bit</span></div>
                    }

                    <div className="id">ID:#{FormatAddress(item?.dobsId)}</div>
                    <div className="capacity">
                        <dt className="rhtNum">Capacity:</dt>
                        <dd>
                            <span
                                className="num rhtNum">{item?.capacity ? formatUnit(item?.capacity, "ckb") : 0}</span>
                            <span className="sym">CKB</span>
                        </dd>
                    </div>
                </div>
            </FirstLine>
            <DLBox>
                <dl>
                    <dt>{t("modal.FloorPrice")}</dt>
                    <dd>
                        <span>{floor.floorPrice ? formatUnit(floor.floorPrice, "ckb") : 0} {floor.currency}</span>
                        <button className="apply" disabled={!floor.floorPrice}
                                onClick={() => handleApply()}>{t("modal.Apply")}</button>

                    </dd>
                </dl>
                <dl>
                <dt>{t("modal.Price")}</dt>
                    <dd>
                        <Input type="number" min={0} onChange={onChange} value={inputValue}/>CKB
                    </dd>
                </dl>

            </DLBox>
            {/*<BtmBox>*/}
            {/*        /!*<dl>*!/*/}
            {/*        /!*    <dt>Service Fee <span className="tag">{(serviceFee?.maker_fee * 100).toFixed(2)}%</span></dt>*!/*/}
            {/*        /!*    <dd>*!/*/}
            {/*        /!*        <span className="num">{formatFee() }</span>*!/*/}
            {/*        /!*        <span className="sym">sats</span>*!/*/}
            {/*        /!*    </dd>*!/*/}
            {/*        /!*</dl>*!/*/}
            {/*    /!*<div>*!/*/}
            {/*    /!*    <div>You receive</div>*!/*/}
            {/*    /!*    <div>*!/*/}
            {/*    /!*        /!*<span className="price">{getResult()}</span> <span className="sym">BTC</span></div>*!/*!/*/}
            {/*    /!*        <span className="price">{inputValue}</span> <span className="sym">BTC</span></div>*!/*/}
            {/*    /!*</div>*!/*/}

            {/*</BtmBox>*/}

            {
                !inputValue || parseUnit(Number(inputValue).toFixed(8),"ckb").lt(minPriceFormat) && <TipsBox>{t("modal.tips_ckb_list", {MINiPrice})}</TipsBox>
            }

            <ButtonGroup>
                <button className="cancel" onClick={() => ModalClose()}>{t("modal.Cancel")}</button>
                <button className="confirm" disabled={show ||!inputValue || parseUnit(Number(inputValue).toFixed(8),"ckb").lt(minPriceFormat) }
                        onClick={() => handleList(inputValue, item)}>{t("modal.Confirm")}
                </button>
            </ButtonGroup>
        </ContentBox>
    </MaskBox>
}
