import styled from "styled-components";
import Avatar from 'boring-avatars';

import {CopyOutlined,CaretDownOutlined} from "@ant-design/icons";
import {Tabs, App, Select} from "antd";

import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
// import {handleBalance} from "../utils/wallet.js";
import {CopyToClipboard} from "react-copy-to-clipboard";
// import {notification} from 'antd';
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormatAddress, isBTC, isCCC} from "../utils/general.js";
import {useTranslation} from "react-i18next";
import Collections_ckb from "../components/myAssets/collections_ckb.jsx";
import {getNet} from "../utils/wallet.js";

import {BI, helpers, Indexer} from "@ckb-lumos/lumos";
import {CKB_networkList} from "../utils/constant.js";
import {formatUnit} from "@ckb-lumos/bi";
import {predefined} from "@ckb-lumos/config-manager";
import MyOrderListCKB from "../components/myAssets/myOrderList_CKB.jsx";
import useCCCSigner from "../hook/useCCCSigner.jsx";
import ChangeAddress from "../components/modal/changeAddress.jsx";



const Box = styled.div`
    margin-top: 50px;
    .ant-select .ant-select-arrow{
        color: rgba(255,255,255,0.3);
    }
`
const TopBanner = styled.div`
    //background: rgba(255, 255, 255, 0.1) ;
    
    background: linear-gradient(
            217deg,
            rgba(255, 90, 95, 0.2),
            rgba(7, 76, 255, 0.2)
    ), linear-gradient(127deg, rgba(4, 119, 56, 0.1), rgba(19, 173, 165, 0) 70.71%),
    linear-gradient(336deg, rgba(247, 186, 42, 0.1), rgba(255, 149, 0, 0) 70.71%);
    

    padding: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    @media (max-width: 1274px) {
        margin: 0 auto 20px;
        box-sizing: border-box;
        padding: 20px;
        
    }
`

const RhtBox = styled.div`

    .title{
        font-family: Roboto-Bold;
        font-size: 18px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .tips{
        font-size: 12px;
        opacity: 0.5;
    }
    .titleMobile{
        display: none;
    }
    @media (max-width: 1274px) {
        .title{
            display: none;
        }
        .titleMobile{
            display: flex;
            font-family: Roboto-Bold;
            font-size: 16px;
            justify-content: center;
        }
 
        .iconB{
            margin-left: 5px;
        }
    }
`
const FirstLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 1274px) {
        flex-direction: column;
        gap: 0;
        .avatar{
            svg{
                width: 40px;
                height: 40px;
            }
        }

    }
`
const TitleBox = styled.div`
    border-bottom: 1px solid rgba(255,255,255,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-tabs-top >.ant-tabs-nav{
        margin-bottom: 0;
    }

    @media (max-width: 1274px) {
        padding: 0 10px;
    }
`

const BalanceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-left: 10px;
        
    }
    .sym{
        margin-left: 5px;
        margin-bottom: -2px;
        opacity: 0.6;
    }
`

const FlexLine = styled.div`
    display: flex;
    gap:10px;
    align-items: center;

    @media (max-width: 1274px) {
        flex-direction: column;
        justify-content: center;
        gap:0;
    }
`

const ArrBox = styled.div`
    background: rgba(255, 255, 255, 0.2) ;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    @media (max-width: 1274px) {
        margin-left: 5px;
    }
`


export default function MyAssets_ckb(){
    const {t } = useTranslation();
    const { notification } = App.useApp();
    const account = useSelector(store => store.account);
    const secondary = useSelector(store => store.secondary);
    const type = useSelector(store => store.type);
    const [balance,setBalance] = useState({})
    const navigate = useNavigate()
    const [current,setCurrent] = useState("1")
    const {signer} = useCCCSigner(type);
    const [show,setShow]=useState(false);


    const [ orderList,setOrderList] = useState("")


    const [selectType,setSelectType] = useState('')

    const [search] = useSearchParams();
    const tab = search.get("tab");

    useEffect(()=>{
        setCurrent(tab|| "1")

    },[tab])

    useEffect(() => {
        if(!type) {
            navigate("/")
            return;
        }
        if(isBTC(type)){
            navigate("/myAssets")
        }

    }, [type]);


    const items = [
        {
            key: '1',
            label: t("general.MyAssets"),
        },
        {
            key: '2',
            label: t("my.orders"),
        },

    ];


    useEffect(() => {
        handleOrder()
    }, [selectType]);

    const handleOrder= () => {
        console.log("selectType",selectType)
        // 0 Unlist，1List，2Success
        switch (selectType){
            case "listed":
                setOrderList("status_1")
                break;

            case "sold":
                setOrderList("status_2")
                break;

            case "unlisted":
                setOrderList("status_0")
                break;


        }
    };

    // useEffect(() => {
    //     if(!account) {
    //         navigate("/")
    //     }
    // }, [account]);


    useEffect(() => {
        if(!type || !account || isBTC(type)) return;

        // if(isCCC(type) ){
        //     signer && getCCCBal()
        // }else{
        //     getBl()
        // }
        getBl()

    }, [type,account,signer]);

    // const getCCCBal = async() =>{
    //     const rt = await signer.getBalance();
    //     console.log(rt.toString())
    //
    //     setBalance({capacity:formatUnit(rt.toString(),"ckb"),OcCapacity:""});
    // }

    const getBl = async() =>{
        try{
            const net = await getNet()

            const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

            const rpcUrl = CKB_networkList[net].node
            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);

            let totalCapacity = BI.from(0);
            let OcCapacity = BI.from(0);


            const addressScript = helpers.parseAddress(account,{config:newConfig});

            const collector = indexer.collector({
                lock: addressScript,
                scriptSearchMode:"exact"
            });

            for await (const cell of collector.collect()) {
                totalCapacity = totalCapacity.add(cell.cellOutput.capacity);
                if(cell.data !== "0x"){
                    OcCapacity = OcCapacity.add(cell.cellOutput.capacity)
                }
            }

            let  total = totalCapacity.toString()
            let occ = OcCapacity.toString()

            setBalance({capacity:formatUnit(total,"ckb"),OcCapacity:formatUnit(occ,"ckb")});
        }catch(e){
            console.log(e)
            notification.error({
                message: "Get Balance",
                description:e?.response?.data?.message || e?.message || e?.reason
            });
        }


    }

    const Copy = () =>{


        notification.success({
            message: 'Copied!',
        });
    }


    const onChange = (key) => {
        setCurrent(key)
    };


    const handleShow = () =>{
        setShow(true)
    }

    const handleClose = () =>{
        setShow(false)
    }

    const handleChange = (value) => {
        setSelectType(value)
    };




    return <Box>
        {
            show  && <ChangeAddress handleClose={handleClose} />
        }
        <TopBanner>
            <FirstLine>
                {
                    !!account &&  <div className="avatar"><Avatar

                        size={100}
                        name={account}
                        variant="beam"
                        colors={[
                            '#FF5A5F',
                            '#074ce1',
                            '#047738',
                            "#13ada5",
                            "#F7BA2A"]}
                    />
                    </div>
                }

                <RhtBox>
                    <div className="title">{account}
                        {
                            isCCC(type) && !!secondary&& <ArrBox onClick={()=>handleShow()}><CaretDownOutlined /></ArrBox>
                        }

                        <CopyToClipboard onCopy={() => Copy()} text={account}>
                             <CopyOutlined/>
                        </CopyToClipboard>
                    </div>


                    <div className="titleMobile">{FormatAddress(account)}
                        {
                            isCCC(type) && !!secondary&&<ArrBox onClick={()=>handleShow()}><CaretDownOutlined /></ArrBox>
                        }
                        <CopyToClipboard onCopy={() => Copy()} text={account}>
                            <CopyOutlined className="iconB"/>
                        </CopyToClipboard>
                    </div>

                    <FlexLine>
                        <BalanceBox>
                            <div>CKB {t("my.balance")}</div>
                            <div>
                                {t("my.total")}:<span className="num">{balance?.capacity}</span> <span
                                className="sym">CKB</span>
                            </div>

                        </BalanceBox>
                        {
                            !!balance?.OcCapacity &&  <BalanceBox>
                                <div>Occupied:<span className="num">{balance?.OcCapacity}</span> <span className="sym">CKB</span></div>
                            </BalanceBox>
                        }


                    </FlexLine>

                </RhtBox>
            </FirstLine>

        </TopBanner>
        <TitleBox>

            <Tabs activeKey={current} items={items} onChange={onChange}/>
            {
                current === "2" && <div className="li"><Select
                    defaultValue=""
                    size="large"
                    style={{width: "140px"}}
                    className="selectBox"
                    onChange={handleChange}
                    dropdownStyle={{background: "#141414"}}
                    options={[
                        {value: '', label: t("collection.AllEvents")},
                        {value: 'listed', label: t("collection.Listed")},
                        {value: 'sold', label: t("collection.Sold")},
                        {value: 'unlisted', label: t("collection.Unlisted")},
                    ]}
                /></div>
            }

        </TitleBox>
        {
            current === "1" && <Collections_ckb/>
        }
        {
            current === "2" && <MyOrderListCKB searchList={orderList}/>
        }
    </Box>
}
