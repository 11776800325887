import styled from "styled-components";

import { useEffect, useState } from "react";

import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import LoadingBtn from "../loadingBtn.jsx";
import { App, Input } from "antd";
import { getFeeRate } from "../../api/btc.js";

import { TOKEN_CONFIG } from "../../utils/constant.js";
import { RgbppHelper } from "dobs-dex-sdk";
import { sendRbfToSendServer, assetsInfoByHash, getPsbt } from "../../api/assets.js";
import { bitcoin, NetworkType, networkTypeToConfig } from "@rgbpp-sdk/btc";
import { handlePublicKey, handleSignPsbt } from "../../utils/wallet.js";
import { useSelector } from "react-redux";

import store from "../../store/index.js";
import { saveLoading } from "../../store/reducer.js";

import { BtcAssetsApi } from "@rgbpp-sdk/service";

const { TextArea } = Input;


const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    font-family: din_bold;
    font-size: 18px;

    .lftImg{
        cursor: pointer;
    }
    .lft{
        flex-grow: 1;
        text-align: center;

    }
    .icon{
        cursor: pointer;
    }
    
`


const MidBox = styled.div`
    display: flex;
    flex-direction: column;

    .btnBox{
        padding: 15px;
        align-items: center;
        justify-content: center;
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        color: #0D0C0B;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        display: flex;
        gap: 10px;
        &:disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
`


const TextBox = styled(TextArea)`
    margin: 30px 0 50px;
    resize: none!important;
    border: 1px solid #ff9500;
`

// eslint-disable-next-line react/prop-types
export default function RBFModal({ handleClose }) {
    const { notification } = App.useApp();

    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)

    const { t } = useTranslation();
    const [txHash, setTxHash] = useState("");
    const [feeRate, setFeeRate] = useState(0);

    const transfer = async () => {
        store.dispatch(saveLoading(true))

        try {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

            let publicKey = await handlePublicKey(type, isMobile)

            let result = await handleConfirm(txHash, account, publicKey, feeRate)

            console.log("result", result)

            notification.success({
                message: t("modal.SendSuccess"),
                // description: result
            });

            setTimeout(() => {
                handleClose()
            }, 1000)

        } catch (e) {
            console.error(e)

            notification.error({
                message: t("modal.SendFailed"),
                description: e?.response?.data?.message || e.message || e.reason
            });

        } finally {
            store.dispatch(saveLoading(false))
        }

    }


    const handleConfirm = async (tx_hash, fromBtcAddress, fromBtcPubkey, fee_rate) => {

        const isMainnet = import.meta.env.VITE_CHAIN === "livenet"

        const BTC_ASSETS_API_URL = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_API_URL;
        const BTC_ASSETS_ORGIN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_ORGIN;
        const BTC_ASSETS_TOKEN = TOKEN_CONFIG[import.meta.env.VITE_MODE].BTC_ASSETS_TOKEN;

        const networkType = import.meta.env.VITE_CHAIN === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;

        const cfg = networkTypeToConfig(networkType);

        console.log(cfg)

        const rgbppHelper = new RgbppHelper(isMainnet, BTC_ASSETS_API_URL, BTC_ASSETS_TOKEN, BTC_ASSETS_ORGIN);

        const { btcTranscation, psbt, ckbVirtualTxResult } = await rgbppHelper.buildRbfTransferPsbt(tx_hash, fromBtcAddress, fromBtcPubkey, fee_rate); // feeRate

        let flagIsOrderRbf = false;
        let txHash = '';
        let txIdx = -1;
        for (let i = 0; i < btcTranscation.vin.length; i++) {
            const element = btcTranscation.vin[i];
            if (element.prevout.scriptpubkey_address !== fromBtcAddress && element.prevout.value <= cfg.rgbppUtxoDustLimit) {
                flagIsOrderRbf = true;
                txHash = element.txid;
                txIdx = element.vout;
                break;
            }
        }

        if (flagIsOrderRbf) {
            const btcService = BtcAssetsApi.fromToken(BTC_ASSETS_API_URL, BTC_ASSETS_TOKEN, BTC_ASSETS_ORGIN);

            const rgbppAsset = await btcService.getRgbppAssetsByBtcUtxo(txHash, txIdx);

            const assetHashId = rgbppAsset[0].cellOutput.type.args.indexOf('0x') === 0 ? rgbppAsset[0].cellOutput.type.args.substring(2) : rgbppAsset[0].cellOutput.type.args
            const assetInfo = await assetsInfoByHash(assetHashId);

            // console.log(assetInfo);

            if (assetInfo.order) {
                const rt = await getPsbt(assetInfo.order.order_id);
                console.log("~~~~~~handlePBST", rt)

                const { psbt: psbtHex } = rt;

                // check tapKeySig & partialSig in input[1]
                let checkSign = bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })

                console.log("checkSign", checkSign)

                psbt.data.inputs[1] = checkSign.data.inputs[1];
            }
        }

        const signInputList = [];

        for (let i = 0; i < psbt.txInputs.length; i++) {
            if (!psbt.data.inputs[i].tapKeySig && !psbt.data.inputs[i].partialSig) {
                signInputList.push({
                    index: i,
                    address: account,
                    publicKey: fromBtcPubkey,
                });
            }
        }

        console.log(psbt)

        let psbtSigned;

        psbtSigned = await handleSignPsbt(
            psbt.toHex(),
            type,
            {
                autoFinalized: false,
                toSignInputs: signInputList,
            }
        );

        console.log(psbt.toHex())



        //
        const newPsbt = bitcoin.Psbt.fromHex(psbtSigned, { network: cfg.network });
        // const rawTx = bitcoin.Transaction.fromHex(psbt.toHex(), { network: cfg.network });
        //
        newPsbt.finalizeAllInputs();
        const btcTx = newPsbt.extractTransaction();
        //
        console.log("btcTx", btcTx.toHex())

        const rt = await sendRbfToSendServer(btcTx.toHex(), ckbVirtualTxResult)

        console.log('------rbf tx result', rt);

        handleClose()
    }

    useEffect(() => {
        getRate()
    }, [])

    const getRate = async () => {
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try {
            let rt = await getFeeRate();
            const { fastestFee } = rt;

            setFeeRate(fastestFee)
        } catch (e) {
            console.error("https://mempool.space/api/v1/fees/recommended", e)
            notification.error({
                message: t("modal.feeRate"),
                description: e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    return <MaskBox>
        <ContentBox>

            <TitleBox>

                <div className="lft">RBF</div>
                <CloseOutlined className="icon" onClick={() => handleClose()} />
            </TitleBox>
            <MidBox>
                Transaction:<TextBox value={txHash} autoSize={{
                    minRows: 1,
                    maxRows: 10,
                }} onChange={e => setTxHash(e.target.value)} />

                FeeRate:<TextBox value={feeRate} autoSize={{
                    minRows: 1,
                    maxRows: 10,
                }} onChange={e => setFeeRate(Number.parseInt(e.target.value))} />

                <button className="btnBox" disabled={!txHash || !feeRate} onClick={() => transfer()}>{t("modal.Confirm")}

                    {/*<LoadingBtn />*/}
                </button>
            </MidBox>
        </ContentBox>
    </MaskBox>
}
