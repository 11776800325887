import { Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/home";
import Collection from "../pages/collection.jsx";
import Collection_ckb from "../pages/collection_ckb.jsx";
import ItemDetail from "../pages/ItemDetail.jsx";
import MyAssets from "../pages/myAssets.jsx";
import MyAssets_ckb from "../pages/myAssets_ckb.jsx";
import Privacy from "../pages/privacy.jsx";
import Terms from "../pages/Terms.jsx";
import ItemDetail_ckb from "../pages/ItemDetail_ckb.jsx";

function RouterLink() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Navigate to="/home" />} />
                <Route path="/home" element={<Home />} />
                <Route path="/cluster/:id" element={<Collection />} />
                <Route path="/cluster_ckb/:id" element={<Collection_ckb />} />
                <Route path="/item-detail/:id" element={<ItemDetail />} />
                <Route path="/item-detail_ckb/:id" element={<ItemDetail_ckb />} />
                <Route path="/my-detail/:id" element={<ItemDetail />} />
                <Route path="/my-detail_ckb/:id" element={<ItemDetail_ckb />} />
                <Route path="/myAssets" element={<MyAssets />} />
                <Route path="/myAssets_ckb" element={<MyAssets_ckb />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />

            </Routes>
        </>
    );
}

export default RouterLink;
