import i18n from "i18next";

import enTrans from "./lan/en-us.json";
import zhTrans from "./lan/zh-cn.json";
import {
    initReactI18next
} from 'react-i18next';
import {DEFAULT_LANGUAGE} from "../utils/constant.js";

function getStoreLang() {
    return localStorage.getItem('lang');
}

function saveStoreLang(lang) {
    localStorage.setItem('lang', lang)
}

i18n.use(initReactI18next)
    .init({
        resources: {
            "en-us": {
                translation: enTrans,
            },
            "zh-cn": {
                translation: zhTrans,
            },
        },
        lowerCaseLng: true,
        fallbackLng: DEFAULT_LANGUAGE,
        lng: getStoreLang() || DEFAULT_LANGUAGE,
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })
i18n.on("languageChanged", (lang)=> {
    saveStoreLang(lang)
})
export default i18n;
