import request from './index';

const rootPrefix = import.meta.env.VITE_CHAIN==="testnet"?"/btc":'';

export const collectionsDetail= (id) =>{
    return request.get(`${rootPrefix}/collections/${id}/mkt_info`);
}

export const assetsCreate = (data) =>{
    return request.post(`${rootPrefix}/orders/create`,data);
}
export const assetsBuy = (id,data) =>{
    return request.post(`${rootPrefix}/orders/${id}/take`,data);
}

export const assetsMy = (offset,pageSize) =>{
    return request.get(`${rootPrefix}/assets/my?offset=${offset}&size=${pageSize}`);
}


export const assetsInfo = (id) =>{
    return request.get(`${rootPrefix}/assets/detail/${id}`);
}

export const getPsbt = (id) =>{
    return request.post(`${rootPrefix}/orders/${id}/get_psbt`);
}




export const cancelOrder = (id) =>{
    return request.post(`${rootPrefix}/orders/${id}/cancel`);
}





// my orders:  /orders/my?collection_id=:coll_id

// all: /collections/:coll_id/assets?sort_field=order_price&sort=asc
export const collectionsList= (id,offset,pageSize,queryWord,type,typeSort) =>{
    return request.get(`${rootPrefix}/collections/${id}/assets?offset=${offset}&size=${pageSize}&q=${queryWord}&sort_field=${type}&sort=${typeSort}`);
}

//     listed: /collections/:coll_id/assets?sort_field=order_price&sort=asc&listing_only=1
export const listedList=  (id,offset,pageSize,queryWord,type,typeSort) =>{
    return request.get(`${rootPrefix}/collections/${id}/assets?offset=${offset}&size=${pageSize}&q=${queryWord}&sort_field=${type}&sort=${typeSort}&listing_only=1`);
}

//     orders: /orders/all?collection_id=:coll_id
export const OrderList= (id,offset,pageSize,status) =>{
    return request.get(`${rootPrefix}/orders/all?collection_id=${id}&offset=${offset}&size=${pageSize}&status=${status}&sort_field=update_ts&sort=desc`);
}

export const myOrderList= (id,offset,pageSize,status) =>{
    return request.get(`${rootPrefix}/orders/my?include_cancelled=false&offset=${offset}&size=${pageSize}&status=${status}&sort_field=update_ts&sort=desc`);
}
export const getServiceFee = () =>{
    return request.get(`${rootPrefix}/dex-info/fee`);
}

export const postUtxoItem = () =>{
    return request.post(`${rootPrefix}/datasrv/import_my_utxo_data`);
}

export const getFloor = (collectionId) =>{
    return request.get(`${rootPrefix}/collections/${collectionId}/floor_price`);
}

// export const getList = () =>{
//     return request.get(`${rootPrefix}/collections`);
// }

export const sendToSendServer = (id,tx,ckbVirtualTxResult) =>{
    return request.post(`${rootPrefix}/assets/sendasset/${id}`,{raw_tx:tx, ckb_virtual_tx_result:ckbVirtualTxResult});
}

export const sendRbfToSendServer = (tx,ckbVirtualTxResult) =>{
    return request.post(`${rootPrefix}/assets/rbftx`,{raw_tx:tx, ckb_virtual_tx_result:ckbVirtualTxResult});
}

export const assetsInfoByHash = (hash) =>{
    return request.get(`${rootPrefix}/assets/asset/${hash}`);
}
