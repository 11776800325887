import styled from "styled-components";

const Box = styled.div`

    /* HTML: <div class="loader"></div> */
    .loader {
        width: 30px;
        opacity: 0.6;
        aspect-ratio: 4;
        background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% space;
        clip-path: inset(0 100% 0 0);
        animation: l1 1s steps(4) infinite;
    }
    @keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}
`

export default function LoadingBtn(){
    return <Box>
        <div className="loader"></div>
    </Box>
}
