import styled from "styled-components";
import {App, Table, Tag} from 'antd';
import {Link, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {myOrderListCKB} from "../../api/assets_ckb.js";
import {useSelector} from "react-redux";
import {getNet} from "../../utils/wallet.js";
import {FormatAddress, formatUSD_ckb, toJSONStr} from "../../utils/general.js";
import {CKB_networkList, DID_CONTRACT} from "../../utils/constant.js";
import dayjs from "dayjs";
import {useTranslation} from "react-i18next";
import {formatUnit} from "@ckb-lumos/bi";
import {BI, CellCollector, Indexer} from "@ckb-lumos/lumos";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {build_unlist} from "../../utils/ckbTransaction.js";
import {sendRawTx} from "../../utils/ckbUtils.js";
import {getFeeRate} from "../../api/ckb.js";
import DidImg from "../../assets/images/did.svg"
import {Buffer} from "buffer";

const NewBtn = styled.button`

    height: 35px;
    border-radius: 10px;
    cursor: pointer;
    border:1px solid #FF9500;
    color: #ff9500;
    background: none;
    padding: 0 30px;
`

const TableBox = styled.div`
    margin: 10px 0;
    a{
        text-decoration: underline;
    }
    th{
        text-transform: uppercase;
    }
    .tagBg{
        border: 0;
        font-family: Roboto-Bold;
        font-size: 14px;
        text-transform: capitalize;
    }
    .photo{
        width: 60px;
        height: 60px;
        border-radius: 5px;
    }
    .status_2{
        color: #FF5A5F;
        border: 0;
    }
    .updated{
        color:#8AC926;
    }
    .status_1{
        color: #3DA5D9;
    }  
    .status_0{
        color: #FF914C;
    }
    .status_3{
        color: #F600FF;
    }
    
    .preview{
        width: 60px;
        height: 60px;
        object-position: center;
        object-fit: cover;
    }
    .btc{
        
        span{
            font-family: din_bold;
            font-size: 16px;
        }
    }
    .price{
        opacity: 0.6;
        font-family:din_regular;
    }
    a.time{
        color: #FFF9F2;
        text-decoration: none;
    }
    @media (max-width: 1274px) {
        padding-left: 10px;
        width: 100%;
        box-sizing: border-box;
    }
`
export default function MyOrderList({searchList}){
    const {t } = useTranslation();
    const {id} = useParams()
    const [page,setPage] = useState(1)
    const [total,setTotal] = useState(0)
    const [pageSize,setPageSize] = useState(10)
    const infoCKb = useSelector(store => store.infoCKb)
    const type = useSelector(store => store.type)
    const {  notification } = App.useApp();
    const [list,setList] = useState([])
    const [fee, setFee] = useState(null);
    const [net,setNet] = useState("testnet");
    const account = useSelector(store => store.account);

    useEffect(() => {
        setPage(1)
    }, [searchList]);

    useEffect(()=>{
        getAssets()

    },[page,searchList])
    useEffect(()=>{
        getRate()
        getNetwork()

        let timer = setInterval(()=>{
            getRate()
        }, 30 * 1000)

        return () =>{
            clearInterval(timer)
        }
    },[])

    const getNetwork = async () =>{
        let rt = await getNet(type)
        setNet(rt)
    }

    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {median} = rt;

            let feeRate = BI.from(median).toString()
            setFee(feeRate)
        }catch (e) {
            console.error(e)
            notification.error({
                message: t("modal.FeeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    const getAssets = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await myOrderListCKB(id,page,pageSize,searchList,account)
            const {current,data,total} = rt
            setTotal(total)


            rt.data.map((item)=>{
                const sporeObj = toJSONStr(item?.sporeData)

                let imgUrl;
                let txt;

                if(sporeObj.contentType.indexOf("image")>-1){
                    const buffer = Buffer.from(sporeObj.content.toString().slice(2), 'hex');
                    const base64 = buffer.toString("base64");
                    imgUrl =  `data:${sporeObj.contentType};base64,${base64}`;
                }else if(sporeObj.contentType.indexOf("text")>-1){
                    const buffer = Buffer.from(sporeObj.content.toString().slice(2), 'hex');
                    txt =  buffer.toString()
                }
                item.spore = {
                    imgUrl,
                    txt,
                }
            })

            setPage(current)
            setList(data)

        }catch (e) {
            console.error("collectionsList",e)

            notification.error({
                message: t("error.MyOrders"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }


    const handlePage = (e) =>{
        setPage(e)
    }

    const handleCancel = async(item) =>{

        store.dispatch(saveLoading(true));
        try {

            const net = await getNet()

            const rpcUrl = CKB_networkList[net].node
            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);

            const version = net === 'testnet'?"preview":"latest";
            const sporeConfig = net==="testnet"? predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;
            const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);


            let selectItemObj;

            if(item.assetType === 0){
                const {script:{codeHash,hashType}} = sporeTypeScript;

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:codeHash,
                        hashType:hashType,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }else if(item.assetType === 1){


                const {CODE_HASH,HASH_TYPE} = DID_CONTRACT[net]

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:CODE_HASH,
                        hashType:HASH_TYPE,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }

            let txSkeleton = await build_unlist(fee,selectItemObj,account,type,item.assetType)
            let result = await sendRawTx(txSkeleton,type,account,true,getAssets)

            notification.success({
                message: t("modal.unlistSuccess"),
                description: result
            });


        }catch (e) {
            console.error(e)
            notification.error({
                message: t("modal.unlistFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));
        }

    }



    const columns = [
        {
            title: t('table.Preview'),
            dataIndex: 'media_info',
            fixed: 'left',
            render: (_,row) =><div className="photo">
                {
                    row.assetType === 0 && <img src={row?.spore?.imgUrl || row.dobsPreview} className="preview"/>
                }

                {
                    row.assetType === 1 && <img src={DidImg} className="preview"/>
                }
            </div>,
        },
        {

            title:t('table.DOBID'),
            dataIndex: 'dob_id',
            render: (_,row) => <Link to={`/item-detail_ckb/${row.dobsId}`}>{FormatAddress(row.dobsId)}</Link>,
        },
        {
            title: t('table.Event'),
            dataIndex: 'status',
            render: (text) =><Tag className={`status_${text} tagBg`}>{t(`table.status_${text}`)}</Tag>,
        },

        {
            title:t('table.Value'),
            dataIndex: 'price',
            render: (_, { price }) =><div >
                <div className="btc"><span>{formatUnit(price ?? 0, 'ckb').toString()}</span> CKB</div>
                <div className="price">$ {formatUSD_ckb(infoCKb?.ckbToUSDT,price)}</div></div>,
        },
        {
            title:t('table.From'),
            dataIndex: 'fromAddr',
            render: (text) => <a href={`${CKB_networkList[net]?.scanUrl}address/${text}`} rel="noreferrer" target="_blank">{FormatAddress(text)}</a>,
        },
        {
            title: t('table.To'),
            dataIndex: 'toAddr',
            render: (text) => <a href={`${CKB_networkList[net]?.scanUrl}address/${text}`} rel="noreferrer" target="_blank">{FormatAddress(text)}</a>,
        },
        {
            title: t('table.Time'),
            dataIndex: 'updateAt',
            render: (text) => <a className="time">{dayjs(text).format('YYYY-MM-DD HH:mm')}</a>,
        },
        {
            title: t('table.Operate'),
            dataIndex: 'time',
            render: (_,row) => <div>
                {row.status === 1 &&<NewBtn onClick={()=>handleCancel(row)}>{t('general.Cancel')}</NewBtn>
                }
                </div>,
        }
    ];


    return <TableBox>
        <Table  columns={columns} dataSource={list}
                scroll={{
                    x:850
                }}
                rowKey={() => Math.random().toString(36).substr(2)}
                pagination={{
                    position:["bottomCenter"],
                    pageSize,
                    current:page,
                    total,
                    showSizeChanger:false,
                    onChange: (page) => {
                        handlePage(page);
                    }
                }}
        />
    </TableBox>
}
