import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation} from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {getBanner} from "../../api/all_list.js";
import DefaultImg from "../../assets/images/test_logo.png";
import {BitcoinUnit} from "bitcoin-units";
import {formatUnit} from "@ckb-lumos/bi";
import DidImg from "../../assets/images/did.svg";

const Box = styled.div`
    margin:30px auto 0;
    //background: #21170d;
    padding: 50px 0;
    width: 100%;
    box-sizing: border-box;
    @media (max-width: 1274px) {
        margin: 30px 0;
    }
`

const SwiperBox = styled.div`
    width: 100%;
    box-sizing: border-box;

    
    .swiper{
        padding: 0 30px;
        
    }

    .swiper-slide{
        width: 100%;
        border-radius: 10px;
        box-sizing: border-box;
        overflow: hidden;
        
        position: relative;
    }
    .photo{

        display: flex !important;
        overflow: hidden;
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                cursor: pointer;
                transition: transform 0.3s ease;
                &:hover {
                    transform: scale(1.2); /* 放大到原大小的1.2倍 */
                }
            }
        }
    }
    .swiper-button-prev, .swiper-button-next{
        color: #fff;
        &:after{
            font-size: 30px!important;
        }
    }
    .swiper-button-prev{
        height: 100%;
        top: 22px;
        left: 0;
        background: #090808;
        //background: #21170d;
    }   
    .swiper-button-next{
        height: 100%;
        top: 22px;
        right:0;
        background: #090808;
        //background: #21170d;
    }
    .titleBtm{
        min-height: 30px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 999;
        background: linear-gradient(transparent,#000);
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 20px;
    }
    .titleTop{
        font-size: 18px;
        font-weight: bold;
    }
`

export default function Banner(){
    const {t } = useTranslation();
    const navigate = useNavigate()
    const[list,setList] = useState([])
    const handleTo = (id,currency) =>{

        const url = currency === "BTC"?`/cluster/${id}`:`/cluster_ckb/${id}`
        navigate(url)
    }


    const  isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    useEffect(() => {
        getDobList()
    }, []);

    const getDobList = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await getBanner();

            rt.map((item)=>{
                item.metaObject = JSON.parse(item.metaData)
            })
            setList(rt)
        }catch (error){
            console.error(error)
        }finally {
            store.dispatch(saveLoading(false))
        }

    }


    return <Box><SwiperBox>
        <Swiper
            slidesPerView={isMobileDevice()?1:4}
            spaceBetween={isMobileDevice()?5:10}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
        >
            {
                list.map((item,index)=>( <SwiperSlide key={index} onClick={()=>handleTo(item.clusterId,item.currency)}>
                    <div className="titleBtm">
                        <div className="titleTop">{item?.metaObject.name}</div>
                        <div>Floor:
                        <>
                            {
                                item.currency === "BTC" &&
                                <>{new BitcoinUnit(item?.floorPrice ?? 0, 'sats').to('BTC').toString()} BTC</>
                            }
                            {
                                item.currency === "CKB" && <>{formatUnit(item?.floorPrice, "ckb")} CKB</>
                            }
                        </>
                        </div>
                    </div>
                    <div className="photo">
                        <div className="aspect"/>
                        <div className="content">
                            <div className="innerImg">

                                {item?.assetType === 0 && <img
                                    src={item?.imageUrl?.indexOf("http")>-1?item?.imageUrl:DefaultImg}
                                    alt=""/>
                                }
                                {item?.assetType === 1 && <img
                                    src={DidImg}
                                    alt=""/>
                                }
                                {/*<img src={item?.imageUrl?.indexOf("http")>-1?item?.imageUrl:DefaultImg} alt="" />*/}
                            </div>
                        </div>
                    </div>
                </SwiperSlide>))
            }


        </Swiper>
    </SwiperBox>
    </Box>;
}
