import styled from "styled-components";
import BtcImg from "../assets/images/btc.svg";
import {InfoCircleOutlined, ReadOutlined} from '@ant-design/icons';
import BgImg from "../assets/images/bg.png";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import store from "../store/index.js";
import {saveLoading} from "../store/reducer.js";
import {assetsCreate, assetsInfo} from "../api/assets.js";
import {Params} from "../utils/constant.js";
import {getNet} from "../utils/wallet.js";
import {useSelector} from "react-redux";
import Buy from "../components/modal/buy.jsx";
import {BitcoinUnit} from "bitcoin-units";
import dayjs from "dayjs";
import List from "../components/modal/list.jsx";
import {testListPsbt} from "../lib/TxHelper.js";
import {App} from "antd";
import Pending from "../components/pending.jsx";
import {formatImage, handleBg, isBTC} from "../utils/general.js";
import {bitcoin, NetworkType, networkTypeToConfig} from "@rgbpp-sdk/btc";
import {useTranslation} from "react-i18next";
import Wallet from "../components/modal/wallet.jsx";
import SwitchWallet from "../components/modal/switchWallet.jsx";

const Box = styled.div`
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 32px;
    .titleInner{
        font-size: 18px;
        margin-bottom: 20px;
        padding-bottom:10px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .icon{
        color: #ff9500;
        font-size: 20px;
    }
    @media (max-width: 1274px) {
        flex-direction: column;
    }
`

const LftBox = styled.div`
    flex-shrink: 0;
    width: 450px;
    position: relative;

    @media (max-width: 1274px) {
        width: 90vw;
        margin: 0 auto;
    }
`

const BgBox = styled.div`
    //position: sticky;
    //top:120px;
    position: relative;
    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .photo{
        display: flex !important;
        overflow: hidden;
        border-radius: 10px;
        background: ${props => props.bg};
        //border-bottom: 1px solid rgba(255, 249, 242,0.2);
        //background: rgba(255,255,255,0.08);
        
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                //image-rendering: pixelated;
            }
        }
        .innerTxt{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.1);
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            align-content: center;
            justify-content: center;

            font-size: 16px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:15;
            -webkit-box-orient: vertical;
            line-height: 2em;
        }
    }
`
const RhtBox = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap:20px;
    @media (max-width: 1274px) {
        gap: 0;
        margin: 0 10px;
    }
`
const BtnBox = styled.button`
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    color: #0D0C0B;
    border: 0;
    padding: 0;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    .inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: url(${BgImg}) center 90%;
        background-size: 100% auto;
    }
    .buy{
        text-transform: uppercase;
        font-family: Roboto-Bold;
    }
    .price{
        font-family: din_bold;
        margin-right: 5px;
    }
    .sym{
        margin-bottom: -5px;
    }
    .rht{
        background: #FFFAF2;
        padding: 10px 20px;
        border-radius: 40px;
        box-shadow: inset 0 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
    }
    img{
        margin-right:10px;
    }
    @media (max-width: 1274px) {
        .inner{
            padding: 10px ;
        }
    }
`
const BtnListBox = styled.button`
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(180deg, #6EEB83 0%, #00A99D 100%);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    color: #0D0C0B;
    padding: 0;
    border: 0;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    .inner{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height:30px;
        padding: 10px 20px;
        background: url(${BgImg}) center 90%;
        background-size: 100% ;
    }
    .buy{
        text-transform: uppercase;
        font-family: Roboto-Bold;
    }
    .price{
        margin-right: 5px;
        font-weight: bold;
    }
 
    .rht{
        //background: #FFFAF2;
        padding: 10px 20px;
        border-radius: 40px;
        //box-shadow: inset 0 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    img{
        margin-right:10px;
    }
 
`

const TitleBox = styled.div`
    font-size: 16px;
    font-family: Roboto-Bold;
    word-break: break-all;
    @media (max-width: 1274px) {
        padding: 0 10px 10px; 
    }
`

const ContentBox = styled.div`
    padding: 20px;
    background: rgba(255, 149, 0, 0.1);
    border-radius: 10px;

    dl{
        margin-bottom: 20px;
        dt{
            opacity: 0.6;
            margin-bottom: 5px;
        }
        dd{
            font-size: 14px;
        }
    }

    .chainImg{
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        img{
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }
    }
    @media (max-width: 1274px) {
        dd{
            word-break: break-all;
        }
    }
`
const RhtFst = styled.div`
    padding: 20px;
    background: rgba(255, 149, 0, 0.1);
    border-radius: 10px;
    dl{
        display: flex;
        align-items: center;
        justify-content: space-between; 
        margin-bottom: 20px;
    }
    dt{
        opacity: 0.6;
    }
    .dna{
        padding-top: 20px;
        border-top: 1px solid rgba(255,255,255,0.3);
    }
    @media (max-width: 1274px) {
        dl{
            gap: 20px;
            align-items: flex-start;
        }
        dd{
            word-break: break-all;
        
        }
        dt{
            width: 30%;
            flex-shrink: 0;
        }
    }
`

const TraitBox = styled.div`
    
    &:after {
        content: '';
        display: block;
        clear: both;
    }

        dl{
        float: left;
        width: 24%;
        margin-right: 1%;
            background: rgba(255,255,255,0.05);
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        &:nth-child(4n){
            margin-right: 0;
        }
    }
    dt{
        color: #ff9500;
        font-size: 14px;
        padding-bottom: 1px;
    }
    dd{
        font-family: din_bold;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    @media (max-width: 1274px) {
        dl{
            width: 32%;
            margin-right: 2%;
            &:nth-child(4n){
                margin-right: 2%;
            }
            &:nth-child(3n){
                margin-right: 0;
            }
        }
    }
`

const BorderBox = styled.div`
    padding: 30px 20px 10px;
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    .title{
        position: absolute;
        left: 20px;
        top: -10px;
        font-size: 18px;
        background:#090808;
        padding: 0 20px;
    }
    @media (max-width: 1274px) {
        margin-top: 40px;
        padding: 30px 10px 10px;
    }
`
const TopBox = styled.div`
    dl{
        margin-right: 1%;
        background: rgba(255,255,255,0.05);
        border-radius: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 10px;
        dt{
            color: #ff9500;
            font-size: 14px;
            text-transform: capitalize;
        }
        dd{
            font-family: din_bold;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
`

const BtnPSN = styled.div`
`


export default function ItemDetail(){
    const {t } = useTranslation();
    const {id} = useParams()
    const [detail,setDetail] = useState(null)
    const [net,setNet] = useState("testnet");
    const type = useSelector(store => store.type)
    const [show,setShow] = useState(false);
    const location = useLocation();
    const [showMy, setShowMy] = useState(false);
    const [showList,setShowList] = useState(false);
    const [traits,setTraits] = useState([])
    const [prev,setPrev] = useState([])
    const [dna,setDna] = useState('')
    const account = useSelector(store => store.account);
    const {  notification } = App.useApp();

    const [showChain,setShowChain] = useState(false);
    const walletList = useSelector(store => store.walletList);
    const [showSwitch,setShowSwitch] = useState(false);

    useEffect(() => {
        if(location.pathname.indexOf("my")>-1){
            setShowMy(true)
        }else{
            setShowMy(false)
        }

    }, [location.pathname]);


    useEffect(()=>{
        if(!id )return;
        getAssets()
        getNetwork()
    },[id,type])

    const getNetwork = async () =>{
        let rt = await getNet(type)
        setNet(rt)
    }


    const getAssets = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await assetsInfo(id)
            setDetail(rt)

            let rawData = JSON.parse(rt.metadata.raw)
            let traitsBox = JSON.parse(rawData?.render_output);
            setDna(rawData?.dob_content?.dna)

            let arr = []
            let arrPrev = []

            traitsBox.map((item)=>{
                if(item?.name.startsWith("prev")){
                    arrPrev.push(item)
                }else if(item?.name!=="IMAGE"){
                    arr.push(item)
                }

            })

            console.log("traitsBox",traitsBox)
            setTraits(arr)
            setPrev(arrPrev)

        }catch (e) {
            console.error("assetsInfo",e)
            notification.error({
                message: 'Fetch asset info failed!',
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }

    const ModalClose = () =>{
        setShow(false)
        // window.location.reload()
    }

    const handleShow = (e) =>{
        e.stopPropagation();
        setShow(true)
    }

    const handleShowList = (e) =>{
        e.stopPropagation();
        setShowList(true)
    }
    const ModalCloseList = () =>{
        setShowList(false)
        // window.location.reload()
    }

    const handleList = async(inputValue,item,serviceFee) =>{
        let price = new BitcoinUnit(inputValue ?? 0, 'BTC').to('sats').getValue() ;

        store.dispatch(saveLoading(true));
        try{
            let psbtHex = await testListPsbt(detail,price);

            if(!psbtHex)return;

            //<<<<< check tapKeySig & partialSig in input[1]
            let network = await getNet(type)
            const networkType = network === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;
            const cfg = networkTypeToConfig(networkType);


            // check tapKeySig & partialSig in input[1]
            let checkSign =  bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })

            if(!checkSign.data.inputs[1].tapKeySig && !checkSign.data.inputs[1].partialSig){
                notification.error({
                    message: 'Invalid Signature!',
                    description:"Missing tapKeySig or partialSig! Please upgrade wallet."
                });
                saveLoading(false)
                return;
            }
            //>>>>>

            let  obj= {
                "asset_id": item.asset_id,
                "price": price,
                "amount": 1, // opt, default 1
                "notional": price * 1,
                "currency": "sats",
                "service_fee_rate": serviceFee?.taker_fee,
                "service_fee": Math.ceil(price * serviceFee?.taker_fee),
                "psbt": psbtHex
            }
            let rt = await assetsCreate([obj]);
            if(rt?.length){
                notification.success({
                    message: 'List asset successful!',
                });
                getAssets()
            }else{
                notification.error({
                    message: 'List asset failed!',
                    description: 'Return invalid data!'
                });
            }

        }catch (e) {
            console.log("handleList",e)
            notification.error({
                message: 'List asset failed!',
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            ModalClose && ModalClose()
            ModalCloseList && ModalCloseList()
            store.dispatch(saveLoading(false));
        }

    }

    const handleChain = (e,chain) =>{
        console.log("handleChain",e)
        e.stopPropagation();
        console.log(walletList)
        if(walletList.btc){
            setShowSwitch(true)
        }else{
            setShowChain(true)
        }

    }

    const handleChainClose = () =>{
        setShowChain(false)
        setShowSwitch(false)
    }


    return  <Box>


        <LftBox>
            <BgBox bg={handleBg(detail)}>

                {
                    detail?.owner_address === "1BitcoinEaterAddressDontSendf59kuE" &&<div className="listedTag">
                        <span>Burned</span>
                    </div>
                }
                {
                    !detail?.is_pending &&!!detail?.order &&  detail?.owner_address !== "1BitcoinEaterAddressDontSendf59kuE"&& <div className="listedTag">
                        {t("detail.Listed")}
                    </div>
                }


                {
                    detail?.is_pending && detail?.owner_address !== "1BitcoinEaterAddressDontSendf59kuE"&& <div className="listedTag">
                        <Pending /><span>{t("detail.Pending")}</span>
                    </div>
                }

                <div className="photo">
                    <div className="aspect"/>
                    <div className="content">
                        {
                            detail?.media_info?.content_type?.indexOf("image") > -1 && <div className="innerImg">
                                <img
                                    // src={detail?.media_info?.url}
                                    src={formatImage(detail)}
                                    alt=""/>
                            </div>
                        }
                        {
                            detail?.media_info?.content_type?.indexOf("text") > -1 && <div className="innerTxt">
                                {detail?.media_info?.content_data}
                            </div>
                        }
                    </div>
                </div>

                <BtnPSN>
                {
                    showMy&& isBTC(type) &&  <BtnListBox onClick={(e)=>handleShowList(e)} disabled={detail?.order || detail?.is_pending  || !isBTC(type)}>
                        <div className="inner">
                            <span className="buy">{t("detail.ListNow")}</span>
                            {/*<div className="rht">*/}
                            {/*    /!*<img src={BtcImg} alt=""/>*!/*/}
                            {/*    <span className="price"><RiseOutlined /></span>*/}
                            {/*    /!*<span className="sym">BTC</span>*!/*/}
                            {/*</div>*/}
                        </div>
                    </BtnListBox>
                }
                {
                    !showMy&& isBTC(type) && !!detail?.order &&  <BtnBox onClick={(e)=>handleShow(e)}  disabled={!detail?.order || detail?.owner_address === account || detail?.is_pending || !isBTC(type)}>
                        <div className="inner">
                            <span className="buy">{t("detail.BuyNow")}</span>
                            <div className="rht">
                                <img src={BtcImg} alt=""/>
                                <span className="price">{new BitcoinUnit(detail?.price ?? 0, 'sats').to('BTC').toString()}</span>
                                <span className="sym">BTC</span>
                            </div>
                        </div>
                    </BtnBox>
                }
                {
                    !showMy&& !detail?.order&& isBTC(type) &&  <BtnBox  disabled>
                        <div className="inner">
                            <span className="buy">{t("collection.MakeOffer")}</span>
                            <div className="rht">
                                <img src={BtcImg} alt=""/>
                                <span className="price">{new BitcoinUnit(detail?.price ?? 0, 'sats').to('BTC').toString()}</span>
                                <span className="sym">BTC</span>
                            </div>
                        </div>
                    </BtnBox>
                }

                    {
                        !isBTC(type) &&  <BtnBox onClick={(e)=>handleChain(e)}  >
                            <div className="inner">
                                <span className="buy">{t('detail.BuyNow')}</span>
                                <div className="rht">
                                    <img src={BtcImg} alt=""/>
                                    <span
                                        className="price">{new BitcoinUnit(detail?.price ?? 0, 'sats').to('BTC').toString()}</span>
                                    <span className="sym">BTC</span>
                                </div>
                            </div>
                        </BtnBox>
                    }
                </BtnPSN>
            </BgBox>
        </LftBox>
        <RhtBox>
        <TitleBox> {detail?.display_name} #{detail?.dob_id} </TitleBox>
            <RhtFst>
                <div className="titleInner">
                    <InfoCircleOutlined className="icon"/>
                    {t("detail.INFO")}
                </div>
                <dl>
                    <dt> {t("detail.Standard")}</dt>
                    <dd>#{detail?.dob_standard}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ID")}</dt>
                    <dd>{detail?.dob_id}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.DNA")}</dt>
                    <dd>{dna}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Owner")}</dt>
                    <dd><a href={`${Params[net]?.scanUrl}address/${detail?.owner_address}`} rel="noreferrer"
                           target="_blank">{detail?.owner_address}</a></dd>
                </dl>

                {/* <dl>
                    <dt>Cluster ID</dt>
                    <dd>{detail?.cluster_hash_id}</dd>
                </dl>
                <dl>
                    <dt>Cluster Name</dt>
                    <dd>{detail?.cluster_name}</dd>
                </dl> */}

                <dl>
                    <dt>{t("detail.ContentType")}</dt>
                    <dd>{detail?.media_info?.content_type}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Transaction")}</dt>
                    <dd>{detail?.latest_tx_id}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Timestamp")}</dt>
                    <dd>{dayjs(Number(detail?.timestamp)).format('YYYY-MM-DD HH:mm')}</dd>
                </dl>
            </RhtFst>
            <BorderBox>
                <div className="title">
                    {t("detail.DESCRIPTION")}
                </div>

                    {
                        !!prev.length && <TopBox>
                            {
                                prev?.map((item, index) => (<dl key={index}>
                                    <dt>{item?.name}</dt>
                                    <dd>{item?.traits[0].Number ||item?.traits[0].String}</dd>
                                </dl>))
                            }
                        </TopBox>
                    }
                {
                    !!traits.length && <TraitBox>
                        {
                            traits?.map((item, index) => (<dl key={index}>
                                <dt>{item?.name}</dt>
                                <dd>{item?.traits[0].Number ||item?.traits[0].String}</dd>
                            </dl>))
                        }
                    </TraitBox>
                }
            </BorderBox>


            <ContentBox>
                <div className="titleInner">
                    <ReadOutlined className="icon"/>
                    {t("detail.About")}
                </div>
                <dl>
                    <dt>{t("detail.ClusterID")}</dt>
                    <dd>{detail?.cluster_hash_id}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterName")}</dt>
                    <dd>{detail?.cluster_name}</dd>
                </dl>
                <dl>
                    <dt>{t("info.chain")}</dt>
                    <dd className="chainImg">
                        <img src={BtcImg} alt=""/>
                        <span>BTC</span>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterDescription")}</dt>
                    <dd>{detail?.cluster_desc}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterTimestamp")}</dt>
                    <dd>{detail?.cluster_timestamp ? dayjs(Number(detail?.cluster_timestamp)).format('YYYY-MM-DD HH:mm') : "--"}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Blockheight")}</dt>
                    <dd>#{detail?.cluster_blockheight}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterCreator")}</dt>
                    <dd><a href={`${Params[net]?.scanUrl}address/${detail?.cluster_creator}`} rel="noreferrer"
                           target="_blank">{detail?.cluster_creator}</a></dd>
                </dl>

            </ContentBox>
        </RhtBox>
        {
            show && <Buy ModalClose={ModalClose} selectedItem={detail} getAssets={getAssets}/>
        }
        {
            showList && <List ModalClose={ModalCloseList} item={detail} handleList={handleList}/>
        }


        {
            showChain && <Wallet handleClose={handleChainClose} chain="btc"/>
        }

        {
            showSwitch &&   <SwitchWallet handleClose={handleChainClose} handleNext={handleChain} switchTochain="btc"  />
        }
    </Box>
}
