import JoyidImg from "../../assets/images/Joyid.svg";
import {initConfig, requestAccounts, signMessage} from '@joyid/bitcoin';
import store from "../../store";
import {saveAccount, saveLoading, saveSignature, saveType, saveWalletList} from "../../store/reducer";
import useLogin from "../../hook/useLogin.jsx";
import {SIG_WORD} from "../../utils/constant.js";
import {App} from "antd";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function Joyid({handleClose}){
    const {  notification } = App.useApp();
    const {t } = useTranslation();
    const walletList = useSelector(store => store.walletList);

    useLogin(handleClose,"joyid","p2tr");

    const onConnect = async() =>{

        initConfig({
            name: "Dobby",
            logo: `${window.location.origin}/images/newlogo.png`,
            requestAddressType:"p2tr",
            joyidAppURL: import.meta.env.VITE_CHAIN==="testnet"?"https://testnet.joyid.dev":"https://app.joy.id",
        });
        store.dispatch(saveLoading(true));
        try {
            const accounts = await requestAccounts();

            const signature = await signMessage(SIG_WORD);


            let btc = {
                account : accounts[0],
                type:"joyid",
                signature
            }

            store.dispatch(saveWalletList({
                btc:btc,
                ckb:walletList.ckb
            }));

            store.dispatch(saveSignature(signature));
            store.dispatch(saveAccount(accounts[0]));
            store.dispatch(saveType("joyid"));
            localStorage.setItem("joyidType","p2tr");

            // handleClose()
        } catch (error) {
            console.error(error);

            notification.error({
                message:  t("error.Joyid"),
                description:error.message || error.reason
            });
            store.dispatch(saveLoading(false));
        }
    }

    return <dl onClick={() => onConnect()}>
        <dt>
            <img src={JoyidImg} alt=""/>
        </dt>
        <dd>JoyID (Taproot)</dd>
    </dl>
}
