import styled from "styled-components";
import Banner from "../components/home/banner.jsx";
// import List from "../components/home/list.jsx";
import Tablelist from "../components/home/tablelist.jsx";

const Box = styled.div`
    color: #fff;

`
export default function Home(){
    return <Box>

        <Banner />
        <Tablelist />
    </Box>
}
