import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {CloseOutlined} from "@ant-design/icons";
import {FormatAddress, handleBg, setUtxo} from "../../utils/general.js";
import {useSelector} from "react-redux";
import {MoveLeft} from "lucide-react";
import DidImg from "../../assets/images/did.svg";
import {formatUnit} from "@ckb-lumos/bi";
import {getNet} from "../../utils/wallet.js";
import {CKB_networkList, DID_CONTRACT} from "../../utils/constant.js";
import {BI, CellCollector, Indexer, RPC} from "@ckb-lumos/lumos";
import {build_transfer_dob} from "../../utils/transfer.js";
import {sendRawTx} from "../../utils/ckbUtils.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {App} from "antd";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import useCCCSigner from "../../hook/useCCCSigner.jsx";
import {getFeeRate} from "../../api/ckb.js";

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .lft{
        flex-grow: 1;

    }
    .icon{
        cursor: pointer;
    }
    
`
const Tips = styled.div`
    font-size: 12px;
    opacity: 0.6;
    padding-top: 10px;
    margin-bottom: 10px;
`



const FirstLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .innerImg{
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        background: ${props => props.bg};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            //image-rendering: pixelated;
        }
    }
    .innerTxt{
        background: #000;
        width: 80px;
        height: 80px;
        font-size: 12px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap:break-word;
        -webkit-line-clamp:3;
        -webkit-box-orient: vertical;
    }
    .title{
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 400px;
    }
    .id{
        //color: #ff9500;
        font-family:din_regular;
    }
    .capacity{
        display: flex;
        gap: 5px;
    }
    @media (max-width: 1274px) {
        .title{
            width: 58vw;
            word-break: break-all;
        }
    }
`

const BtmBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 20px;
    .price{
        color: #ff9500;
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button{
        width: 49%;
        height: 40px;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        &:disabled{
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .confirm{
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
    }
    .cancel{
        border:1px solid #FF9500;
        color: #ff9500;
        background: none;
    }
`
const FeeLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .custom{
        border:1px solid #FF9500;
        color: #ff9500;
        padding:5px 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .num{
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
        padding-left: 5px;
    }
    .rhtNum{
        color: #ff9500;
    }
`

const CustomLine = styled.div`
    .fst{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        width: 49%;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover,&.active{
            cursor: pointer;
            border: 1px solid #ff9500;
        }
     
    }
    .none{
        display: none;
    }
    .title{
        font-size: 12px;
        opacity: 0.6;
    }
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-right: 10px;
    }
    .sym{
        font-size: 12px;
        font-weight: bold;
    }
    @media (max-width: 1274px) {
        .lBtm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`

const SliderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .lft{
        flex-grow: 1;
    }
    .rht{
        flex-shrink: 0;
        width: 80px;
    }
`

const Tips2 = styled.div`
    color: #ff9500;
    font-size: 16px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 5px;
`
const SendTo = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`


const BtmBoxBtm = styled.div`
    word-break: break-all;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 10px;
    color: #ff9500;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
 
`

export default function SendStep2_ckb ({handleClose,selectedItem,handleStep,address,refresh}){
    const {t } = useTranslation();
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)
    const {  notification } = App.useApp();

    const {signer} = useCCCSigner(type);

    const transfer = async() =>{
        store.dispatch(saveLoading(true))
        try{

            const net = await getNet()
            const rpcUrl = CKB_networkList[net].node
            let rt =  await getFeeRate();

            const {median} = rt;

            let feeRate = BI.from(median).toString()

            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);


            const sporeConfig = net==="testnet"? predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

            const version = net === 'testnet'?"preview":"latest";
            const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);


            let selectItemObj;

            if(selectedItem.assetType === 0){
                const {script:{codeHash,hashType}} = sporeTypeScript;

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: selectedItem?.dobsId,
                        codeHash:codeHash,
                        hashType:hashType,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }else if(selectedItem.assetType === 1){


                const {CODE_HASH,HASH_TYPE} = DID_CONTRACT[net]

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: selectedItem?.dobsId,
                        codeHash:CODE_HASH,
                        hashType:HASH_TYPE,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }

            let txSkeletonObj = await build_transfer_dob(feeRate,[selectItemObj],account,address,type,selectedItem.assetType)
            const refreshFuc = () =>{
                console.log(txSkeletonObj)
            }


            let result = await sendRawTx(txSkeletonObj,type,account,false,refresh?refresh:refreshFuc,signer)
            setUtxo(txSkeletonObj);

            notification.success({
                message: t("modal.SendSuccess"),
                description: result
            });
            setTimeout(()=>{
                handleClose()
            },1000)


        }catch(e){
            console.error(e)

            notification.error({
                message: t("modal.SendFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false))
        }

    }

    return <div>
        <TitleBox>

            <div onClick={() => handleStep(1)} className="lftImg"><MoveLeft size={24}/></div>
            <CloseOutlined className="icon" onClick={() => handleClose()}/>
        </TitleBox>
        <div className="lft"> {t("detail.Send")} {t("modal.Confirmation")}</div>
        <Tips>{t("modal.confirmTips")}:</Tips>

        <FirstLine bg={handleBg(selectedItem)}>
            {
                selectedItem.assetType === 0 && <div className="innerImg">
                    <img
                        src={selectedItem?.spore?.url || selectedItem?.spore?.imgUrl }
                        alt=""/>
                </div>
            }
            {
                selectedItem.assetType === 1 && <div className="innerImg">
                    <img
                        src={DidImg}
                        alt=""/>
                </div>
            }
            <div className="rht">
                {
                    selectedItem.assetType === 1 &&
                    <div className="title">{selectedItem?.spore?.DidName?.split(".")[0]}.bit</div>

                }
                <div className="id">ID: #{FormatAddress(selectedItem?.dobsId)}</div>
                <div className="capacity">
                    <dt className="rhtNum">Capacity:</dt>
                    <dd>
                            <span
                                className="num rhtNum">{selectedItem?.capacity ? formatUnit(selectedItem?.capacity, "ckb") : 0}</span>&nbsp;
                        <span className="sym">CKB</span>
                    </dd>
                </div>
            </div>
        </FirstLine>

        <SendTo>
            <div>{t("modal.SendTo")}</div>
            <BtmBoxBtm>
                {address}
            </BtmBoxBtm>
        </SendTo>
        <ButtonGroup>
            <button className="cancel" onClick={() => handleClose()}>{t("modal.Cancel")}</button>
            <button className="confirm" disabled={!account} onClick={() => transfer()}>
                {t("modal.Confirm")}
                {/*{*/}
                {/*    disableTx &&  <LoadingBtn />*/}
                {/*}*/}

            </button>
        </ButtonGroup>
    </div>
}
