import styled from "styled-components";
import {useEffect, useState} from "react";
import {collectionsDetail, getFloor} from "../../api/assets.js";
import {useParams} from "react-router-dom";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {addCommasToNumber, checkFileExtension} from "../../utils/general.js";
import {BitcoinUnit} from "bitcoin-units";
import {App} from "antd";
import {useTranslation} from "react-i18next";
import DefaultImg from "../../assets/images/test_logo.png";


const Box = styled.div`
    display: flex;
    align-items: stretch;
    gap: 20px;
    box-sizing: border-box;
    width: 100%;
    
`
const LftBox = styled.div`
    width: 200px;
    height: 200px;
    margin-left: 10px;
    flex-shrink: 0;
    img{
        width: 200px;
        height: 200px;
        border-radius: 10px;
        //image-rendering: pixelated;
    }
    .videoBox{
        width: 200px;
        height: 200px;
        border-radius: 10px;
        overflow: hidden;
    }
    video{
        width: 200px;
        height: 200px;
        object-fit:fill;
    }
    @media (max-width: 1274px) {
        display: flex;
        justify-content: center;
        padding-left: 10px;
        width: 100px;
        height: 100px;
        margin-top: 25px;
        img{
            width: 100px;
            height: 100px;
        }
        .videoBox{
            width: 100px;
            height: 100px;
            border-radius: 10px;
            overflow: hidden;
        }
        video{
            width: 100%;
            height: 100%;
            object-fit:fill;
        }
    }
`
const RhtBox = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: 10px;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 10px;
    
    @media (max-width: 1274px) {
        padding-top: 20px;
    }
`
const TitleBox = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom:10px;
    .titleLft{
        font-size: 20px;
    }
    .tips{
        color: #ff9500;
    }
    .rhtTop{
        color: #ff9500;
        font-size: 18px;
        gap: 5px;
        display: flex;
        align-items: flex-start;
        .num2{
            font-family: din_bold;
        
        }
    }
    @media (max-width: 1274px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        .titleLft{
            font-size: 16px;
            
        }
        .rhtTop{
            font-size: 14px;
        }
    }
`
const BtmBox = styled.ul`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding-top: 10px;
    gap:15px;
    li{
        width: 100%;
        background: rgba(255,255,255,0.06);
        background: linear-gradient( to right bottom, #ffffff25 20%, #ffffff15 30%);
        border-radius: 5px;
        box-sizing: border-box;
        padding: 10px;
        
    }
    .num{
        font-size: 19px;
        font-family: din_bold;
        margin-right:5px;
    }
    .sym{
        font-size: 12px;
        font-family:Roboto-Regular;
        margin-right: 5px;

        span{
            opacity: 0.5;
        }
        .btc{
            opacity: 1;
            padding-left: 5px;
            color: #ff9500;
        }
    }
    .num2{
        font-size: 16px;
        margin-right:5px;
        color: #ff9500;
        font-weight: bold;
    }
    @media (max-width: 1274px) {
        margin-left:calc(-36vw + 10px) ;
        flex-wrap: wrap;
        width: calc(100% + 34vw - 10px);
        gap: 0;
        li{
            width:49%;
            flex-shrink: 0;
            background: transparent;
        }
    }
`

const Tips = styled.div`
    font-size: 12px;
    opacity: 0.8;
    margin-bottom: 10px;

    word-break: break-word;
    box-sizing: border-box;
`
export default function Info(){

    const {t } = useTranslation();
    const {id} = useParams()
    const [detail,setDetail] = useState(null);
    const [floor,setFloor] = useState(0)
    const [type,setType]= useState('')
    const {  notification } = App.useApp();

    useEffect(()=>{
        if(!id )return;
        getInfo()
        getFloorprice()
    },[id])

    useEffect(()=>{
        if(!detail)return;
        let type = checkFileExtension(detail?.avatar);
        setType(type)
    },[detail])



    const getFloorprice = async() =>{
        let rt = await getFloor(id)
        setFloor(rt)

    }

    const getInfo = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await collectionsDetail(id)
            setDetail(rt)

        }catch (e) {
            console.error("collectionsDetail",e)

            notification.error({
                message:  t("error.Network"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }





    return <Box>
        {/*<List />*/}
        <LftBox>
            {/*{*/}
            {/*    type === "image" && <img src={detail?.avatar} alt=""/>*/}
            {/*}*/}

            <img
                src={detail?.avatar.indexOf("http") > -1 ? detail?.avatar : DefaultImg}
                alt=""/>

            {/*{*/}
            {/*    type === "video" && <div className="videoBox">*/}
            {/*        <video autoPlay muted loop id="myVideo">*/}
            {/*            <source src={detail?.avatar} type="video/mp4"/>*/}
            {/*        </video>*/}
            {/*    </div>*/}
            {/*}*/}


        </LftBox>
        <RhtBox>
            <div>
                <TitleBox>
                    <div className="titleLft">{detail?.name}</div>
                    {/*<div className="tips">This collection was submitted by @Nervape </div>*/}

                    <div className="rhtTop">
                        <span className="sym"> {t("info.FloorPrice")}</span>
                        <span
                            className="num2">{new BitcoinUnit(floor?.floor_price ?? 0, 'sats').to('BTC').toString()} BTC </span>
                    </div>
                </TitleBox>
                <Tips>{detail?.description}</Tips>
            </div>

            <BtmBox>
                <li>
                    <div className="sym"><span>{t("info.24HVolume")} </span><span className="btc">(BTC)</span></div>
                    {/*<div className="num">{addCommasToNumber(detail?.vol_24h)}</div>*/}
                    <div className="num">{Number(detail?.vol_24h) ? new BitcoinUnit(detail?.vol_24h ?? 0, 'sats').to('BTC').toString():0}</div>

                </li>
                <li>
                    <div className="sym"><span>{t("info.TotalVolume")}</span><span className="btc">(BTC)</span></div>
                    {/*<div className="num">{addCommasToNumber(detail?.vol_total)} </div>*/}
                    <div
                        className="num">{Number(detail?.vol_total)?new BitcoinUnit(detail?.vol_total ?? 0, 'sats').to('BTC').toString():0}</div>
                </li>
                <li>
                    <div className="sym"><span>{t("info.24HSales")}</span></div>
                    <div className="num">{addCommasToNumber(detail?.sales_24h)} </div>
                </li>
                <li>
                    <div className="sym"><span>{t("info.TotalSales")}</span></div>
                    <div className="num">{addCommasToNumber(detail?.sales_total)} </div>
                </li>
                <li>
                    <div className="sym"><span>{t("info.MarketCap")}</span><span className="btc">(BTC)</span></div>
                    <div className="num">{Number(detail?.market_cap)?new BitcoinUnit(detail?.market_cap ?? 0, 'sats').to('BTC').toString():0} </div>
                </li>
                <li>
                    <div className="sym"><span>{t("info.Holders")}</span></div>
                    <div className="num">{addCommasToNumber(detail?.holders)} </div>
                </li>

            </BtmBox>
        </RhtBox>
    </Box>
}
