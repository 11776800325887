import { getConfig, getPublicKey, signPsbt } from '@joyid/bitcoin';
import { BitcoinUnit } from "bitcoin-units";
import axios from "axios";
import BigNumber from "bignumber.js";
import { AddressType, getAddressType } from '@rgbpp-sdk/btc';

export const getNet = async (walletType) => {
    // let network;
    // let config;
    // switch (walletType) {
    //     case "unisat":
    //         network = await window?.unisat?.getNetwork();
    //         break;
    //     case "joyid":
    //     //  config = await getConfig();
    //     // if(config.joyidAppURL.indexOf("testnet")>-1){
    //     //     network = "testnet"
    //     // }else{
    //     //     network = "livenet"
    //     // }
    //     // break;
    //     case "okx":
    //         network = import.meta.env.VITE_CHAIN;
    //         break;
    // }
    //
    // return network;
    return  import.meta.env.VITE_CHAIN;

}


export const handlePublicKey = async (walletType, isLogin = false) => {
    let pbkey;
    switch (walletType) {
        case "unisat":
            pbkey = await window?.unisat?.getPublicKey();
            break;
        case "joyid":
            pbkey = await getPublicKey();
            break;
        case "okx":
            if (import.meta.env.VITE_CHAIN === "testnet") {
                const result = await window?.okxwallet?.bitcoinTestnet.connect()
                if (isLogin) {
                    pbkey = result.compressedPublicKey
                } else {
                    pbkey = result.publicKey
                }
            } else {
                // pbkey = await window?.okxwallet.bitcoin.getPublicKey();
                const result = await window?.okxwallet.bitcoin.connect()

                if (isLogin) {
                    pbkey = result.compressedPublicKey;
                } else {
                    pbkey = result.publicKey;
                }
            }


            break;
    }

    return pbkey;
}

const getBalance = async (account) => {

    const isMainnet = import.meta.env.VITE_CHAIN === "livenet"
    let rt = await axios.get(`https://mempool.space${isMainnet ? "" : "/testnet"}/api/address/${account}`);

    const { chain_stats, mempool_stats } = rt.data;

    const inputSum = new BigNumber(chain_stats.funded_txo_sum).plus(mempool_stats.funded_txo_sum)
    const outputSum = new BigNumber(chain_stats.spent_txo_sum).plus(mempool_stats.spent_txo_sum)
    const total = inputSum.minus(outputSum);

    const confirmed = new BigNumber(chain_stats.funded_txo_sum).minus(chain_stats.spent_txo_sum)
    const unconfirmed = new BigNumber(mempool_stats.funded_txo_sum).minus(mempool_stats.spent_txo_sum)

    return {total:total.toString(),confirmed:confirmed.toString(),unconfirmed:unconfirmed.toString()}


}

export const handleBalance = async (walletType, account) => {
    let bl;
    let rt;

    // switch (walletType) {
    //     case "unisat":
    //         rt = await window?.unisat?.getBalance();
    //         break;
    //     case "joyid":
    //         rt = await getBalance(account)
    //         break;
    //     case "okx":
    //         rt = await getBalance(account)
    //         // if (import.meta.env.VITE_CHAIN === "testnet") {
    //         //     rt = await getBalance(account)
    //         // } else {
    //         //     rt = await window?.okxwallet.bitcoin.getBalance();
    //         // }
    //         break;
    // }

    rt = await getBalance(account)



    // bl = new BitcoinUnit((rt?.total || rt) ?? 0, 'sats').to('BTC').getValue();


    return {
        total:new BitcoinUnit((rt?.total) ?? 0, 'sats').to('BTC').getValue(),
        confirmed:new BitcoinUnit((rt?.confirmed) ?? 0, 'sats').to('BTC').getValue(),
        unconfirmed:new BitcoinUnit((rt?.unconfirmed) ?? 0, 'sats').to('BTC').getValue(),

    };
}

export const handleSignPsbt = async (hex, walletType, options) => {
    let rt;
    switch (walletType) {
        case "unisat":
            rt = await window?.unisat?.signPsbt(hex, options);
            break;
        case "joyid":
            rt = await signPsbt(hex, options);
            break;
        case "okx":
            if (import.meta.env.VITE_CHAIN === "testnet") {
                rt = await window?.okxwallet.bitcoinTestnet.signPsbt(hex, options);
            } else {
                rt = await window?.okxwallet.bitcoin.signPsbt(hex, options);
            }
            break;
    }



    return rt;
}

