import styled from "styled-components";
import { FilterOutlined,ReloadOutlined,LineChartOutlined,SearchOutlined } from '@ant-design/icons';
import {Input, Select, Pagination, App} from 'antd';
import Item from "./item.jsx";
import {useEffect, useState} from "react";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import {assetsCreate, assetsMy, postUtxoItem} from "../../api/assets.js";
import axios from "axios";
import Loading from "../loading.jsx";
import {BitcoinUnit} from "bitcoin-units";
import List from "../modal/list.jsx";
import {testListPsbt} from "../../lib/TxHelper.js";
import {bitcoin, NetworkType, networkTypeToConfig} from "@rgbpp-sdk/btc";
import {getNet} from "../../utils/wallet.js";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {isBTC} from "../../utils/general.js";
import SendModal from "../modal/Send_btc.jsx";


const Box = styled.div`
    margin-top: 10px;
    .ant-select .ant-select-arrow{
        color: rgba(255,255,255,0.3);
    }
`

const PageBox = styled.div`
    margin:40px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
const FilterBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
const IconBox = styled.div`
    border: 1px solid #FFFAF2;
    height: 40px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    opacity: 0.5;
    padding: 0 10px;
    gap: 10px;
    background: #141414;
    span{
        font-size: 14px;
    }
    &.active{
        color: #ff9500;
        opacity: 1;
        border: 1px solid #ff9500;
    }
`

const RhtBox = styled.div`
    display: flex;
    justify-content: flex-end;
    gap:10px;
`

const UlBox = styled.ul`
    width: 100%;
    margin-top: 20px;
    &:after{content:"";display:block;clear:both;}
    li{
        width: calc((100% - 80px)/5);
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        &:nth-child(5n){
            margin-right: 0;
        }
    }

    @media (max-width: 1274px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        box-sizing: border-box;
        li{
            width: 48%;
            margin-right: 0;

        }

    }
`

export default function Collections(){
    const {t } = useTranslation();
    const [page,setPage] = useState(0)
    const [total,setTotal] = useState(0)
    const [pageSize,setPageSize] = useState(20)
    const [loading,setLoading] = useState(false);
    const [selectItem,setSelectItem] = useState(false);
    const {  notification } = App.useApp();
    const type = useSelector(store => store.type);
    const token = useSelector(store => store.token);
    const [showSend,setShowSend] = useState(false);

    const [list,setList] = useState([])

    useEffect(() => {

        if(isBTC(type) && token){
            getList()
        }

    }, [type,token]);

    const getList = async(e) =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await assetsMy((e>1?e-1:0) * pageSize,pageSize)

            const {size,data,total,offset} = rt
            setPageSize(size)
            setTotal(total)
            let page = Math.ceil(offset/size)
            setPage(page + 1)
            setList(data)

        }catch (e) {
            console.error("get my assets error",e)
            notification.error({
                message: t("error.myAssets"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false))
        }

    }

    const postUtxo = async() =>{
        let rt =  await postUtxoItem("/datasrv/import_my_utxo_data");
        console.log(rt)
    }

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };


    const handleList = async(inputValue,item,serviceFee) =>{
        let price = new BitcoinUnit(inputValue ?? 0, 'BTC').to('sats').getValue() ;

        setLoading(true)
        try{
            let psbtHex = await testListPsbt(selectItem,price);


            if(!psbtHex)return;

            //<<<<< check tapKeySig & partialSig in input[1]
            let network = await getNet(type)
            const networkType = network === "testnet" ? NetworkType.TESTNET : NetworkType.MAINNET;
            const cfg = networkTypeToConfig(networkType);


            // check tapKeySig & partialSig in input[1]
            let checkSign =  bitcoin.Psbt.fromHex(psbtHex, { network: cfg.network })

            if(!checkSign.data.inputs[1].tapKeySig && !checkSign.data.inputs[1].partialSig){
                notification.error({
                    message:  t("modal.InvalidSig"),
                    description:t("modal.updateInfo")
                });
                setLoading(false)
                return;
            }
            //>>>>>



            let  obj= {
                "asset_id": item.asset_id,
                "price": price,
                "amount": 1, // opt, default 1
                "notional": price * 1,
                "currency": "sats",
                "service_fee_rate": serviceFee?.taker_fee,
                "service_fee": Math.ceil(price * serviceFee?.taker_fee),
                "psbt": psbtHex
            }
            let rt = await assetsCreate([obj]);
            if(rt?.length){
                notification.success({
                    message: t("error.listSuccess"),
                });

            }else{
                notification.error({
                    message: t("error.listFailed")
                });
                setLoading(false)
                throw new Error(t("error.listFailed"))
            }

        }catch (e) {
            console.log("handleList",e)
            notification.error({
                message: t("error.listFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            ModalClose()
            setLoading(false)
            getList()
        }

    }

    const [show,setShow] = useState(false);

    const handleShow = (e,item) =>{
        e.stopPropagation();
        setShow(true)
        setSelectItem(item)
    }

    const ModalClose = () =>{
        setShow(false)
    }

    const handlePage = (e) =>{
        setPage(e)
        getList(e)
    }

    const handleCloseSend = () =>{
        setShowSend(false)
    }

    const handleSendShow = (e,item) =>{
        e.stopPropagation();
        setShowSend(true)
        setSelectItem(item)
    }

    return <Box>



        {/*<div>*/}
        {/*    <button onClick={() => postUtxo()}>Post</button>*/}
        {/*</div>*/}
        {
            loading &&  <Loading />
        }
        <UlBox>
            {
                list.map((item, index) => (<li key={`collection_${index}`}>
                    <Item item={item} handleList={handleList} handleShow={handleShow} ModalClose={ModalClose} show={show} handleSendShow={handleSendShow} />
                </li>))
            }

        </UlBox>
        {
            total >= pageSize &&   <PageBox>
                <Pagination defaultCurrent={page} total={total} pageSize={pageSize} onChange={handlePage}  showSizeChanger={false}  />
            </PageBox>
        }

        {
            show && <List ModalClose={ModalClose} item={selectItem} handleList={handleList}/>
        }
        {
            showSend && <SendModal selectItem={selectItem} handleClose={handleCloseSend}  />
        }
    </Box>
}
