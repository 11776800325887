export default {
  account: null,
  secondary: null,
  type: null,
  walletList:{
    ckb:null,
    btc:null
  },
  token:null,
  signature:null,
  loading: null,
  price:0,
  serviceFee:null,
  tokenExp:null,
  infoCKb:0,
  joyidInfo:null,
  didHash:null,
  utxo:[],
};
