import styled from "styled-components";
import TelegramImg from "../../assets/images/telegram_black.svg";
import TwitterImg from "../../assets/images/twitter_black.svg";
import {QuestionCircleOutlined,CloseOutlined} from "@ant-design/icons";
import {useState} from "react";
import {Helper} from "../../utils/constant.js";


const AssistantBox = styled.div`
    position: fixed;
    right: -0;
    bottom: 100px;
    z-index: 99;
    .innerBox{
        display: flex;
        align-items: center;
        gap: 20px;

        position: absolute;
        right: -330px;
        //right: 0;
        background: #FF9500;
        transform: translateY(-50%);
        padding: 10px 20px;
        box-sizing: border-box;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width: 390px;
        transition: right 0.5s ease;
    }
    a{
        color: #0D0C0B;
    }


   
    &:hover,.active{
        .innerBox{
            right:0;
        }
    }
    li{
        display: flex;
        align-items: center;
        gap: 10px;
        color: #0D0C0B;
        .inner{

        }
        &:hover{
            cursor: pointer;
            text-decoration: underline;
            .inner{
                transition: transform 1s;
                animation: rotate 0.8s linear ;
            }
        }
    }
    img{
        width: 30px;
        aspect-ratio: auto 30 / 30;
        height: 30px;
    }
    .icon{
        font-size: 25px;
        margin-right: 5px;
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg); 
        }
        to {
            transform: rotate(360deg); 
        }
    }

    @media (max-width: 1274px) {
        .innerBox{
            width:50vw;
            justify-content: space-between;
            right: -35vw;
            .txt{
                display: none;
            }
        }
        li{
            gap: 0;
        }




    }

`

const ModalBox = styled.div`
    position: absolute;
    background: #231c15;
    padding: 20px 0 0;
    z-index: 999;
    right: 10px;
    bottom: -25px;
    width: 380px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
    overflow: hidden;
    transform: translateY(100%);
    animation: scaleIn 0.5s forwards;

    @keyframes scaleIn {
        from {
            transform: translateY(100%);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1; 
        }
    }
    @media (max-width: 1274px) {
        width:95vw;
    }

`

const FlexBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding:0 10px 20px;
    .lft{
        display: inline-block;
        flex-grow: 1;
        text-align: center;
        font-family: din_bold;
        font-size:16px;
    }
`

const InnerContent = styled.div`
    height: 40vh;
    background: #fff;
    padding: 10px 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .boxInner{
        height: 100%;
        color: #0D0C0B;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent; 
        }
    }
    dl{
        margin-bottom: 20px;
        padding: 0 20px;
    }
    dt{
        font-weight: bold;
        font-family: din_bold;
        margin-bottom: 5px;
    }
    
`


export default function Assistant() {
    const [show,setShow] = useState(false);

    const [mobileClick ,setMobileClick] = useState(false);

    const  isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    const handleMobile = () =>{

        if(isMobileDevice()){
            setMobileClick(true)
        }else{
            setMobileClick(false)
        }
    }



    const handleShow = (e) =>{
        e.stopPropagation();

        if(isMobileDevice() && !mobileClick) {
            handleMobile()
            return;
        }

        setShow(true)
        setMobileClick(false)
    }

    const handleHide = () =>{
        setShow(false)
    }


    return <AssistantBox>
        {
            show &&        <ModalBox>
                <FlexBox>
                    <span className="lft">  Help</span>
                    <CloseOutlined onClick={(e)=>handleHide(e)} />

                </FlexBox>
                <InnerContent>
                    <div className="boxInner">
                        {
                            Helper.map((item,index)=> (<dl key={index}>
                                <dt>{item.title}</dt>
                                <dd>{item.content}
                                    {
                                        !!item.link && <a href={item.link} target="_blank" rel="noreferrer">{item.link}</a>
                                    }
                                </dd>
                            </dl>))
                        }
                    </div>


                </InnerContent>
            </ModalBox>
        }
        {
            !show && <ul className={mobileClick ?"innerBox active":"innerBox"} >
                <li onClick={(e) => handleShow(e)}><QuestionCircleOutlined className="icon inner"/> <span className="txt">A question?</span></li>
                <a href="https://t.me/dobbyclub" target="_blank" rel="noopener noreferrer">
                    <li>
                        <img className="inner" src={TelegramImg} alt=""/> <span className="txt">Telegram</span></li>
                </a>
                <a href="https://x.com/MarketDobby" target="_blank" rel="noopener noreferrer">
                    <li><img className="inner" src={TwitterImg} alt=""/> <span className="txt">Twitter</span></li>
                </a>
            </ul>
        }


    </AssistantBox>
}
