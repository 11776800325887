import styled from "styled-components";
import Avatar from 'boring-avatars';
import {CopyOutlined} from "@ant-design/icons";
import {Tabs, App, Select} from "antd";
import Collections from "../components/myAssets/collections.jsx";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {handleBalance} from "../utils/wallet.js";
import {CopyToClipboard} from "react-copy-to-clipboard";
// import {notification} from 'antd';
import {useNavigate, useSearchParams} from "react-router-dom";
import {FormatAddress, isBTC} from "../utils/general.js";
import {useTranslation} from "react-i18next";
import MyOrderListBTC from "../components/myAssets/myOrderList_BTC.jsx";
import RBFModal from "../components/modal/rbf.jsx";



const Box = styled.div`
    margin-top: 40px;
    .ant-select .ant-select-arrow{
        color: rgba(255,255,255,0.3);
    }
`
const TopBanner = styled.div`
    //background: rgba(255, 255, 255, 0.1) ;
    background: linear-gradient(
            217deg,
            rgba(255, 90, 95, 0.2),
            rgba(7, 76, 255, 0.2)
    ), linear-gradient(127deg, rgba(4, 119, 56, 0.1), rgba(19, 173, 165, 0) 70.71%),
    linear-gradient(336deg, rgba(247, 186, 42, 0.1), rgba(255, 149, 0, 0) 70.71%);
    

    padding: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 20px;
    @media (max-width: 1274px) {
        margin: 0 auto 20px;
        box-sizing: border-box;
        padding: 20px;
        
    }
`

const RhtBox = styled.div`

    .title{
        font-family: Roboto-Bold;
        font-size: 18px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .tips{
        font-size: 12px;
        opacity: 0.5;
    }
    .titleMobile{
        display: none;
    }
    @media (max-width: 1274px) {
        .title{
            display: none;
        }
        .titleMobile{
            display: flex;
            font-family: Roboto-Bold;
            font-size: 16px;
            justify-content: center;
        }
 
        .iconB{
            margin-left: 5px;
        }
    }
`
const FirstLine = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    @media (max-width: 1274px) {
        flex-direction: column;
        gap: 0;
        .avatar{
            svg{
                width: 40px;
                height: 40px;
            }
        }

    }
`
const TitleBox = styled.div`
    border-bottom: 1px solid rgba(255,255,255,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ant-tabs-top >.ant-tabs-nav{
        margin-bottom: 0;
    }

    @media (max-width: 1274px) {
        padding: 0 10px;
    }
`

const BalanceBox = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-left: 10px;
        
    }
    .sym{
        margin-left: 5px;
        margin-bottom: -2px;
        opacity: 0.6;
    }
`

const FlexLine = styled.div`
    display: flex;
    gap:10px;
    align-items: center;

    @media (max-width: 1274px) {
        flex-direction: column;
        justify-content: center;
        gap:0;
    }
`

const RbfButton = styled.div`
    
    margin-top: 10px;
    .btn{
        color: #ff9500;
        border: 1px solid #ff9500;
        display: inline-block;
        font-size: 12px;
        padding:2px 10px;
        border-radius: 5px;
        cursor: pointer;
    }
`


export default function MyAssets(){
    const {t } = useTranslation();
    const { notification } = App.useApp();
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type);
    const [balance,setBalance] = useState({})
    const navigate = useNavigate()
    const [selectType,setSelectType] = useState('')

    const [current,setCurrent] = useState("1")
    const [rbf,setRbf] = useState(false);


    const [search] = useSearchParams();
    const tab = search.get("tab");

    useEffect(()=>{
        setCurrent(tab|| "1")

    },[tab])

    useEffect(() => {
        if(!type) {
            navigate("/")
            return;
        }
        if(!isBTC(type)){
            navigate("/myAssets_ckb")
        }

    }, [type]);


    const items = [
        {
            key: '1',
            label: t('general.MyAssets'),
        },
        {
            key: '2',
            label: t("my.orders"),
        },

    ];

    useEffect(() => {
        if(!account) {
            navigate("/")
        }
    }, [account]);


    useEffect(() => {
        if(!type || !isBTC(type)) return;
        getBl()
    }, [type]);

    const getBl = async() =>{
        try{
            let rt = await handleBalance(type,account);
            setBalance(rt);

        }catch (e) {
            console.error("get balance",e)
            notification.error({
                message: 'Fetch balance failed!',
                description:e?.response?.data?.message || e.message || e.reason
            });
        }

    }

    const Copy = () =>{


        notification.success({
            message: 'Copied!',
        });
    }

    const onChange = (key) => {
        setCurrent(key)
    };

    const handleChange = (value) => {
        setSelectType(value)
    };

    const handleRbfClose = () =>{
        setRbf(false);
    }

    const handleRbf = () =>{
        setRbf(true);
    }


    return <Box>
        <TopBanner>
            <FirstLine>
                {
                    !!account &&  <div className="avatar"><Avatar

                        size={100}
                        name={account}
                        variant="beam"
                        colors={[
                            '#FF5A5F',
                            '#074ce1',
                            '#047738',
                            "#13ada5",
                            "#F7BA2A"]}
                    />
                    </div>
                }

                <RhtBox>
                    <div className="title">{account} <CopyToClipboard onCopy={() => Copy()} text={account}>
                        <CopyOutlined/>
                    </CopyToClipboard>
                    </div>

                    <div className="titleMobile">{FormatAddress(account)} <CopyToClipboard onCopy={() => Copy()} text={account}>
                        <CopyOutlined className="iconB"/>
                    </CopyToClipboard>
                    </div>

                    <FlexLine>
                        <BalanceBox>
                            <div>BTC {t("my.balance")}</div>
                            <div>
                                {t("my.total")}:<span className="num">{balance?.total}</span> <span
                                className="sym">BTC</span>
                            </div>

                        </BalanceBox>
                        <BalanceBox>
                            <div>{t("my.confirmed")}:<span className="num">{balance?.confirmed}</span> <span className="sym">BTC</span></div>
                        </BalanceBox>
                        {/*<BalanceBox>*/}
                        {/*    <div>{t("my.unconfirmed")}:<span className="num">{balance?.unconfirmed}</span> <span*/}
                        {/*        className="sym">BTC</span></div>*/}
                        {/*</BalanceBox>*/}
                    </FlexLine>
                    {/*<RbfButton>*/}
                    {/*    <div className="btn" onClick={()=>handleRbf()}>RBF</div>*/}
                    {/*</RbfButton>*/}

                </RhtBox>
            </FirstLine>

        </TopBanner>
        <TitleBox>
            <Tabs activeKey={current} items={items} onChange={onChange}/>
            {
                current === "2" && <div className="li"><Select
                    defaultValue=""
                    size="large"
                    style={{width: "140px"}}
                    className="selectBox"
                    onChange={handleChange}
                    dropdownStyle={{background: "#141414"}}
                    options={[
                        {value: '', label: t("collection.AllEvents")},
                        {value: 'listed', label: t("collection.Listed")},
                        {value: 'sold', label: t("collection.Sold")},
                        {value: 'unlisted', label: t("collection.Unlisted")},
                    ]}
                /></div>
            }
        </TitleBox>
        {
            current === "1" && <Collections/>
        }
        {
            current === "2" && <MyOrderListBTC selectType={selectType} />
        }
        {
            rbf &&  <RBFModal handleClose={handleRbfClose} />
        }

    </Box>
}
