import styled from "styled-components";
import LogoImg from "../../assets/images/ckb.png";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import BuyCkb from "../modal/buy_ckb.jsx";
import {FormatAddress, formatUSD_ckb, handleBgCKB, isBTC, toJSONStr} from "../../utils/general";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {formatUnit} from "@ckb-lumos/bi";
import DidImg from "../../assets/images/did.svg"
import Pending from "../pending.jsx";
import Wallet from "../modal/wallet.jsx";
import SwitchWallet from "../modal/switchWallet.jsx";
import {getDOBImage} from "../../api/assets_ckb.js";

const Box = styled.div`
    width: 100%;
    border-radius: 10px;
    background: rgba(255,255,255,0.1);
    box-sizing: border-box;
    overflow: hidden;
    border: 2px solid rgba(255,255,255,0.01);
    &:hover,&.active{
        border: 2px solid #ff9500;
        cursor: pointer;

        .btnBox{
            background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
            color: #0D0C0B;
        }
    }
    
    .btnBox,.btnBox2{
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff9500;
        padding: 10px;
        border-radius: 5px;
        color: #ff9500;
        cursor: pointer;
        background: none;
        &:disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
`

const BgBox = styled.div`
    position: relative;
    .photo{
        display: flex !important;
        overflow: hidden;
        background: ${props => props.bg};
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255,255,255,0.03);
            img{
                max-width: 100%;
                max-height: 100%;
                //image-rendering: pixelated;
            }
        }
    }
    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        z-index: 3;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .innerTxt{
        background: rgba(255, 149, 0, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 10px;
        box-sizing: border-box;
        .title{
            font-size: 30px;
            margin-bottom: 5px;
            font-family: din_bold;
        }
        .tips{
            opacity: 0.6;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:9;
            -webkit-box-orient: vertical;
            line-height: 1.5em;
        }
    }
`
const MetaBox = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .last{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap:10px;
        img{
            width: 25px;
           border-radius: 50%;
        }
    }
    .btc{
        font-family: din_bold;
        display: flex;
        align-items: center;
        width: 55%;
        gap: 5px;
        flex-grow: 1;

        span{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
    }
    .capacity{
        display: flex;
        justify-content: space-between;
        gap: 5px;
        opacity: 0.6;
        margin-bottom: 10px;
    }
    .price{
        opacity: 0.6;
        flex-shrink: 0;
        font-family:din_regular;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 44%;
        white-space: nowrap;
        //display: -webkit-box;
        //-webkit-line-clamp: 1;
        //-webkit-box-orient: vertical;
        
    }
    @media (max-width: 1274px) {
        padding: 0 10px;
        .last{
            flex-direction: column;
            align-items: flex-start;
            .btc{
                width: 100%;
                span{
                    overflow: visible;
                }
            }
            .price{
                width: 100%;
                text-align: left;
            }
        }
    }
`
const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
    .id{
        opacity: 0.6;
        font-size: 14px;
    }

    .desc{
        font-family: din_bold;
        padding-top: 5px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
`

const ItemImgBox = styled.div`
    position: relative;
    img{
        width: 100%;
    }
    .nameBox{
        position: absolute;
        color: #ffe9b7;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        top:50%;
      
        .title{
            font-size: 14px;
            font-family: din_bold;
        }
        .bit{
            font-size: 12px;
        }
    }
`

export default function Item({item,getAssets}){
    const {t } = useTranslation();
    const navigate = useNavigate();
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)
    const [show,setShow] = useState(false);
    const [selectedItem,setSelectedItem] = useState(null);
    const infoCKb = useSelector(store => store.infoCKb)
    const [showChain,setShowChain] = useState(false);
    const walletList = useSelector(store => store.walletList);
    const [showSwitch,setShowSwitch] = useState(false);
    const [image,setImage] = useState("");

    useEffect(() => {
        const sporeObj = toJSONStr(item?.sporeData)
   if(sporeObj.contentType.indexOf("application/json")>-1){
       getDetail()

        }

    }, [item]);

    const getDetail = async() =>{
        try{
            let rt = await getDOBImage(item?.dobsId)
            setImage(rt.url)

        }catch(e){
            console.error("get dob image error",item?.dobsId)
        }
    }


    const toDetail = () =>{
        navigate(`/item-detail_ckb/${item.dobsId}`)
    }

    const handleShow = (e) =>{
        e.stopPropagation();
        setShow(true)
        setSelectedItem(item)
    }

    const ModalClose = () =>{
        setShow(false)
    }

    const handleChain = (e) =>{
        e.stopPropagation();
        if(walletList.ckb){
            setShowSwitch(true)
        }else{
            setShowChain(true)
        }

    }

    const handleChainClose = () =>{
        setShowChain(false)
        setShowSwitch(false)
    }



    return <>
        {
            show && <BuyCkb ModalClose={ModalClose} selectedItem={selectedItem} getAssets={getAssets} />
        }
        {
            showChain && <Wallet handleClose={handleChainClose} chain="ckb"/>
        }

        {
            showSwitch &&  <SwitchWallet handleClose={handleChainClose} handleNext={handleChain} switchTochain="ckb" />
        }
        <Box onClick={()=>toDetail()}>
            <BgBox bg={handleBgCKB(item)}>
                {
                    item.ownerAddr === account && item.status !== 3  && <div className="listedTag">
                         {t("item.My")}
                    </div>
                }

                {
                    item.status === 3 && <div className="listedTag">
                        <Pending/><span>{t("item.Pending")}</span>
                    </div>
                }

                {
                    item.assetType === 0 && <div className="photo">
                        <div className="aspect"/>
                        <div className="content">
                            <div className="innerImg">
                                <img
                                    src={item?.spore?.url || item?.spore?.imgUrl || image}
                                    alt=""/>
                            </div>

                            {/*{*/}
                            {/*    item?.media_info?.content_type?.indexOf("text") > -1 && <div className="innerTxt">*/}
                            {/*        /!*<div className="title">{item.display_name}</div>*!/*/}
                            {/*        <div className="tips">{item?.media_info?.content_data}</div>*/}
                            {/*    </div>*/}
                            {/*}*/}

                        </div>
                    </div>
                }

                {
                    item.assetType === 1 && <ItemImgBox>
                        <div className="nameBox">
                            <div className="title">
                                {item?.spore?.DidName?.split(".")[0]}
                            </div>
                            <div className="bit">
                                .bit
                            </div>

                        </div>
                        <img src={DidImg} alt=""/>
                    </ItemImgBox>
                }


            </BgBox>
            <MetaBox>
                <TitleBox>
                    <span className="id">ID</span>
                    <span className="id">#{FormatAddress(item?.dobsId)}</span>
                    {/*<div className="desc">{item?.display_name}</div>*/}

                </TitleBox>
                <div className="capacity">
                    <span>Capacity</span> <span>{item?.capacity ? formatUnit(item?.capacity, "ckb") : 0} CKB</span>
                </div>
                <div className="last">
                    <div className="btc">
                        <img src={LogoImg} alt=""/>
                        <span>{item?.price ? formatUnit(item?.price, "ckb") : 0}</span>
                    </div>

                    <div className="price">$ {formatUSD_ckb(infoCKb.ckbToUSDT, item?.price)}</div>

                </div>

                {
                    !account &&
                    <button className="btnBox" onClick={(e) => handleChain(e)}>{t('collection.buyNow')}</button>
                }

                {
                    account && item.status === 1 && !isBTC(type) && <button className="btnBox"
                                                                            disabled={item.ownerAddr === account || isBTC(type)}
                                                                            onClick={(e) => handleShow(e)}>{t('collection.buyNow')}</button>
                }
                {
                    account && item.status === 1 && isBTC(type) && <button className="btnBox"
                                                                           onClick={(e) => handleChain(e)}>{t('collection.buyNow')}</button>
                }
                {
                    account && item.status !== 1 &&
                    <button className="btnBox2" disabled>{t('collection.MakeOffer')}</button>
                }

            </MetaBox>
        </Box>
    </>

}
