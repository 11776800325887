import styled from "styled-components";
const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    position: fixed;
    z-index: 99999;
`

const Box = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    .loader {
        width: 40px;
        height: 20px;
        background: orange;
        position: relative;
        animation: l9-0 1.5s infinite linear;
    }
    .loader:before,
    .loader:after {
        content:"";
        position: absolute;
        background: inherit;
        bottom: 100%;
        width: 50%;
        height: 100%;
        animation: inherit;
        animation-name: l9-1;
    }
    .loader:before {
        left: 0;
        transform-origin: bottom left;
        --s:-1;
    }
    .loader:after {
        right: 0;
        transform-origin: bottom right;
    }
    @keyframes l9-0 {
        0%,10%   {transform:translateY(0%)    scaleY(1)}
        49.99%   {transform:translateY(-50%)  scaleY(1)}
        50%      {transform:translateY(-50%)  scaleY(-1)}
        90%,100% {transform:translateY(-100%) scaleY(-1)}
    }
    @keyframes l9-1 {
        10%,90% {transform: rotate(0deg)}
        50%     {transform: rotate(calc(var(--s,1)*180deg))}
    }
`
export default function  Loading(){
    return <Box>
        <MaskBox>
            <div className="loader" />
        </MaskBox>

    </Box>
}
