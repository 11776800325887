import request from './index';

const rootPrefix = import.meta.env.VITE_CHAIN==="testnet"?"":'/layer';

export const collectionsDetailCKB = (clusterId) =>{
    return request.get(`${rootPrefix}/api/getcluster/${clusterId}`);
}

export const assetsInfoCKB = (dobsId) =>{
    return request.get(`${rootPrefix}/api/getdobs/${dobsId}`);
}


export const cancelOrder = (id) =>{
    return request.post(`${rootPrefix}/orders/${id}/cancel`);
}





// my orders:  /orders/my?collection_id=:coll_id

export const collectionsListCKB = (clusterId,page,pageSize,orderList,searchList) =>{
    return request.get(`${rootPrefix}/api/alldobs/${clusterId}?page=${page}&pageSize=${pageSize}&orderList=${orderList}&searchList=${searchList}`);
}

//     listed: /collections/:coll_id/assets?sort_field=order_price&sort=asc&listing_only=1
export const listedListCKB=  (clusterId,page,pageSize,orderList,searchList) =>{
    return request.get(`${rootPrefix}/api/listed/${clusterId}?page=${page}&pageSize=${pageSize}&orderList=${orderList}&searchList=${searchList}`);
}

//     orders: /orders/all?collection_id=:coll_id
export const OrderListCKB= (clusterId,page,pageSize,searchList) =>{
    return request.get(`${rootPrefix}/api/activites/${clusterId}?page=${page}&pageSize=${pageSize}&searchList=${searchList}&orderList=id_DESC`);
}

export const myOrderListCKB= (clusterId,page,pageSize,searchList,account) =>{
    return request.get(`${rootPrefix}/api/mydobs/${account}/${clusterId}?page=${page}&pageSize=${pageSize}&searchList=${searchList}&orderList=id_DESC`);
}
export const myOrderListCKB_assets= (page,pageSize,account) =>{
    return request.get(`${rootPrefix}/api/myalldobs/${account}?page=${page}&pageSize=${pageSize}&orderList=id_DESC`);
}
export const getServiceInfo = () =>{
    return request.get(`${rootPrefix}/api/getdexinfo`);
}

export const sendToServer = (data) =>{
    return request.post(`${rootPrefix}/api/sendtx`,data);
}
export const getAllMyOrder = (addr,page,pageSize,searchList) =>{
    return request.get(`${rootPrefix}/api/mydobswithoutcluster/${addr}?page=${page}&pageSize=${pageSize}&orderList=id_DESC&searchList=${searchList}`);
}


export const getDOBImage = (dobid) =>{
    return request.get(`https://decoder-spore-plain-json.caboroca.xyz/parse/${dobid}`);
}

