export const BASE_URL = {
    dev: "https://dev-dex-api.entrust3.com",
    prev: "https://api.dobby.market",
    prod: "https://api.dobby.market",
};


export const Params = {
    testnet: {
        scanUrl: "https://mempool.space/testnet/",
        node: "https://testnet.ckbapp.dev",
        DOB_AGGREGATOR_URL:"https://cota.nervina.dev/aggregator",
        indexer: "https://testnet.ckbapp.dev"
    },
    livenet: {
        scanUrl: "https://mempool.space/",
        node: "https://mainnet.ckbapp.dev",
        DOB_AGGREGATOR_URL:"https://cota.nervina.dev/mainnet-aggregator",
        indexer: "https://mainnet.ckbapp.dev/indexer"
    }
}

export const SIG_WORD = "Welcome to Dobby, the platform for DOBs. Please sign this message to verify your identity."


export const NETWORK = {
    livenet: {
        messagePrefix: "\x18Bitcoin Signed Message:\n",
        bech32: "bc",
        bip32: {
            public: 0x0488b21e,
            private: 0x0488ade4,
        },
        pubKeyHash: 0x00,
        scriptHash: 0x05,
        wif: 0x80,
    },
    testnet: {
        messagePrefix: "\x18Bitcoin Testnet Signed Message:\n",
        bech32: "tb",
        pubKeyHash: 0x6f,
        scriptHash: 0xc4,
        wif: 0xef,
    }
}
    ;

export const MIN_PRICE = {
    testnet:1,
    livenet:100000
}
export const DEFAULT_LANGUAGE = 'en-us';


/**
 * Testnet API https://btc-assets-api.testnet.mibao.pro/docs/static/index.html
 *
 * Prod API https://api.rgbpp.io/bitcoin/docs/static/index.html, we need apply for the token
 */

export const TOKEN_CONFIG = {
    dev: {
        BTC_ASSETS_API_URL: "https://btc-assets-api.testnet.mibao.pro",
        BTC_ASSETS_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ0ZXN0LWRvYmJ5IiwiYXVkIjoidGVzdC1kb2JieS5lbnRydXN0My5jb20iLCJqdGkiOiJkOWY1NmI5Zi0wZWM3LTQ4MTMtYjE3Yy1iNTgzZDQ0YTNmYjAiLCJpYXQiOjE3MjIxOTAzNzF9.RylQgckbJXMJlviNWZ_iOundPwrHLNGzlBfZ5FqFwlk",
        BTC_ASSETS_ORGIN: "test-dobby.entrust3.com"
    },
    prev: {
        BTC_ASSETS_API_URL: "https://api.rgbpp.io",
        BTC_ASSETS_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJyZ2ItYXBwLXRvb2wiLCJhdWQiOiJyZ2ItYXBwLmVudHJ1c3QzLmNvbSIsImp0aSI6ImE0ZmJlNzQ3LTY1NGEtNDk2ZS1iZjA1LTQ0YTk2MjY5MWUyYiIsImlhdCI6MTcyMTgyNzE3OX0.LZnuMb8YoDzaUBaV5ZkWOXm6Xt3MZo5wglfFn2baGzA",
        BTC_ASSETS_ORGIN: "rgb-app.entrust3.com"
    },
    prod: {
        BTC_ASSETS_API_URL: "https://api.rgbpp.io",
        BTC_ASSETS_TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJET0JCWSBBUFAiLCJhdWQiOiJhcHAuZG9iYnkubWFya2V0IiwianRpIjoiYzIxZmNhMTYtZWQxMC00MzFkLThlMGUtNjcyYjE4MmU0NDhiIiwiaWF0IjoxNzE0MDMwMTMzfQ.wZnftclz4zsANWqzQPMqkCfDFrr5VH-iaZFQ_9CDnbc",
        BTC_ASSETS_ORGIN: "app.dobby.market"
    }
}

//
export const SPV_CONFIG = {
    dev: {
        DEFAULT_WITNESS_ADDR: 'tb1pq2x0qvl0qejrxdxnlmm43zdt8cvda4dcwcespdwcw96v6xnd3veqzgdm0m', // The address of the witness, which is used to receive service fee.
        DEFAULT_WITNESS_PUBKEY: 'c56d5c3cdc4c28aa383271d56a6ac3e06cc2ebe7a626ef3c5c54dd66cfe45c1a', // The pubkey of the witness.
    },
    prev: {
        DEFAULT_WITNESS_ADDR: 'tb1pq2x0qvl0qejrxdxnlmm43zdt8cvda4dcwcespdwcw96v6xnd3veqzgdm0m', // The address of the witness, which is used to receive service fee.
        DEFAULT_WITNESS_PUBKEY: 'c56d5c3cdc4c28aa383271d56a6ac3e06cc2ebe7a626ef3c5c54dd66cfe45c1a', // The pubkey of the witness.
    },
    prod: {
        DEFAULT_WITNESS_ADDR: 'bc1pxcu3gflv4mal8vs9skle22qacf5n49cgvdnht626g8f042sw9lzqvdsm8w', // The address of the witness, which is used to receive service fee.
        DEFAULT_WITNESS_PUBKEY: 'c70a248ff47e02ce5d3706ca44d4df59d676869955c4994d1c1abceab6db695d', // The pubkey of the witness.
    }
}


export const  CKB_networkList = {
    livenet:{
        node:"https://mainnet.ckb.dev/rpc",
        indexer:"https://mainnet.ckb.dev/indexer",
        scanUrl:"https://explorer.nervos.org/"
    },
    testnet:{
        node:"https://testnet.ckb.dev/rpc",
        indexer:"https://testnet.ckb.dev/indexer",
        scanUrl:"https://pudge.explorer.nervos.org/"
    }
}


export const CKB_CONTRACT = {
    livenet:{
        CODE_HASH:"0x7bee13e734cc0723f01943bf6adc3fd03429c8facbb04ef269733ab8c09213fb",
        TX_HASH:"0xf8676a2990f301ff39e2bd73ab5f3ba0bea1cd18766a1c164606388ecab744f9",
        HASH_TYPE:"type",
        DEP_TYPE:"code",
        INDEX: "0x0",
    },
    testnet:{
        CODE_HASH:"0xcaa8deb48d16c61e6fc9d57d951a824bbe426590a9e62e3fdde033f4ed107e01",
        TX_HASH:"0xbcac3a1eaeb76a4a5d6ab03e4192bab0b1c3b05ca18c56c33763ee8d5245733a",
        HASH_TYPE:"type",
        DEP_TYPE:"code",
        INDEX: "0x0",


        // service_address:"ckt1qzda0cr08m85hc8jlnfp3zer7xulejywt49kt2rr0vthywaa50xwsqft99m0c7x3wa379wgt5gnapjtu7g52taseclw82"
    }
}


export const CKB_FEE_CONTRACT = {
    livenet:{
        CODE_HASH:"0x4194fb6393862daa884ca41bb6b5378a739f914bc274da674e27d2fe4f8d9762",
        TX_HASH:"0xd5fb5855133016273c5c391dee55a6bd3fd5ad260a187925a0b9e61937e7b1aa",
        HASH_TYPE:"type",
        DEP_TYPE:"code",
        INDEX: "0x0",
    },
    testnet:{
        CODE_HASH:"0xe6328e97de0e3dcc84b9bab27f6caf31d11f5cadc470b5f1990d6b0a7f82bd60",
        TX_HASH:"0x2d993591df6a0422d1c48ad1254281926a6b32cd4a22939ecc9bfed13b2a780c",
        HASH_TYPE:"type",
        DEP_TYPE:"code",
        INDEX: "0x0",
    }
}

export const DID_CONTRACT = {
    livenet:{
        CODE_HASH:"0xcfba73b58b6f30e70caed8a999748781b164ef9a1e218424a6fb55ebf641cb33",
        TX_HASH:"0x18dda0f02036305b423b85cce276a40417faed044b2ee9220284215f38734daa",
        HASH_TYPE:"type",
        INDEX: "0x0",
        DEP_TYPE:"code",
    },
    testnet:{
        CODE_HASH:"0x0b1f412fbae26853ff7d082d422c2bdd9e2ff94ee8aaec11240a5b34cc6e890f",
        TX_HASH:"0x1bda44bf86d240b739e035ade4eae2d8a53085210c6dab96b49ffeacb8c1174e",
        HASH_TYPE:"type",
        INDEX: "0x0",
        DEP_TYPE:"code",
    }
}

export const MINiPrice = "64"

export const UTXO_PEROID = 10 * 60 * 1000;


export const  OMNILOCK ={
    livenet:{
        CODE_HASH: "0x9b819793a64463aed77c615d6cb226eea5487ccfc0783043a587254cda2b6f26",
        HASH_TYPE: "type",
        TX_HASH: "0xc76edf469816aa22f416503c38d0b533d2a018e253e379f134c3985b3472c842",
        INDEX: "0x0",
        DEP_TYPE: "code",
    },
    testnet:{
        CODE_HASH: "0xf329effd1c475a2978453c8600e1eaf0bc2087ee093c3ee64cc96ec6847752cb",
        HASH_TYPE: "type",
        TX_HASH: "0xec18bf0d857c981c3d1f4e17999b9b90c484b303378e94de1a57b0872f5d4602",
        INDEX: "0x0",
        DEP_TYPE: "code",
    }
}

export const Tools = [
    {
        name:"DOB Creator",
        link:"https://creator.dobby.market"
    },
    {
        name:"Tx Accelerator",
        link:"https://acc.dobby.market"
    }
]

export const Links = [
    {
       name:"Cryptape",
       link:"https://cryptape.com/"
    },
    {
        name:".bit",
        link:"https://d.id/"
    },
    {
        name:"World3",
        link:"https://world3.ai/"
    },
    {
        name:"Cellula",
        link:"https://www.cellula.life/"
    },
    {
        name:"Stable++",
        link:"https://www.stablepp.xyz/"
    },
    {
        name:"Magickbase",
        link:"https://www.magickbase.com/"
    },
    {
        name:"Cell Studio",
        link:"https://www.magickbase.com/"
    }
]

export const Resources = [
    {
        name:"Listing Request",
        link:" https://tally.so/r/nWP6RQ"
    },
    {
        name:"Rei Wallet",
        link:"https://reiwallet.io/"
    },
    {
        name:"JoyID",
        link:"https://joy.id/"
    },
    {
        name:"CCC",
        link:"https://github.com/ckb-ecofund/ccc"
    }
]

export const Helper = [
    {
        title:"How to cancel my listing orders?",
        content:'Please go to "My Orders", and you will find all your orders there. And you can filter your orders with status of "Listed".'
    },
    {
        title:"Why is my Bitcoin DOB transaction not being confirmed?",
        content:'Inscribing and trading DOB requires block confirmation on the BTC network. Due to the surge in transactions causing network congestion, your transaction may take 1-2 days to be fully confirmed. If your transaction shows a confirming status, you can track the progress on Mempool. It is recommended that you patiently wait for the block confirmation to complete.'
    },
    {
        title:"Why my listed Bitcoin order is marked as canceled while the item is not in my assets?",
        content:'In Bitcoin, the iteam (which is DOB in this case) is binded with your UTXO, if the corresponding UTXO is spend, then the item will be transfered too. If the spend transaction is not happened in Dobby, then the Dobby will mark the order related to the UTXO as canceled.'
    },
    {
        title:"What is 1BitcoinEaterAddressDontSendf59kuE?",
        content:'There is a well known Bitcoin vanity address \'1BitcoinEaterAddressDontSendf59kuE\' which is considered to be an unspendable address since computing it from the associated private key would be expected to take 3.3 decillion years! ',
        link: "https://bitcointalk.org/index.php?topic=48317.0"
    },
    {
        title:"How to get my Cluster listed on Dobby?",
        content:'Currently Dobby Market supports DOBs based on RGB++ protocol. If you have a cluster and want to list it on Dobby, please fill the listing request form and we will get back to you as soon as possible.',
        link: "https://tally.so/r/nWP6RQ"
    },
]
