import {loginWeb} from "../api/login.js";
import {useSelector} from "react-redux";
import store from "../store/index.js";
import {saveLoading, saveToken,saveTokenExp} from "../store/reducer.js";
import {useEffect} from "react";
import {handlePublicKey} from "../utils/wallet.js";

export default function useLogin(handleClose,walletType,loginType){
    const account = useSelector(store => store.account);
    const type = useSelector(store => store.type)
    const signature = useSelector(store => store.signature);

    useEffect(()=>{
        let joyidtype = localStorage.getItem("joyidType");

        if(!account || type !== walletType || !signature || (loginType && loginType !== joyidtype)) return;
        login()
    },[account,type,signature,walletType,loginType])

    const login = async() =>{
        const pubkey = await handlePublicKey(type, true); // Caution! Here OKX need pass true to get compressed public key
        const rt = await loginWeb(account,signature,pubkey,type)
        const {bearerToken} = rt;

        store.dispatch(saveLoading(false));
        store.dispatch(saveToken(bearerToken));

        const jwtPayload = JSON.parse(window.atob(bearerToken.split('.')[1]))
        const {exp} = jwtPayload;
        store.dispatch(saveTokenExp(exp * 1000));

        handleClose()

    }

    return null
}
