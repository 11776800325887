import { assembleTransferSporeAction, assembleCobuildWitnessLayout } from '@spore-sdk/core/lib/cobuild'
import { blockchain } from "@ckb-lumos/base";
import {BI, helpers} from "@ckb-lumos/lumos";
import {bytes} from "@ckb-lumos/lumos/codec";
import {
    getCotaTypeScript,
    getJoyIDCellDep,
    getJoyIDLockScript,
    getSubkeyUnlock,
    signRawTransaction
} from "@joyid/ckb";
import {CKB_networkList, Params} from "./constant.js";
import {getNet} from "./wallet.js";
import store from "../store/index.js";
import {serializeScript, serializeWitnessArgs} from "@nervosnetwork/ckb-sdk-utils";
import {append0x, Collector} from "@rgbpp-sdk/ckb";
import {sendToServer} from "../api/assets_ckb.js";
import {isCCC} from "./general.js";
import {Transaction} from "@ckb-ccc/ccc";

import {JsonRpcTransformers} from "@ckb-ccc/ccc/advanced";

export const generateSporeCoBuild_Single = (sporeCell, outputCell) => {
    const { actions } = assembleTransferSporeAction(sporeCell, outputCell)
    return assembleCobuildWitnessLayout(actions)
}

export const getTransactionSizeByTx = (tx) => {
    const serializedTx = blockchain.Transaction.pack(tx);
    const size = serializedTx.byteLength + 4;
    return size;
}

export const calculateFeeCompatible =(size, feeRate) => {
    const ratio = BI.from(1000);
    const base = BI.from(size).mul(feeRate);
    const fee = base.div(ratio);
    if (fee.mul(ratio).lt(base)) {
        return fee.add(1);
    }
    return BI.from(fee);
}

export const updateWitness = async(txSkeleton,myScript,code_hash_contract_type,sporeCoBuild,type) =>{
    const inputArr = txSkeleton.get("inputs").toArray();
    for (let i = 0; i < inputArr.length; i++) {
        txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.push("0x"));
    }

    if(type === "joyid_ckb"){
        const state = store.getState();
        const {joyidInfo} = state;

        if(joyidInfo?.keyType !==  "sub_key"){

            let emptyWitness = { lock: '', inputType: '', outputType: '' }
            const firstIndex = txSkeleton
                .get("inputs")
                .findIndex((input) =>
                    bytes.equal(blockchain.Script.pack(input.cellOutput.lock), blockchain.Script.pack(myScript))
                );

            while (firstIndex >= txSkeleton.get("witnesses").size) {
                txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.push("0x"));
            }
            txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.set(firstIndex,  serializeWitnessArgs(emptyWitness)));
        }
        else{
            let net = await getNet(type);
            const unlockEntry = await getSubkeyUnlock(Params[net]?.DOB_AGGREGATOR_URL, joyidInfo)
             let emptyWitness = {
                lock: '',
                inputType: '',
                outputType: append0x(unlockEntry),
            }
            const firstIndex = txSkeleton
                .get("inputs")
                .findIndex((input) =>
                    bytes.equal(blockchain.Script.pack(input.cellOutput.lock), blockchain.Script.pack(myScript))
                );

            while (firstIndex >= txSkeleton.get("witnesses").size) {
                txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.push("0x"));
            }
            txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.set(firstIndex,  serializeWitnessArgs(emptyWitness)));



            const cotaType = getCotaTypeScript(net==="livenet")

            const collector = new Collector({
                ckbNodeUrl: CKB_networkList[net].node,
                ckbIndexerUrl: CKB_networkList[net].indexer,
            })

            const cotaCells = await collector.getCells({ lock: myScript, type: cotaType })
            if (!cotaCells || cotaCells.length === 0) {
                throw new Error("Cota cell doesn't exist")
            }
            const cotaCell = cotaCells[0]
            const cotaCellDep = {
                outPoint: cotaCell.outPoint,
                depType: 'code',
            }

            let cellDeps = txSkeleton.get("cellDeps")

            txSkeleton = txSkeleton.update("cellDeps", (cellDep) => cellDep.set(0,cotaCellDep));

            for (let i = 0; i < cellDeps.size; i++) {
                const item = cellDeps.get(i)
                txSkeleton = txSkeleton.update("cellDeps", (cellDep) => cellDep.set(i+1,item));
            }
        }
    }
    else{
        const firstIndex = txSkeleton
            .get("inputs")
            .findIndex((input) =>
                bytes.equal(blockchain.Script.pack(input.cellOutput.lock), blockchain.Script.pack(myScript))
            );
        if (firstIndex !== -1) {
            while (firstIndex >= txSkeleton.get("witnesses").size) {
                txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.push("0x"));
            }
            let witness = txSkeleton.get("witnesses").get(firstIndex);
            const newWitnessArgs = {
                /* 65-byte zeros in hex */
                lock: "0x0000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000",
            };
            if (witness !== "0x") {
                const witnessArgs = blockchain.WitnessArgs.unpack(bytes.bytify(witness));
                const lock = witnessArgs.lock;
                if (!!lock && !!newWitnessArgs.lock && !bytes.equal(lock, newWitnessArgs.lock)) {
                    throw new Error("Lock field in first witness is set aside for signature!");
                }
                const inputType = witnessArgs.inputType;
                if (!!inputType) {
                    newWitnessArgs.inputType = inputType;
                }
                const outputType = witnessArgs.outputType;
                if (!!outputType) {
                    newWitnessArgs.outputType = outputType;
                }
            }
            witness = bytes.hexify(blockchain.WitnessArgs.pack(newWitnessArgs));
            txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.set(firstIndex, witness));
        }
    }



    const contractIndex = txSkeleton
        .get("inputs")
        .findIndex((input) => input.cellOutput.type?.codeHash ===  code_hash_contract_type
        );

    while (contractIndex >= txSkeleton.get("witnesses").size) {
        txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.push("0x"));
    }
    txSkeleton = txSkeleton.update("witnesses", (witnesses) => witnesses.set(txSkeleton
        .get("inputs").size, sporeCoBuild));

    return txSkeleton;

}

export const sendRawTx = async(txSkeleton,type,address,isNotList,refresh,signer) =>{
    let signed

    if(type ==="rei"){
        const txObj = helpers.transactionSkeletonToObject(txSkeleton)
        signed = await window.ckb.request({method:"ckb_signRawTransaction",data:{
                txSkeleton:txObj
            }})

    }else if(type === "joyid_ckb"){

        const tx = helpers.createTransactionFromSkeleton(txSkeleton);
        signed = await signRawTransaction(
            tx ,
            address,
            {
                witnessLastIndex:txSkeleton.get("inputs").size -(isNotList?2:1)
            }
    );

    }else if(isCCC(type)){
        const txObj = Transaction.fromLumosSkeleton(txSkeleton)

        const tx = await signer.signTransaction(txObj)
        let txFormat = JsonRpcTransformers.transactionFrom(tx)
        let result = JsonRpcTransformers.transactionTo(txFormat)
        const txString = result.stringify()
        signed = JSON.parse(txString)

    }

    await sendToServer({rawTx:signed})
    if(refresh){
        refresh()
    }else{
        window.location.reload()
    }


}

export const getJoyid_lock_type = (net) =>{
    let lock = getJoyIDLockScript(net==="livenet")
    let celldep = getJoyIDCellDep(net==="livenet")

    const {codeHash,hashType} = lock;
    const {depType,outPoint:{index,txHash}} = celldep;

    return{
        CODE_HASH: codeHash,
        DEP_TYPE:depType,
        HASH_TYPE: hashType,
        INDEX: index,
        SHORT_ID: 0,
        TX_HASH: txHash
    }
}

export const generateSporeCoBuild = (sporeCells, outputCells) => {
    if (sporeCells.length !== outputCells.length) {
        throw new Error('The length of spore input cells length and spore output cells are not same')
    }


    let sporeActions = []
    for (let index = 0; index < sporeCells.length; index++) {
        const sporeCell = sporeCells[index]


        const outputData = sporeCell.data

        const sporeInput = {
            cellOutput: sporeCells[index].cellOutput,
            data: outputData,
        }
        const sporeOutput = {
            cellOutput: outputCells[index].cellOutput,
            data: outputData,
        }
        //
        const { actions } = assembleTransferSporeAction(sporeInput, sporeOutput)
        sporeActions = sporeActions.concat(actions)
    }
    return assembleCobuildWitnessLayout(sporeActions)

}
