import request from "./index.js";

const rootPrefix = import.meta.env.VITE_CHAIN==="testnet"?"/btc":'';

export const getUTXO = (address,isMainnet) =>{
    return request.get(`https://mempool.space${isMainnet ? "" : "/testnet"}/api/address/${address}/utxo`);
}

export const getBTCPrice = () =>{
    return request.get(`${rootPrefix}/dex-info/price`);
}
export const getFeeRate = () =>{
    return request.get(`https://mempool.space/api/v1/fees/recommended`);
}


export const getUtxoOutspend = (isMainnet,txHash,idx) =>{
    return request.get(`https://mempool.space${isMainnet ? "" : "/testnet"}/api/tx/${txHash}/outspend/${idx}`);
}

