import styled from "styled-components";
import CKBImg from "../assets/images/ckb.png";
import {InfoCircleOutlined, ReadOutlined} from '@ant-design/icons';
import BgImg from "../assets/images/bg.png";
import {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import store from "../store/index.js";
import {saveLoading} from "../store/reducer.js";
import {assetsInfoCKB, getDOBImage} from "../api/assets_ckb.js";
import {CKB_networkList, DID_CONTRACT} from "../utils/constant.js";
import {getNet} from "../utils/wallet.js";
import {useSelector} from "react-redux";
import dayjs from "dayjs";
import {App} from "antd";

import {useTranslation} from "react-i18next";
import {BI, formatUnit} from "@ckb-lumos/bi";
import {bufferToRawString, getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import BuyCkb from "../components/modal/buy_ckb.jsx";
import List_ckb from "../components/modal/list_ckb.jsx";
import {handleBgCKB, isBTC, toJSONStr} from "../utils/general.js";
import DidImg from "../assets/images/did.svg"
import {CellCollector, Indexer} from "@ckb-lumos/lumos";
import {build_list_dob} from "../utils/ckbTransaction.js";
import {sendRawTx} from "../utils/ckbUtils.js";
import {getFeeRate} from "../api/ckb.js";
import Pending from "../components/pending.jsx";
import Wallet from "../components/modal/wallet.jsx";
import SwitchWallet from "../components/modal/switchWallet.jsx";
import {Buffer} from "buffer";
import SendModal from "../components/modal/Send_ckb.jsx";



const Box = styled.div`
    margin-top: 50px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 32px;
    .titleInner{
        font-size: 18px;
        margin-bottom: 20px;
        padding-bottom:10px;
        text-transform: uppercase;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .icon{
        color: #ff9500;
        font-size: 20px;
    }
    .rht{
        img{
            width: 20px;
            height: 20px;
            border: 1px solid #ccc;
            border-radius: 100%;
        }
    }
    @media (max-width: 1274px) {
        flex-direction: column;
    }
`

const LftBox = styled.div`
    flex-shrink: 0;
    width: 450px;
    position: relative;

    @media (max-width: 1274px) {
        width: 90vw;
        margin: 0 auto;
    }
`

const BgBox = styled.div`
    //position: sticky;
    //top:120px;
    position: relative;
    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        z-index: 9;
        align-items: center;
        gap: 5px;
    }
    .photo{
        display: flex !important;
        overflow: hidden;
        border-radius: 10px;
        background: ${props => props.bg};
        //border-bottom: 1px solid rgba(255, 249, 242,0.2);
        //background: rgba(255,255,255,0.08);
        
        .aspect {
            padding-bottom: 100%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255,255,255,0.03);
            img{
                max-width: 100%;
                max-height: 100%;
                //image-rendering: pixelated;
            }
        }
        .nameBox{
            position: absolute;
            color: #ffe9b7;
            text-align: center;
            width: 100%;
            box-sizing: border-box;
            top:55%;

            .title{
                font-size: 18px;
                font-family: din_bold;
            }
            .bit{
                font-size: 14px;
            }
        }
        .innerTxt{
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(255,255,255,0.1);
            box-sizing: border-box;
            padding: 10px;
            display: flex;
            align-content: center;
            justify-content: center;

            font-size: 16px;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:15;
            -webkit-box-orient: vertical;
            line-height: 2em;
        }
    }
`
const RhtBox = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap:20px;
    @media (max-width: 1274px) {
        gap: 0;
        margin: 0 10px;
    }
`
const BtnBox = styled.button`
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    color: #0D0C0B;
    border: 0;
    padding: 0;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    .inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        background: url(${BgImg}) center 90%;
        background-size: 100% auto;
    }
    .buy{
        text-transform: uppercase;
        font-family: Roboto-Bold;
    }
    .price{
        font-family: din_bold;
        margin-right: 5px;
    }
    .sym{
        margin-bottom: -5px;
    }
    .rht{
        background: #FFFAF2;
        padding: 10px 20px;
        border-radius: 40px;
        box-shadow: inset 0 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
    }
    img{
        margin-right:10px;
    }
    @media (max-width: 1274px) {
        .inner{
            padding: 10px ;
        }
    }
`
const BtnListBox = styled.button`
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    background: linear-gradient(180deg, #6EEB83 0%, #00A99D 100%);
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
    color: #0D0C0B;
    padding: 0;
    border: 0;
    &:disabled{
        opacity: 0.5;
        cursor: not-allowed;
    }
    .inner{
        display: flex;
        align-items: center;
        justify-content: center;
        min-height:30px;
        padding: 10px 20px;
        background: url(${BgImg}) center 90%;
        background-size: 100% ;
    }
    .buy{
        text-transform: uppercase;
        font-family: Roboto-Bold;
    }
    .price{
        margin-right: 5px;
        font-weight: bold;
    }
 
    .rht{
        //background: #FFFAF2;
        padding: 10px 20px;
        border-radius: 40px;
        //box-shadow: inset 0 0 5px 5px rgba(0,0,0,0.1);
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    img{
        margin-right:10px;
    }
 
`

const TitleBox = styled.div`
    font-size: 16px;
    font-family: Roboto-Bold;
    word-break: break-all;
    @media (max-width: 1274px) {
        padding: 0 10px 10px; 
    }
`

const ContentBox = styled.div`
    padding: 20px;
    background: rgba(255, 149, 0, 0.1);
    border-radius: 10px;

    dl{
        margin-bottom: 20px;
        dt{
            opacity: 0.6;
            margin-bottom: 5px;
            word-break: break-all;
        }
        dd{
            font-size: 14px;
            word-break: break-all;
        }
    }
    .chainImg{
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        img{
            width: 32px;
            height: 32px;
            border-radius: 100%;
        }
    }
    @media (max-width: 1274px) {
        dd{
            word-break: break-all;
        }
    }
`
const RhtFst = styled.div`
    padding: 20px;
    background: rgba(255, 149, 0, 0.1);
    border-radius: 10px;
    dl{
        display: flex;
        align-items: flex-start;
        justify-content: space-between; 
        margin-bottom: 20px;
        gap: 50px;
    }
    dt{
        opacity: 0.6;
        white-space: nowrap;
        
    }

    dd{
        word-break: break-all;
        text-align: right;

    }
    .capacity{
        //color: #ff9500;
        margin-right: 5px;
    }
    .dna{
        padding-top: 20px;
        border-top: 1px solid rgba(255,255,255,0.3);
    }
    @media (max-width: 1274px) {
        dl{
            gap: 20px;
            align-items: flex-start;
        }
      
        dt{
            width: 30%;
            flex-shrink: 0;
        }
    }
`

const TraitBox = styled.div`
    
    &:after {
        content: '';
        display: block;
        clear: both;
    }

        dl{
        float: left;
        width: 24%;
        margin-right: 1%;
            background: rgba(255,255,255,0.05);
            border-radius: 5px;
            margin-bottom: 10px;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        &:nth-child(4n){
            margin-right: 0;
        }
    }
    dt{
        color: #ff9500;
        font-size: 14px;
        padding-bottom: 1px;
    }
    dd{
        font-family: din_bold;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
    }
    @media (max-width: 1274px) {
        dl{
            width: 32%;
            margin-right: 2%;
            &:nth-child(4n){
                margin-right: 2%;
            }
            &:nth-child(3n){
                margin-right: 0;
            }
        }
    }
`

const BorderBox = styled.div`
    padding: 30px 20px 10px;
    border: 1px solid rgba(255,255,255,0.12);
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    .title{
        position: absolute;
        left: 20px;
        top: -10px;
        font-size: 18px;
        background:#090808;
        padding: 0 20px;
    }
    @media (max-width: 1274px) {
        margin-top: 40px;
        padding: 30px 10px 10px;
    }
`
const TopBox = styled.div`
    dl{
        margin-right: 1%;
        background: rgba(255,255,255,0.05);
        border-radius: 5px;
        margin-bottom: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        gap: 10px;
        dt{
            color: #ff9500;
            font-size: 14px;
            text-transform: capitalize;
        }
        dd{
            font-family: din_bold;

            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
        }
    }
`

const BtnPSN = styled.div`
`


export default function ItemDetail_ckb(){
    const {t } = useTranslation();
    const {id} = useParams()
    const [detail,setDetail] = useState(null)
    const [net,setNet] = useState("testnet");
    const type = useSelector(store => store.type)
    const [show,setShow] = useState(false);
    const location = useLocation();
    const [showMy, setShowMy] = useState(false);
    const [showList,setShowList] = useState(false);
    const [traits,setTraits] = useState([])
    const [prev,setPrev] = useState([])
    const [dna,setDna] = useState('')
    const account = useSelector(store => store.account);
    const {  notification } = App.useApp();
    const [bitName, setBitName] = useState("");
    const [fee, setFee] = useState(null);

    const [showSend,setShowSend] = useState(false);
    const [showChain,setShowChain] = useState(false);
    const walletList = useSelector(store => store.walletList);
    const [showSwitch,setShowSwitch] = useState(false);
    const navigate = useNavigate();
    const [image,setImage] = useState("");

    useEffect(() => {
        if(location.pathname.indexOf("my")>-1){
            setShowMy(true)
        }else{
            setShowMy(false)
        }

    }, [location.pathname]);


    useEffect(()=>{
        if(!id )return;
        getAssets()
        getNetwork()
    },[id,type])

    const getNetwork = async () =>{
        let rt = await getNet(type)
        setNet(rt)
    }


    const getAssets = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await assetsInfoCKB(id)
            let metadata = toJSONStr(rt?.metaData)
            let rawData = metadata?toJSONStr(metadata.raw):""

            const standardObj = JSON.parse(rt.sporeData)
            rt.standard = rt.assetType === 1 ?"DOB/0":standardObj.contentType;

            let imgUrl;
            let txt;

            if(standardObj.contentType.indexOf("image")>-1){
                const buffer = Buffer.from(standardObj.content.toString().slice(2), 'hex');
                const base64 = buffer.toString("base64");
                imgUrl =  `data:${standardObj.contentType};base64,${base64}`;
            }else if(standardObj.contentType.indexOf("text")>-1){
                const buffer = Buffer.from(standardObj.content.toString().slice(2), 'hex');
                txt =  buffer.toString()
            }else if(standardObj.contentType.indexOf("application/json")>-1){
                try{
                    let rtInner = await getDOBImage(rt?.dobsId)
                    setImage(rtInner.url)

                }catch(e){
                    console.error("get dob image error",rt?.dobsId)
                }
            }


            // const dna = standardObj ? bufferToRawString(`0x${rawData.dob_content}`) :"";
            const dnaObj = standardObj.content ? bufferToRawString(standardObj.content) :"";
            const newDNA = toJSONStr(dnaObj)


            let traitsBox = rawData?.render_output?toJSONStr(rawData?.render_output) : [];
            setDna(newDNA?.dna)

            let arr = []
            let arrPrev = []
            let bitName = ""

            traitsBox.map((item)=>{
                if(item?.name.startsWith("prev")){
                    arrPrev.push(item)
                }else{
                    if(item?.name === "Account"){
                        bitName =  item.traits[0].String;

                    }
                    arr.push(item)
                }

            })

            rt.spore = {
                imageUrl:metadata.url,
                imgUrl:metadata.url,
                url:imgUrl,
                txt,
                DidName:bitName,
                content_type:metadata.content_type
            }

            const clusterInfo = toJSONStr(rt.clusterInfo.metaData)


            rt.cluster_info = {
                name: clusterInfo.name,
                description:toJSONStr(clusterInfo.description,"description"),
            }

            setBitName(bitName)

            setDetail(rt)

            setTraits(arr)
            setPrev(arrPrev)

        }catch (e) {
            console.error("assetsInfo",e)
            notification.error({
                message: 'Fetch asset info failed!',
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }
    }

    const ModalClose = () =>{
        setShow(false)
        // window.location.reload()
    }

    const handleShow = (e) =>{
        e.stopPropagation();
        setShow(true)
    }

    const handleShowList = (e) =>{
        e.stopPropagation();
        setShowList(true)
    }
    const ModalCloseList = () =>{
        setShowList(false)
        // window.location.reload()
    }


    useEffect(()=>{
        if(isBTC(type))return;
        getRate()

        let timer = setInterval(()=>{
            getRate()
        }, 30 * 1000)

        return () =>{
            clearInterval(timer)
        }
    },[type])


    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {median} = rt;

            let feeRate = BI.from(median).toString()
            // setDisableFee(false)
            setFee(feeRate)
        }catch (e) {
            console.error(e)
            // setDisableFee(true)
            notification.error({
                message: t("modal.feeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    const handleList = async(inputValue,item) =>{


        store.dispatch(saveLoading(true));
        try{

            const net = await getNet()

            const rpcUrl = CKB_networkList[net].node
            const indexUrl = CKB_networkList[net].indexer
            const indexer = new Indexer(indexUrl, rpcUrl);


            const sporeConfig = net==="testnet"? predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;

            const version = net === 'testnet'?"preview":"latest";
            const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);


            let selectItemObj;

            if(item.assetType === 0){
                const {script:{codeHash,hashType}} = sporeTypeScript;

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:codeHash,
                        hashType:hashType,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }else if(item.assetType === 1){


                const {CODE_HASH,HASH_TYPE} = DID_CONTRACT[net]

                const cellCollector = new CellCollector(indexer, {

                    type: {
                        args: item?.dobsId,
                        codeHash:CODE_HASH,
                        hashType:HASH_TYPE,
                    },
                });
                let rt = await cellCollector.collect();
                let detail = await rt.next()
                selectItemObj = detail.value;
            }


            let txSkeleton = await build_list_dob(fee,selectItemObj,account,inputValue,type,detail.assetType)

            let result = await sendRawTx(txSkeleton,type,account,false)

            notification.success({
                message: t("modal.listSuccess"),
                description: result
            });
            ModalCloseList()

        }catch (e) {
            console.error(e)
            notification.error({
                message: t("modal.listFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));

        }

    }

    const switchString = (innerItem) =>{
        return innerItem.Number ||innerItem.String ||dayjs(innerItem.Timestamp * 1000).format('YYYY-MM-DD HH:mm')
    }



    const handleChain = (e,chain) =>{
        e.stopPropagation();
        if(walletList.ckb){
            setShowSwitch(true)
        }else{
            setShowChain(true)
        }

    }

    const handleChainClose = () =>{
        setShowChain(false)
        setShowSwitch(false)
    }

    const handleCloseSend = () =>{
        setShowSend(false)
    }

    const handleSendShow = (e,item) =>{
        e.stopPropagation();
        setShowSend(true)
    }

    const refresh = () =>{
        navigate(`/item-detail_ckb/${id}`)
    }


    return  <Box>
        <LftBox>
            <BgBox bg={handleBgCKB(detail)}>

                {
                    detail?.status === 1 && <div className="listedTag">
                        {t("detail.Listed")}
                    </div>
                }

                {
                    detail?.status === 3 && <div className="listedTag">
                        <Pending /><span>{t("detail.Pending")}</span>
                    </div>
                }


                <div className="photo">
                    <div className="aspect"/>
                    <div className="content">
                        <div className="innerImg">

                            {
                                detail?.assetType === 0 && <img
                                    src={detail?.spore?.imageUrl || detail?.spore?.url || image}
                                    alt=""/>
                            }
                            {
                                detail?.assetType === 1 && <div>
                                    <div className="nameBox">
                                        <div className="title">
                                            {bitName?.split(".")[0]}
                                        </div>
                                        <div className="bit">
                                            .bit
                                        </div>

                                    </div>
                                    <img src={DidImg}/>
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <BtnPSN>
                    {
                        showMy && !isBTC(type) && detail?.status === 0  &&
                        <BtnListBox onClick={(e) => handleShowList(e)} disabled={detail?.status !== 0 || isBTC(type)}>
                            <div className="inner">
                            <span className="buy">{t("detail.ListNow")}</span>
                        </div>
                    </BtnListBox>
                }

                    {
                        showMy && !isBTC(type) && detail?.status === 0  &&
                        <BtnListBox onClick={(e) => handleSendShow(e)} disabled={detail?.status !== 0 || isBTC(type)}>
                            <div className="inner">
                                <span className="buy"> {t("detail.Send")}</span>
                            </div>
                        </BtnListBox>
                    }
                {
                    !showMy && !isBTC(type) && detail?.status === 1   &&  <BtnBox onClick={(e)=>handleShow(e)}  disabled={detail?.ownerAddr === account || isBTC(type)  }>
                        <div className="inner">
                            <span className="buy">{t("detail.BuyNow")}</span>
                            <div className="rht">
                                <img src={CKBImg} alt=""/>
                                <span className="price">{detail?.price?formatUnit(detail.price,"ckb"):0}</span>
                                <span className="sym">CKB</span>
                            </div>
                        </div>
                    </BtnBox>
                }
                {
                    !showMy && detail?.status !== 1   &&  <BtnBox disabled>
                        <div className="inner">
                            <span className="buy">{t("collection.MakeOffer")}</span>
                            <div className="rht">
                                <img src={CKBImg} alt=""/>
                                <span className="price">{detail?.price?formatUnit(detail.price,"ckb"):0}</span>
                                <span className="sym">CKB</span>
                            </div>
                        </div>
                    </BtnBox>
                }
                    {/*{*/}
                    {/*    isBTC(type) &&  <BtnBox onClick={(e)=>handleChain(e)}  >*/}
                    {/*        <div className="inner">*/}
                    {/*            <span className="buy">{t('general.connect')}</span>*/}
                    {/*            <div className="rht">*/}
                    {/*                <img src={CKBImg} alt=""/>*/}
                    {/*                <span className="price">{detail?.price?formatUnit(detail.price,"ckb"):0}</span>*/}
                    {/*                <span className="sym">CKB</span>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </BtnBox>*/}
                    {/*}*/}
                </BtnPSN>
            </BgBox>
        </LftBox>
        <RhtBox>
            <TitleBox># {detail?.dobsId} </TitleBox>
            <RhtFst>
                <div className="titleInner">
                    <InfoCircleOutlined className="icon"/>
                    {t("detail.INFO")}
                </div>
                <dl>
                    <dt> {t("detail.Standard")}</dt>
                    <dd>{detail?.standard}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ID")}</dt>
                    <dd><a href={`${CKB_networkList[net]?.scanUrl}nft-info/${detail?.clusterTypescriptHash}/${detail?.dobsId}`} rel="noreferrer"
                           target="_blank">{detail?.dobsId}</a></dd>
                </dl>
                <dl>
                    <dt>{t("detail.DNA")}</dt>
                    <dd>{dna??"--"}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Owner")}</dt>
                    <dd><a href={`${CKB_networkList[net]?.scanUrl}address/${detail?.ownerAddr}`} rel="noreferrer"
                           target="_blank">{detail?.ownerAddr}</a></dd>
                </dl>
                <dl>
                    <dt>{t("detail.ContentType")}</dt>
                    <dd>{detail?.spore?.content_type || detail?.standard}</dd>
                </dl>
                <dl>
                    <dt>Capacity</dt>
                    <dd><span className="capacity">{detail?.capacity?formatUnit(detail?.capacity,"ckb"):0}</span> CKB</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Timestamp")}</dt>
                    <dd>{dayjs(detail?.updateAt).format('YYYY-MM-DD HH:mm')}</dd>
                </dl>
            </RhtFst>
            <BorderBox>
                <div className="title">
                    {t("detail.DESCRIPTION")}
                </div>

                    {
                        !!prev.length && <TopBox>
                            {
                                prev?.map((item, index) => (<dl key={index}>
                                    <dt>{item?.name}</dt>
                                    <dd>{item?.traits[0].Number ||item?.traits[0].String}</dd>
                                </dl>))
                            }
                        </TopBox>
                    }
                {
                    !!traits.length && <TraitBox>
                        {
                            traits?.map((item, index) => (<dl key={index}>
                                <dt>{item?.name}</dt>
                                <dd>{switchString(item?.traits[0])}</dd>
                            </dl>))
                        }
                    </TraitBox>
                }
            </BorderBox>


            <ContentBox>
                <div className="titleInner">
                    <ReadOutlined className="icon"/>
                    {t("detail.About")}
                </div>
                <dl>
                    <dt>{t("detail.ClusterID")}</dt>
                    <dd><a href={`${CKB_networkList[net]?.scanUrl}nft-collections/${detail?.clusterTypescriptHash}`} rel="noreferrer"
                           target="_blank">{detail?.clusterId}</a></dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterName")}</dt>
                    <dd>{detail?.cluster_info.name}</dd>
                </dl>
                <dl>
                    <dt>{t("info.chain")}</dt>
                    <dd className="chainImg">
                        <img src={CKBImg} alt=""/>
                        <span>CKB</span>
                    </dd>
                </dl>

                <dl>
                    <dt>{t("detail.ClusterDescription")}</dt>
                    <dd>{detail?.cluster_info.description}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterTimestamp")}</dt>
                    <dd>{detail?.clusterInfo.timestamp ?dayjs(Number(detail?.clusterInfo.timestamp )).format('YYYY-MM-DD HH:mm'):"--"}</dd>
                </dl>
                <dl>
                    <dt>{t("detail.Blockheight")}</dt>
                    <dd><a href={`${CKB_networkList[net]?.scanUrl}block/${detail?.clusterInfo.blockHeight}`} rel="noreferrer"
                           target="_blank">#{detail?.clusterInfo.blockHeight}</a></dd>
                </dl>
                <dl>
                    <dt>{t("detail.ClusterCreator")}</dt>
                    <dd><a href={`${CKB_networkList[net]?.scanUrl}address/${detail?.clusterInfo.creator}`} rel="noreferrer"
                           target="_blank">{detail?.clusterInfo.creator}</a></dd>
                </dl>

            </ContentBox>
        </RhtBox>

        {
            show && <BuyCkb ModalClose={ModalClose} selectedItem={detail} getAssets={getAssets} />
        }
        {
            showList &&  <List_ckb ModalClose={ModalCloseList} item={detail} handleList={handleList}/>
        }
        {
            showSend && <SendModal selectItem={detail} handleClose={handleCloseSend} refresh={refresh}  />
        }

        {
            showChain && <Wallet handleClose={handleChainClose} chain="ckb"/>
        }

        {
            showSwitch &&   <SwitchWallet handleClose={handleChainClose} handleNext={handleChain} switchTochain="ckb"  />
        }
    </Box>
}
