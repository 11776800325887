import request from "./index.js";

const rootPrefix = import.meta.env.VITE_CHAIN==="testnet"?"":'/layer';

export const getClusterList = (page,pageSize) =>{
    return request.get(`${rootPrefix}/api/getclusterlist?page=${page}&pageSize=${pageSize}`);
}

export const getBanner = () =>{
    return request.get(`${rootPrefix}/api/getclusterbanner`);
}
