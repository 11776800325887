import styled from "styled-components";
import { CloseOutlined,UpOutlined,DownOutlined } from '@ant-design/icons';
import {App, Input, Slider} from 'antd';
import {useEffect, useState} from "react";
// import {assetsCreate} from "../../api/assets.js";
import {useSelector} from "react-redux";

import {getNet} from "../../utils/wallet.js";
import {FormatAddress, handleBg, setUtxo} from "../../utils/general.js";
import LoadingBtn from "../loadingBtn.jsx";
import {useTranslation} from "react-i18next";
import {getFeeRate} from "../../api/ckb.js";
import {BI, formatUnit, parseUnit} from "@ckb-lumos/bi";
import {CellCollector, helpers, Indexer} from "@ckb-lumos/lumos";
import {CKB_CONTRACT, CKB_networkList, DID_CONTRACT, MINiPrice} from "../../utils/constant.js";
import {getSporeScript, predefinedSporeConfigs} from "@spore-sdk/core";
import {build_buy_dob} from "../../utils/ckbTransaction.js";
import {sendRawTx} from "../../utils/ckbUtils.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import BigNumber from "bignumber.js";
import DidImg from "../../assets/images/did.svg";
import useCCCSigner from "../../hook/useCCCSigner.jsx";

const MaskBox = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
`

const ContentBox = styled.div`
    background: #191A1E;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    @media (max-width: 1274px) {
        width: 80vw;
    }
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    .lft{
        flex-grow: 1;

    }
    .icon{
        cursor: pointer;
    }
    
`
const Tips = styled.div`
    font-size: 12px;
    opacity: 0.6;
    padding-top: 10px;
    margin-bottom: 10px;
`

const DLBox = styled.div`
    dl{
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-child{
            border-bottom: 0;
            margin-bottom: 0;
        }
    }
    dd{
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .num{
        font-family: din_bold;
    }
    .rhtNum{
        color: #ff9500;
    }
    .sym{
        opacity: 0.6;
    }
    .tag{
        border: 1px solid rgba(255,255,255,1);
        font-size: 12px;
        padding:2px 5px;
        border-radius: 4px;
        opacity: 0.5;
        line-height: 1em;
    }
`

const FirstLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    .innerImg{
        width: 80px;
        height: 80px;
        border-radius: 5px;
        border: 1px solid rgba(255,255,255,0.1);
        overflow: hidden;
        background: ${props => props.bg};
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            
            //image-rendering: pixelated;
        }
    }
    .capacity{
        display: flex;
        gap: 5px;
        font-family:din_regular;
    }
    .innerTxt{
        background: #000;
        width: 80px;
        height: 80px;
        font-size: 12px;
        line-height: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px;
        word-break: break-all;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap:break-word;
        -webkit-line-clamp:3;
        -webkit-box-orient: vertical;
    }
    .title{
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 400px;
    }
    .id{
        //color: #ff9500;
        font-family:din_regular;
    }
    @media (max-width: 1274px) {
        .title{
            width: 58vw;
            word-break: break-all;
        }
    }
`

const BtmBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    background: rgba(255,255,255,0.1);
    border-radius: 10px;
    padding: 20px;
    .price{
        color: #ff9500;
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
    }
`
const ButtonGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    button{
        width: 49%;
        height: 40px;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        &:disabled{
            cursor: pointer;
            opacity: 0.6;
        }
    }
    .confirm{
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        gap:10px;
    }
    .cancel{
        border:1px solid #FF9500;
        color: #ff9500;
        background: none;
    }
`
const FeeLine = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 20px;
    .custom{
        border:1px solid #FF9500;
        color: #ff9500;
        padding:5px 10px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .num{
        font-family: din_bold;
        font-size: 18px;
    }
    .sym{
        opacity: 0.6;
        font-size: 14px;
        padding-left: 5px;
    }
    .rhtNum{
        color: #ff9500;
    }
`

const CustomLine = styled.div`
    .fst{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .item{
        width: 49%;
        border: 1px solid rgba(255,255,255,0.3);
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:hover,&.active{
            cursor: pointer;
            border: 1px solid #ff9500;
        }
     
    }
    .none{
        display: none;
    }
    .title{
        font-size: 12px;
        opacity: 0.6;
    }
    .num{
        font-family: din_bold;
        color: #ff9500;
        margin-right: 10px;
    }
    .sym{
        font-size: 12px;
        font-weight: bold;
    }
    @media (max-width: 1274px) {
        .lBtm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
`

const SliderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
    .lft{
        flex-grow: 1;
    }
    .rht{
        flex-shrink: 0;
        width: 80px;
    }
`

const Tips2 = styled.div`
    color: #ff9500;
    font-size: 12px;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 5px;
`

export default function BuyCkb({ModalClose,selectedItem,getAssets}){
    const {t } = useTranslation();
    const {  notification } = App.useApp();

    const type = useSelector(store => store.type)
    const infoCKb = useSelector(store => store.infoCKb)
    const joyidInfo = useSelector(store => store.joyidInfo)
    const {signer} = useCCCSigner(type);

    const account = useSelector(store => store.account);
    const [fee, setFee] = useState(null);
    const [disableTx,setDisableTx] = useState(true)
    const [ disableFee,setDisableFee] = useState(true)
    const [detail,setDetail] = useState(null);
    const [txFee,SetTxFee] = useState(0);
    const [sFee,SetSFee] = useState(0);

    const [txSkeletonObj,setTxSkeletonObj] = useState(null);


    useEffect(()=>{
        setDisableTx(disableFee)
    },[disableFee])


    useEffect(()=>{

        getCell()

    },[selectedItem])

    const getCell = async() =>{
        const net = await getNet()

        const rpcUrl = CKB_networkList[net].node
        const indexUrl = CKB_networkList[net].indexer
        const indexer = new Indexer(indexUrl, rpcUrl);

        const version = net === 'testnet'?"preview":"latest";
        const sporeConfig = net==="testnet"? predefinedSporeConfigs.Testnet:predefinedSporeConfigs.Mainnet;
        const sporeTypeScript = getSporeScript(sporeConfig,"Spore",["v2",version]);

        let selectItemObj;
        if(selectedItem.assetType === 0){
            const {script:{codeHash,hashType}} = sporeTypeScript;

            const cellCollector = new CellCollector(indexer, {

                type: {
                    args: selectedItem?.dobsId,
                    codeHash:codeHash,
                    hashType:hashType,
                },
            });
            let rt = await cellCollector.collect();
            let detail = await rt.next()
            selectItemObj = detail.value;
        }else if(selectedItem.assetType === 1){

            const {CODE_HASH,HASH_TYPE} = DID_CONTRACT[net]

            const cellCollector = new CellCollector(indexer, {

                type: {
                    args: selectedItem?.dobsId,
                    codeHash:CODE_HASH,
                    hashType:HASH_TYPE,
                },
            });
            let rt = await cellCollector.collect();
            let detail = await rt.next()
            selectItemObj = detail.value;
        }

        setDetail(selectItemObj)
    }


    useEffect(()=>{
        getRate()

        // let timer = setInterval(()=>{
        //     getRate()
        // }, 30 * 1000)
        //
        // return () =>{
        //     clearInterval(timer)
        // }
    },[])



    const getRate = async() =>{
        // store.dispatch(saveLoading(true));
        // setLoadingFee(true)
        try{
            let rt = await getFeeRate();
            const {median} = rt;

            let feeRate = BI.from(median).toString()
            setDisableFee(false)
            setFee(feeRate)
        }catch (e) {
            console.error(e)
            setDisableFee(true)
            notification.error({
                message: t("modal.feeRate"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }
        finally {
            // store.dispatch(saveLoading(null));
            // setLoadingFee(false)
        }
    }

    useEffect(() => {
        if(!detail || !fee)return;
        beforeBuy()

    }, [detail,fee,joyidInfo]);

    useEffect(() => {
        if(!txSkeletonObj)return;
        formatFee()
    }, [txSkeletonObj]);

    const beforeBuy = async() =>{
        store.dispatch(saveLoading(true));
        try{
            const {price,ownerAddr,assetType} = selectedItem;


            const net = await getNet()
            const {service_address} = CKB_CONTRACT[net]
            let txSkeletonObj = await build_buy_dob(fee,detail,account,price,ownerAddr,service_address,type,infoCKb.serviceFee,assetType)
            setTxSkeletonObj(txSkeletonObj)

        }catch (e){
            console.error(e)
            notification.error({
                message: t("modal.buyFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });

        }finally {
            store.dispatch(saveLoading(false));
        }

    }



    const buy = async () =>{
        store.dispatch(saveLoading(true));
        try{
            let result = await sendRawTx(txSkeletonObj.txSkeleton,type,account,true,getAssets,signer)
            setUtxo(txSkeletonObj.txSkeleton);

            notification.success({
                message: t("modal.buySuccess"),
                description: result
            });
            ModalClose()
        }catch(e){
            console.error(e)
            notification.error({
                message: t("modal.buyFailed"),
                description:e?.response?.data?.message || e.message || e.reason
            });
        }finally {
            store.dispatch(saveLoading(false));
        }

    }



    const formatFee = () =>{

        let value = txSkeletonObj?.txFee?.toString()
        const valueFormat = value?formatUnit(value,"ckb"):0
        // return  txSkeletonObj?.txFee?valueFormat: 0

        SetTxFee(valueFormat)


        let valueS = txSkeletonObj?.sFee?.toString()
        const valueSFormat = valueS?formatUnit(valueS,"ckb"):0

        SetSFee(valueSFormat)
    }

    const formatPrice = () =>{
        if(!selectedItem?.price) return 0;
        let value = formatUnit(selectedItem.price,"ckb");

        let total = new BigNumber(value.toString()).plus(txFee).plus(sFee)


        return total.toString();
    }


    return <MaskBox>
        <ContentBox>

            <TitleBox>
                <div className="lft"> {t("modal.Confirmation")}</div>

                <CloseOutlined className="icon" onClick={()=>ModalClose()} />
            </TitleBox>
            <Tips>{t("modal.confirmTips")}:</Tips>

            <FirstLine bg={handleBg(selectedItem)}>


                {
                    selectedItem.assetType === 0 && <div className="innerImg">
                        <img
                            src={selectedItem?.spore?.url || selectedItem?.spore?.imgUrl }
                            alt=""/>
                    </div>
                }
                {
                    selectedItem.assetType === 1 && <div className="innerImg">
                        <img
                            src={DidImg}
                            alt=""/>
                    </div>
                }

                <div className="rht">
                    {
                        selectedItem.assetType === 1 &&
                        <div className="title">{selectedItem?.spore?.DidName?.split(".")[0]}.bit</div>

                    }

                    <div className="id">ID: #{FormatAddress(selectedItem?.dobsId)}</div>
                    <div className="capacity">
                        <dt className="rhtNum">Capacity:</dt>
                        <dd>
                            <span
                                className="num rhtNum">{selectedItem?.capacity ? formatUnit(selectedItem?.capacity, "ckb") : 0}</span>&nbsp;
                            <span className="sym">CKB</span>
                        </dd>
                    </div>
                </div>
            </FirstLine>
            <DLBox>
                <dl>
                    <dt>{t("modal.TotalValue")}</dt>
                    <dd>
                        <span className="num">{selectedItem?.price ? formatUnit(selectedItem?.price, "ckb") : 0}</span>
                        <span className="sym">CKB</span>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("modal.ServiceFee")} <span className="tag">{(infoCKb?.serviceFee)}%</span></dt>
                    <dd>
                        <span className="num">{sFee}</span>
                        <span className="sym">CKB</span>
                    </dd>
                </dl>
                <dl>
                    <dt>{t("modal.TransactionFee")}</dt>
                    <dd>
                        <span className="num">≈ {txFee}</span>
                        <span className="sym">CKB</span>
                    </dd>
                </dl>

            </DLBox>



            <BtmBox>
                <div>{t("modal.YouPay")}</div>
                <div>
                    <span className="price">{formatPrice()}</span> <span className="sym">CKB</span></div>
            </BtmBox>

            {/*<Tips2>{t("modal.warnings")}</Tips2>*/}
            <Tips2>{t("modal.tips_ckb", {MINiPrice})}</Tips2>
            <ButtonGroup>


                <button className="cancel" onClick={()=>ModalClose()}>{t("modal.Cancel")}</button>
                <button className="confirm" disabled={!account || disableTx || !txSkeletonObj} onClick={()=>buy()}>
                    {t("modal.Confirm")}

                    {
                        disableTx &&  <LoadingBtn />
                    }

                </button>
            </ButtonGroup>
        </ContentBox>
    </MaskBox>
}
