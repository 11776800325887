import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {BitcoinUnit} from "bitcoin-units";
import {addCommasToNumber} from "../../utils/general.js";
import {App, Table} from "antd";
import BtcLogo from "../../assets/images/btc.png";
import CkbLogo from "../../assets/images/ckb.png";
import {formatUnit} from "@ckb-lumos/bi";

import {useTranslation} from "react-i18next";
import {getClusterList} from "../../api/all_list.js";
import store from "../../store/index.js";
import {saveLoading} from "../../store/reducer.js";
import DidImg from "../../assets/images/did.svg"
import DefaultImg from "../../assets/images/test_logo.png";

const Box = styled.div`
    width:1274px;
    flex-grow: 1;
    margin: 20px auto ;

    @media (max-width: 1274px) {
        margin:70px auto 20px;
        width: 100%;
    }
`

const TitleTop = styled.div`
    font-size: 20px;
    margin-bottom: 20px;
    @media (max-width: 1274px) {
        margin: 0 10px;
    }
`
const TableBox = styled.div`
    .photo{
        width: 60px;
        height: 60px;
        border-radius: 5px;
        overflow: hidden;
        flex-shrink: 0;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
    td{
        cursor: pointer;
        white-space: nowrap;
    }
`

const UlBox = styled.ul`
    width: 100%;
    margin-top: 20px;
    &:after{content:"";display:block;clear:both;}
    li{
        width: calc((100% - 80px)/4);
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        
        &:nth-child(4n){
            margin-right: 0;
        }
    }

    @media (max-width: 1274px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 10px;
        box-sizing: border-box;
        li{
            width: 48%;
            margin-right: 0;

        }

    }
`

const Box2 = styled.div`
    width: 100%;
    border-radius: 10px;
    background: rgba(255,255,255,0.1);
    box-sizing: border-box;
    overflow: hidden;
    border: 2px solid rgba(255,255,255,0.01);
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
    &:hover{
        transform: translateY(-10px);
    }


    .listedTag{
        position: absolute;
        right: -1px;
        top: -1px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;
        background: rgba(0,0,0,0.7);
        padding: 5px 10px;
        font-size: 12px;
        color: #ff9500;
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .btnBox{
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ff9500;
        padding: 10px;
        border-radius: 5px;
        color: #ff9500;
        cursor: pointer;
        background: transparent;
        &:disabled{
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
`

const BgBox = styled.div`
    .photo{
        display: flex !important;
        overflow: hidden;
        background: ${props => props.bg};
        .aspect {
            padding-bottom: 80%;
            height: 0;
            flex-grow: 1 !important;
        }
        .content {
            width: 100%;
            margin-left: -100% !important;
            max-width: 100% !important;
            flex-grow: 1 !important;
            position: relative;
        }
        .innerImg{
            position: absolute;
            width: 100%;
            height: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                //image-rendering: pixelated;
            }
        }
    }
    .innerTxt{
        background: rgba(255, 149, 0, 0.1);
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .title{
            font-size: 30px;
            margin-bottom: 5px;
            font-family: din_bold;
        }
        .tips{
            opacity: 0.6;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            white-space: normal !important;
            text-overflow: ellipsis;
            word-wrap:break-word;
            -webkit-line-clamp:9;
            -webkit-box-orient: vertical;
            line-height: 1.5em;
        }
    }
`
const MetaBox = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .last{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btc{
        font-family: din_bold;
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .price{
        opacity: 0.8;
        font-family:din_regular;
    }
`
const TitleBox = styled.div`
    padding-bottom: 10px;
    .id{
        opacity: 0.6;
        font-size: 14px;
    }

    .desc{
        font-family: din_bold;
        padding-top: 5px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
    }
`

const FlexLine = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    
`

const LogoBox = styled.div`
    img{
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`

export default function Tablelist(){
    const {t } = useTranslation();
    const navigate = useNavigate()
    const[list,setList] = useState([])
    const [page,setPage] = useState(1);
    const [pageSize] = useState(10);
    const [total,setTotal] = useState(100);
    const {  notification } = App.useApp();

    useEffect(() => {
        getDobList()
    }, [page]);

    const getDobList = async() =>{
        store.dispatch(saveLoading(true))
        try{
            let rt = await getClusterList(page,pageSize);

            // FIXME: This is a temporary solution to filter rt data if in production mode. [F] 2024-08-01
            // filter rt data if in production mode.
            // if(import.meta.env.VITE_MODE !== "dev"){
            //     rt.data = rt.data.filter((item) => {
            //         return (
            //             item.currency === "BTC"
            //             || (item.currency === "CKB" && item.clusterId === "0xf717625c0161f88007e187743f05e2ec8493e22ccdffb1de561c42b6b5c64cf8"));
            //     });
            //     rt.total = rt.data.length;
            // }

            console.log(rt)

            setTotal(rt.total)
            setPage(rt.current)
            rt.data?.map((item)=>{
                item.metaObject = JSON.parse(item.metaData)
            })
            setList(rt.data)
        }catch (error){
            console.error(error)
            notification.error({
                message: t("error.MyOrders"),
                description:error?.response?.data?.message || error.message ||error.reason
            });
        }finally {
            store.dispatch(saveLoading(false))
        }

    }

    const handleTo = (id) =>{
        navigate(`/cluster/${id}`)
    }


    const columns = [
        {
            title: "Cluster",
            dataIndex: 'media_info',
            width: 260,
            fixed: 'left',
            render: (_,row) => <FlexLine>


                <div className="photo" >
                    {row.assetType === 0 && <img
                        src={row?.imageUrl?.indexOf("http")>-1?row?.imageUrl:DefaultImg}
                        alt=""/>
                    }
                    {row.assetType === 1 && <img
                        src={DidImg}
                        alt=""/>
                    }

                </div>
                <Link >{row?.metaObject.name}</Link>
            </FlexLine>,
        },
        {
            title:t("info.chain"),
            render: (_, row) => <LogoBox>
                {
                    row.currency === "BTC" && <img src={BtcLogo} alt=""/>
                }
                {
                    row.currency === "CKB" && <img src={CkbLogo} alt=""/>
                }
            </LogoBox>,
        },
        {
            title: t("info.24HVolume"),
            render: (_, row) =><>
                {
                    row.currency === "BTC" &&
                    <div>{Number(row?.vol_24h) ? new BitcoinUnit(row?.vol_24h ?? 0, 'sats').to('BTC').toString() : 0} BTC</div>
                }
                {
                    row.currency === "CKB" && <div>{formatUnit(row?.vol_24h, "ckb")} CKB</div>
                }
            </>,
        },

        {
            title: t("info.FloorPrice"),
            render: (_, row) =><>
                {
                    row.currency === "BTC" &&
                    <div>{new BitcoinUnit(row?.floorPrice ?? 0, 'sats').to('BTC').toString()} BTC</div>
                }
                {
                    row.currency === "CKB" && <div>{formatUnit(row?.floorPrice, "ckb")} CKB</div>
                }
            </>,
        },
        {
            title: t("info.MarketCap"),
            render: (_, row) =><>

                {
                    row.currency === "BTC" &&
                    <div>{Number(row?.marketCap) ? new BitcoinUnit(Number(row?.marketCap), 'sats').to('BTC').toString() : 0} BTC</div>
                }
                {
                    row.currency === "CKB" && <div>{formatUnit(row?.marketCap, "ckb")} CKB</div>
                }
            </>,
        },
        {
            title: t("info.24HSales"),
            render: (_, row) =><div>{addCommasToNumber(row?.dealCount_24h)} </div>,
        },
        {
            title:t("info.Holders"),
            dataIndex: 'update_ts',
            render: (_, row) => <div>{addCommasToNumber(row?.holders)} </div>,
        },

    ];

    const handlePage = (page) =>{
        setPage(page)
    }

    return <Box>
        {/*<TitleTop>Notable collections</TitleTop>*/}
        <TableBox>
            <Table  columns={columns} dataSource={list}
                    scroll={{
                        x:850
                    }}
                    onRow={(row) => {
                        return {
                            onClick: () => {
                                let str = row.currency === "BTC"?`/cluster/${row.clusterId}`:`/cluster_ckb/${row.clusterId}`
                                navigate(str);
                            },
                        };
                    }}
                    rowKey={() => Math.random().toString(36).substr(2)}
                    pagination={{
                        position:["bottomCenter"],
                        pageSize,
                        current:page,
                        total,
                        showSizeChanger:false,
                        onChange: (page) => {
                            handlePage(page);
                        }
                    }}
            />
        </TableBox>

    </Box>
}
