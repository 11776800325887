import axios from "axios";
import {BASE_URL} from "../utils/constant";
import store from "../store/index.js";
import {saveAccount, saveToken, saveTokenExp, saveType} from "../store/reducer.js";
import {isBTC} from "../utils/general.js";
import {t} from "i18next";

const instance = axios.create({
  baseURL: `${BASE_URL[import.meta.env.VITE_MODE]}/`,
  timeout: 15000,
  // headers: { "content-type": "application/json" },
});

instance.interceptors.request.use(function (config) {

  if(config.url.includes("login") ||config.url.includes("mempool")){
    return config;
  }

  const state = store.getState();

  let now = new Date ().valueOf();
  let exp = state.tokenExp;

  const checkType = isBTC(state.type)

  if( checkType && now >exp){
    store.dispatch(saveAccount(null));
    store.dispatch(saveType(null));
    store.dispatch(saveToken(null));
    store.dispatch(saveTokenExp(null));
  }

  if (!config.headers) {
    config.headers = {};
  }

  const token = state.token;
  config.headers['Authorization'] = `Bearer ${token || ''}`;


  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {response} = error;
    const state = store.getState();

    let now = new Date ().valueOf();
    let exp = state.tokenExp;
    const checkType = isBTC(state.type)
    if(  response?.status === 401){
      store.dispatch(saveAccount(null));
      store.dispatch(saveType(null));
      store.dispatch(saveToken(null));
      store.dispatch(saveTokenExp(null));

      if(checkType){
        if(  now< exp){
          const newError = new Error(t("error.unique"))
          return Promise.reject(newError);
        }

      }

    }

    return Promise.reject(error);
  },
);

/**
 * get
 * @method get
 * @param {url, params, loading}
 */
const get = function (url, params) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
/**
 * post
 * @method post
 * @param {url, params}
 */
const post = function (url, data) {
  return new Promise((resolve, reject) => {
    instance
      .post(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * put
 * @method put
 * @param {url, params}
 */
const put = function (url, data) {
  return new Promise((resolve, reject) => {
    instance
      .put(url, data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const rdelete = function (url, params) {
  return new Promise((resolve, reject) => {
    instance
      .delete(url, {
        params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export default { get, post, put, delete: rdelete };
