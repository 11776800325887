import styled from "styled-components";
import {App, Input} from 'antd';
import {MoveLeft} from "lucide-react";
import {CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {predefined} from "@ckb-lumos/config-manager";
import {helpers} from "@ckb-lumos/lumos";
import LoadingBtn from "../loadingBtn.jsx";
import {getUtxoOutspend} from "../../api/btc.js";
const { TextArea } = Input;

const MidBox = styled.div`
    display: flex;
    flex-direction: column;

    .btnBox{
        padding: 15px;
        align-items: center;
        justify-content: center;
        background: linear-gradient( 180deg, #FFBD3D 0%, #FF9500 100%);
        color: #0D0C0B;
        border-radius: 5px;
        cursor: pointer;
        border: none;
        &:disabled{
            opacity: 0.3;
            cursor: not-allowed;
        }
    }
`

const TextBox = styled(TextArea)`
    margin: 30px 0 50px;
    resize: none!important;
    border: 1px solid #ff9500;
    background: transparent;
    color: #fff;
    border-radius:5px;
    padding:5px;
    //&:focus-visible{
    //    outline: none;
    //}
`

const TitleBox = styled.div`
    display: flex;
    align-items: center;
    font-family: din_bold;
    font-size: 18px;

    .lftImg{
        cursor: pointer;
    }
    .lft{
        flex-grow: 1;
        text-align: center;

    }
    .icon{
        cursor: pointer;
    }
    
`


export default function SendStep1_ckb ({handleClose,handleSend,selectedItem}){
    const {t } = useTranslation();
    const account = useSelector(store => store.account);
    const [input,setInput] = useState("");
    const {  notification } = App.useApp();




    const net = import.meta.env.VITE_CHAIN === "testnet"?"testnet":"mainnet"


    const parsed = (address) => {
        if (!address) return null;
        // const prefix = address.substring(0, 3);

        // const config = prefix === "ckb" ? MAINNET_CONFIG : TESTNET_CONFIG;

        const newConfig = net==="testnet"? predefined.AGGRON4:predefined.LINA;

        let script;
        try {
            script = helpers.parseAddress(address, {config:newConfig});
            return true
        } catch(e) {
            return false;
        }
    }
    const handleConfirm  = () =>{
        if(!parsed(input)) {
            notification.error({
                message: "Error",
                description: t("error.rightCkb")
            });

            return;
        }
        if(account.toLowerCase() === input.toLowerCase() ){
            notification.error({
                message: "Error",
                description: t("error.sameAccount")
            });

            return;
        }

        handleSend(input)
    }

    return <div>
        <TitleBox>

            <div className="lft">{t("detail.sendDob")}</div>
            <CloseOutlined className="icon" onClick={() => handleClose()}/>
        </TitleBox>
        <MidBox>
            <TextBox id="textarea" value={input}  autoSize={{
                minRows: 1,
                maxRows: 10,
            }}  onChange={e => setInput(e.target.value)} />
            <button className="btnBox" disabled={!input} onClick={()=>handleConfirm()}>{t("detail.Send")}

            </button>
        </MidBox>
    </div>
}
